<div class="wrapper-audio">
	<h6>Ausculta Ápice Pulmão Esquerdo</h6>
	<div class="d-block">
		<audio controls>
			<source
				src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
				type="audio/ogg"
			/>
			<source
				src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
				type="audio/mpeg"
			/>
		</audio>
	</div>
	<div class="d-block">
		<audio controls>
			<source src="horse.ogg" type="audio/ogg" />
			<source src="horse.mp3" type="audio/mpeg" />
		</audio>
	</div>
</div>

import { Action } from '@ngrx/store'
import { ItemQueue } from '../../models/queue'

export enum ActionQueueTypes {
	DoRequest = '@QUEUE/DO_REQUEST',
	RequestSuccess = '@QUEUE/REQUEST_SUCCESS',
    RequestFailure = '@QUEUE/REQUEST_FAILURE',
    RequestClear = '@QUEUE/REQUEST_CLEAR',
}

export const DoRequestQueue = () => {
	return <Action>{ type: ActionQueueTypes.DoRequest }
}

export const RequestSuccessQueue = (queue: ItemQueue[], total: number) => {
    return <Action>{ type: ActionQueueTypes.RequestSuccess, payload: {queue, total} }
}

export const RequestFailureQueue = () => {
    return <Action>{ type: ActionQueueTypes.RequestFailure }
}

export const ClearQueue = () => {
    return <Action>{ type: ActionQueueTypes.RequestClear }
}

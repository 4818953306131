import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-box',
	templateUrl: './box.component.html',
	styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
	@Input() titleBox?: string
	@Input() alertIcon: boolean = false
	@Input() arrow: boolean = true
	@Input() qtdFiles?: number

	@Input() titleAlert?: string
	@Input() descriptionAlert?: string

	public open: boolean = false

	constructor() {}

	ngOnInit(): void {}

	openHistory(): void {
		this.open = !this.open
	}
}

<ng-container *ngIf="open">
	<div
		class="workbench-layer"
		[class.mouse-enter]="mouseOverItem"
		[style]="
			layers > 0
				? 'grid-template-columns: ' + layers * 10.5 + '% 1fr'
				: 'grid-template-columns: 15% 1fr'
		"
	>
		<i
			(click)="close()"
			(mouseenter)="addClassMouseOver()"
			(mouseout)="removeClassMouseOver()"
		></i>
		<div class="wrapper-content">
			<div *ngIf="isHeaders" class="workbench-layer-menu">
				<button type="button" (click)="close()" class="close">
					<app-image
						[path]="
							layers == 1
								? 'assets/images/close.svg'
								: 'assets/images/arrow-left.svg'
						"
						[title]="layers == 1 ? 'Fechar' : 'Voltar'"
					></app-image>
				</button>
				<ng-content
					select="[workbenchHeader]"
				></ng-content>
			</div>
			<div class="content" [class.content-open]>
				<ng-content select="[workbenchContent]"></ng-content>
			</div>
		</div>
	</div>
</ng-container>

<div class='wrapper-table custom-scroll'>
    <table class='table'>
        <thead>
        <tr>
            <th
                scope='col'
                *ngFor='let item of listHeader'
                [attr.data-value]='item.value'
                [attr.align]='item.align'
                [attr.class]='item.className'
            >
                {{ item.label }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr
            *ngFor='
					let item of listBody
				'
        >
            <ng-container
                *ngIf='bodyTemplateRef'
                [ngTemplateOutlet]='bodyTemplateRef'
                [ngTemplateOutletContext]='{ $implicit: item }'
            >
            </ng-container>
        </tr>
        </tbody>
    </table>
</div>
<div>
    <ngb-pagination
        [collectionSize]='total || 0'
        [(page)]='page'
        [pageSize]='pageSize'
        [maxSize]='7'
        [ellipses]='true'
        (pageChange)='changePagination($event)'
    ></ngb-pagination>
</div>

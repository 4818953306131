import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from './state/app-state'
import { AuthService } from './services/auth/auth.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private loading$?: Observable<boolean>
    loading?: boolean

    constructor(
        private authService: AuthService,
        private store: Store<AppState>
    ) {
        this.loading$ = this.store.select('loading')

        this.loading$.subscribe((state) => {
            this.loading = state
        })
    }
}

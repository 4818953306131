<a *ngIf='!isButton'
   [routerLink]='link'
   routerLinkActive='active'
   [routerLinkActiveOptions]='{exact: true}'
>
    <div class='wrapper-img'>
        <img class='no-active' [src]='image' />
        <img class='active' [src]='imageActive' />
    </div>
    <span>{{title}}</span>
</a>
<button *ngIf='isButton'
        type='button'
        [disabled]='disabled'
        (click)='fnHandler()'
>
    <div class='wrapper-img'>
        <img class='no-active' [src]='image' />
        <img class='active' [src]='imageActive' />
    </div>
    <span>{{title}}</span>
</button>


import { Component, OnInit } from '@angular/core'
import { StatsService } from '../../services/stats/stats.service'

@Component({
	selector: 'app-page-onboard',
	templateUrl: './page-onboard.component.html',
	styleUrls: ['./page-onboard.component.scss'],
})
export class PageOnboardComponent implements OnInit {
	slides: any[] = []
	current: boolean | undefined
	urlPath = '/'

	constructor(private statsService: StatsService) {
		this.slides = [
			{
				id: '1',
				title: 'Boas-vindas!',
				description:
					'Vamos te explicar como funciona algumas opções <br />tque você vai encontrar por aqui.<br /><br /><strong>Podemos começar?</strong><br /><br />',
				image: '',
				logo: 'logo-hai-big.svg',
			},
			{
				id: '2',
				title: 'Status de serviços',
				description:
					'Agora com o novo sistema HAI, podemos verificar o status dos <br/> serviços que o sistema necessita para funcionar',
				image: 'status.svg',
				logo: '',
			},
			{
				id: '3',
				title: 'Engajamento',
				description:
					'Agora temos um sistema para medir seu engajamento com a plataforma. <br/> Basta você sempre responder as perguntar no final de cada consulta',
				image: 'engajamento.svg',
				logo: '',
			},
			{
				id: '4',
				title: 'Perguntas frequentes',
				description:
					'Aqui você consegue tirar suas dúvidas de uma maneira mais simples com <br/> uma descrição ou se preferir assistir o video da explicação',
				image: 'faq.svg',
				logo: '',
			},
			{
				id: '5',
				title: 'Feedback da consulta',
				description:
					'Aqui você precisa indicar algumas informações sobre o atendimento <br/> realizado. Importante: isso impacta diretamente no seu engajamento dentro <br/> da plataforma.',
				image: 'feedback.png',
				logo: '',
			},
			{
				id: '8',
				title: 'Vamos começar?',
				description:
					'Agora você já está por dentro de tudo do aplicativo da HAI.',
				image: 'icon-onboarding-4.png',
				logo: '',
			},
		]
	}

	ngOnInit(): void {
		this.statsService.stats('init_onboard', 'start')
	}

	ngOnDestroy() {
		this.statsService.stats('init_onboard', 'end')
	}

	title = 'ngSlick'

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		infinite: false,
		arrows: true,
		prevArrow: '.prev',
		nextArrow: '.next',
		fade: true,
	}

	change(e: any) {
		this.current = e.nextSlide === this.slides.length - 1
	}
}

<div class="wrapper apis">
	<h2>Serviços</h2>
	<div *ngFor="let item of listApis; index as i">
		<div class="status mb-2">
			<div>
				<p class="pl-2">- {{ item.api }}</p>
			</div>
			<figure>
				<img
					draggable="false"
					[src]="
						'/assets/images/status-' +
						(item.status != 'error'
							? 'success'
							: !item.required && item.status === 'error'
							? 'incidente'
							: 'error') +
						'.png'
					"
					[alt]="item.status != 'error' ? 'Sucesso' : 'error'"
					[title]="item.status != 'error' ? 'Sucesso' : 'error'"
				/>
			</figure>
		</div>
	</div>
</div>

<div class="wrapper-exams">
	<div class="exams-head">
		<div class="d-flex">
			<app-button-tooltip
				*ngIf="alertIcon"
				[title]="titleAlert || '!'"
				[description]="descriptionAlert || ''"
				type="warn emergency outline"
			></app-button-tooltip>
			<h4 [class.pl-2]="alertIcon">{{ titleBox }}</h4>
			<span *ngIf="qtdFiles">{{ qtdFiles }}</span>
		</div>
	</div>
	<div class="exams-content">
		<ng-content select="[headerBox]"></ng-content>
        <div class='button-collapse' *ngIf="arrow">
            <button (click)="openHistory()" class="d-flex">
                Ver histórico
                <figure class="pl-2" [class.rotate]="open">
                    <img
                        src='../../../assets/images/arrow-down-blue.png'
                        alt="Ver histórico"
                        title="Ver histórico"
                    />
                </figure>
            </button>
        </div>

		<div class="content-history" [class.open-history]="open">
			<ng-content select="[contentBox]"></ng-content>
		</div>
	</div>
</div>

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { v4 as uuidv4 } from 'uuid'

@Component({
	selector: 'app-result-exams',
	templateUrl: './result-exams.component.html',
	styleUrls: ['./result-exams.component.scss'],
})
export class ResultExamsComponent implements OnInit {
	patientId: string = ''
    error: boolean = false

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		const routeParams = this.route.snapshot.paramMap
		const token = routeParams.get('token')
		const patientId = routeParams.get('id')
		if (token) {
			localStorage.setItem('token', token)

			try {
				const tokenLocale = localStorage.getItem('token') || ''
				let identifier = uuidv4()

				if (tokenLocale === token) {
					identifier =
						localStorage.getItem('identifier') || identifier
				}
				localStorage.setItem('identifier', identifier)

				this.patientId = patientId || ''

				window.addEventListener('beforeunload', (e) => {
					localStorage.removeItem('token')
				})
			} catch (e) {
                localStorage.removeItem('token')
                localStorage.removeItem('identifier')

                this.patientId = ''
			}
		}
	}

    eventError(error: any) {
        console.warn(error)
        this.error = true
    }
}

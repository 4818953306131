<audio #audioElement>
	<p>
		Seu navegado não suporta audio HTML5.
		<a href="{{ blob }}" [download]="blob">Clique aqui</a> e faça o download
		do audio.
	</p>
</audio>
<div class="wrapper-audio" [class.active]="!paused" [class.title]="title">
	<span class="audio">
		<button
			(click)="paused ? play() : pause()"
			type="button"
			[disabled]="state.loading"
		>
			<img
				*ngIf="!state.loading"
				[src]="
					paused
						? '../../../assets/images/controls/play-circle.svg'
						: '../../../assets/images/controls/pause-circle.png'
				"
				[alt]="paused ? 'Play' : 'Pause'"
				[title]="paused ? 'Play' : 'Pause'"
			/>
			<img
				*ngIf="state.loading"
				[src]="'../../../assets/images/loading-audio.svg'"
				alt="Carregando..."
				title="Carregando..."
			/>
		</button>
		<h6 *ngIf="title">{{ title }}</h6>
		<span *ngIf="duration"
			>{{ getTimeCodeFromNum(current) }} /
			{{ getTimeCodeFromNum(duration) }}</span
		>
	</span>
</div>

import { Component, OnInit } from '@angular/core'
import { ModalDefaultComponent } from '../../components/modals/modal-default/modal-default.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	constructor(private modalDefault: NgbModal) {}

	ngOnInit(): void {}

	open() {
		const modalRef = this.modalDefault.open(ModalDefaultComponent)
		modalRef.componentInstance.title = 'Excluir imagem?'
		modalRef.componentInstance.desc = 'Essa a ação não poderá ser desfeita.'
		modalRef.componentInstance.callbackConfirmation = () => {
			modalRef.close()
		}
	}
}

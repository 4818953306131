import { Component, OnInit } from '@angular/core'
import { Patient } from '../../models/patient'
import { HelpersService } from '../../services/helpers/helpers.service'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'

@Component({
	selector: 'app-patient-info',
	templateUrl: './patient-info.component.html',
	styleUrls: ['./patient-info.component.scss'],
})
export class PatientInfoComponent implements OnInit {
	private patient$?: Observable<Patient>
	patient?: Patient

	constructor(
		public helpers: HelpersService,
		private store: Store<AppState>
	) {
		this.patient$ = this.store.select('patient')
        this.patient$.subscribe((state) => {
            this.patient = state
        })
	}

	ngOnInit(): void {}
}

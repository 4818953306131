import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { AuthService } from '../../services/auth/auth.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { StatsService } from '../../services/stats/stats.service'
import { HelpersService } from '../../services/helpers/helpers.service'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'

@Component({
	selector: 'app-recover-password',
	templateUrl: './recover-password.component.html',
	styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
	form: FormGroup

	constructor(
		private store: Store<AppState>,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private toast: ToastrService,
		private statsService: StatsService,
		private helpersService: HelpersService
	) {
		this.form = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email]],
			// cpf: [
			// 	null,
			// 	[Validators.required, GenericValidatorService.isValidCpf],
			// ],
		})
	}

	ngOnInit(): void {}

	onSubmitForgot() {
		const self = this

		if (self.form.valid) {
			self.store.dispatch(ShowLoading())
			const value = self.form.value

			this.authService.forgot(value, {
				fnSuccess() {
					self.form.reset()
				},
				fnError(responseError) {
					console.warn(responseError.error.detail)
					self.toast.error(responseError.error.detail)
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			})
		}
	}
}

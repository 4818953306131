<div class="content">
	<app-version></app-version>
	<div class="wrapper-forgot">
		<div class="row w-100">
			<div
				class="
					col-sm-6
					my-5
					d-flex
					justify-content-center
					align-items-center
				"
			>
				<app-image
					class="d-none d-sm-block"
					[path]="'assets/images/logo.svg'"
					[title]="'H.ai'"
				></app-image>
				<app-image
					class="d-block d-sm-none"
					[path]="'assets/images/logo-blue.svg'"
					[title]="'H.ai'"
				></app-image>
			</div>
			<div class="col-sm-6 d-flex justify-content-center">
				<form
					action="#"
					autocomplete="off"
					(ngSubmit)="onSubmitForgot()"
					[formGroup]="form"
				>
					<app-input
						id="email"
						name="email"
						typeInput="email"
						placeholder="Digite seu email cadastrado"
						labelName="E-mail"
						[form]="form"
					></app-input>
					<!--					<app-input-->
					<!--						id="cpf"-->
					<!--						name="cpf"-->
					<!--						typeInput="text"-->
					<!--						placeholder="Digite seu CPF"-->
					<!--						labelName="CPF"-->
					<!--						inputMask="000.000.000-00"-->
					<!--						[form]="form"-->
					<!--					></app-input>-->
					<button
						type="submit"
						class="btn w-100"
						[disabled]="form.invalid"
					>
						Enviar
					</button>
				</form>
			</div>
		</div>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core'
import { ExamsService } from '../../../services/exams/exams.service'

@Component({
	selector: 'app-complaint',
	templateUrl: './complaint.component.html',
	styleUrls: ['./complaint.component.scss'],
})
export class ComplaintComponent implements OnInit {
	@Input() id: string = ''
	@Input() link?: string = ''
	@Input() withTranscription: boolean = false
	description: string = ''

	loading: boolean = false

	constructor(private examsService: ExamsService) {}

	ngOnInit(): void {
		if (this.id && this.withTranscription) {
			this.getTranscription()
		}
	}

	ngOnDestroy(): void {
		this.id = ''
		this.link = ''
	}

    getTranscription() {
        const self = this
        self.loading = true
        self.examsService.getAudioTranscription(self.id, {
            fnSuccess(response?: any) {
                const { data } = response
                self.description = data?.transcription
            },
            fnError(errorResponse) {},
            fnFinalized() {
                self.loading = false
            },
        })
    }
}

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guard/auth.guard'
import { LoginComponent } from './pages/login/login.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { QueueComponent } from './pages/queue/queue.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { AppointmentComponent } from './pages/appointment/appointment.component'
import { PanelComponent } from './pages/panel/panel.component'
import { FaqComponent } from './pages/faq/faq.component'
import { StatusComponent } from './pages/status/status.component'
import { ConfigComponent } from './pages/config/config.component'
import { PageOnboardComponent } from './pages/onboard/page-onboard.component'
import { ResultExamsComponent } from './pages/result-exams/result-exams.component'
import { QuizResponsesComponent } from './components/quiz-responses/quiz-responses.component'
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component'
import { NewPasswordComponent } from './pages/new-password/new-password.component'

const roles = ['doctor']

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		// canActivate: [NoAuthGuard],
	},
	{
		path: 'esqueci-minha-senha',
		component: RecoverPasswordComponent,
		pathMatch: 'full',
	},
	{
		path: 'alterar-senha/:id',
		component: NewPasswordComponent,
		pathMatch: 'full',
	},
	{
		path: 'quiz',
		component: QuizResponsesComponent,
		// canActivate: [NoAuthGuard],
	},
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full',
	},
	{
		path: 'painel',
		redirectTo: '/painel/dashboard',
		pathMatch: 'full',
	},
	{
		path: 'result-exams/:token/:id',
		component: ResultExamsComponent,
	},
	{
		path: 'painel',
		component: PanelComponent,
		data: {
			title: 'Painel',
		},
		children: [
			{
				path: 'dashboard',
				redirectTo: '/painel/lista-de-pacientes',
				pathMatch: 'full',
			},
			{
				path: 'minha-conta',
				component: ProfileComponent,
				canActivate: [AuthGuard],
				data: {
					roles,
					title: 'Minha conta',
				},
			},
			{
				path: 'lista-de-pacientes',
				component: QueueComponent,
				canActivate: [AuthGuard],
				data: {
					roles,
					title: 'Lista de pacientes',
				},
			},
			{
				path: 'consulta',
				component: AppointmentComponent,
				canActivate: [AuthGuard],
				data: {
					roles,
					title: 'Consulta',
				},
			},
			{
				path: 'faq',
				component: FaqComponent,
				canActivate: [AuthGuard],
				data: {
					roles,
					title: 'Perguntas frequentes',
				},
			},
			{
				path: 'status',
				component: StatusComponent,
				canActivate: [AuthGuard],
				data: {
					roles: roles,
				},
			},
			{
				path: 'checagem-do-sistema',
				component: ConfigComponent,
				canActivate: [AuthGuard],
				data: {
					roles: roles,
				},
			},
			{
				path: 'onboard',
				component: PageOnboardComponent,
				canActivate: [AuthGuard],
				data: {
					roles: roles,
				},
			},
		],
	},
	{
		path: '404',
		component: NotFoundComponent,
		data: { title: 'Página não encontrada' },
	},
	{
		path: '**',
		redirectTo: '/404',
		data: { title: 'Página não encontrada' },
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import {
    Component,
    ContentChildren, EventEmitter,
    Input,
    OnInit, Output,
    QueryList
} from '@angular/core'
import { TabComponent } from './tab/tab.component'

@Component({
	selector: 'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
	@ContentChildren(TabComponent) tabs?: QueryList<TabComponent>
	@Input() setAlerts?: number
    @Output() tabOpenEvent = new EventEmitter<string>();

    ngAfterContentInit() {
		let activeTabs = this.tabs?.filter((tab: { active: any }) => tab.active)

		if (activeTabs?.length === 0) {
			this.selectTab(this.tabs?.first)
		}
	}

	selectTab(tab: any) {
		this.tabs?.toArray()
			.forEach((tab: { active: boolean }) => (tab.active = false))
		tab.active = true
        this.tabOpenEvent.emit(tab.title)
	}

	ngOnInit(): void {}
}

<div class="wrapper wrapper-video-testing">
	<h2>Vídeo</h2>
	<div class="wrapper-content">
		<div class="devices" *ngIf="optionsVideo.length">
			<div
				*ngFor="let item of optionsVideo; index as i"
				(click)="changeVideoInput(item.value, (currentSelected = i))"
				[class.active]="currentSelected == i"
			>
				{{ item.label }}
			</div>
		</div>
		<div class="devices no-data" *ngIf="!optionsVideo.length">
			<div>Nenhum device localizado</div>
		</div>
		<div class="content p-0 sh-0">
			<video #originalVideo autoplay></video>
		</div>
	</div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HelpersService } from '../../../services/helpers/helpers.service'

@Component({
	selector: 'app-testing-input-audio',
	templateUrl: './testing-input-audio.component.html',
	styleUrls: ['./testing-input-audio.component.scss'],
})
export class TestingInputAudioComponent implements OnInit {
	@ViewChild('audioCanvas', { static: false })
	public _audioCanvasRef?: ElementRef
	private audioCanvas?: HTMLCanvasElement

	showConfig?: boolean = false

	optionAudioInputSelected: string = 'default'
	optionsAudioInput: any[] = []

	audioPreview: string = ''

	leftchannel: any[] = []
	rightchannel: any[] = []
	recording: boolean = false
	recordingLength: number = 0
	volume: any = null
	audioInput: any = null
	sampleRate: any = null
	//@ts-ignore
	AudioContext: any = window.AudioContext || window.webkitAudioContext
	context: any = null
	analyser: any = null
	canvasCtx?: any
	stream: any = null
	tested: boolean = false
	currentSelected: number = 0

	constructor(private helpers: HelpersService) {}

	ngOnInit(): void {
		this.init()
	}

	async init(): Promise<void> {
		this.getDevices()
		try {
			this.stream = await this.getStream()
		} catch (e) {
			// infromar problema com permissão
		}
		this.audioCanvas = this._audioCanvasRef?.nativeElement

		if (this.audioCanvas) {
			this.canvasCtx = this.audioCanvas.getContext('2d')
			this.setUpRecording()
		}
	}

	getStream(constraints?: any) {
		if (!constraints) {
			constraints = { audio: true, video: false }
		}
		return navigator.mediaDevices.getUserMedia(constraints)
	}

	setUpRecording() {
		this.context = new this.AudioContext()
		this.sampleRate = this.context.sampleRate
		this.volume = this.context.createGain()
		this.audioInput = this.context.createMediaStreamSource(this.stream)
		this.analyser = this.context.createAnalyser()
		this.audioInput.connect(this.analyser)

		let bufferSize = 2048
		let recorder = this.context.createScriptProcessor(bufferSize, 2, 2)

		this.analyser.connect(recorder)
		recorder.connect(this.context.destination)
		recorder.onaudioprocess = (e: any) => {
			if (!this.recording) return
			let left = e.inputBuffer.getChannelData(0)
			let right = e.inputBuffer.getChannelData(1)
			if (!this.tested) {
				this.tested = true
				if (!left.reduce((a: number, b: number) => a + b)) {
					alert('There seems to be an issue with your Mic')
					this.stream
						.getTracks()
						.forEach((track: MediaStreamTrack) => {
							track.stop()
						})
					this.context.close()
				}
			}
			this.leftchannel.push(new Float32Array(left))
			this.rightchannel.push(new Float32Array(right))
			this.recordingLength += bufferSize
		}
		this.visualize()
	}

	visualize() {
		const WIDTH = this.audioCanvas?.width || 100
		const HEIGHT = this.audioCanvas?.height || 100

		if (!this.analyser && !this.canvasCtx) return

		this.analyser.fftSize = 2048
		let bufferLength = this.analyser.fftSize
		// console.log(bufferLength);
		let dataArray = new Uint8Array(bufferLength)

		this.canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)

		let draw = () => {
			const drawVisual = requestAnimationFrame(draw)

			this.analyser.getByteTimeDomainData(dataArray)

			this.canvasCtx.fillStyle = 'rgb(255, 255, 255)'
			this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT)

			this.canvasCtx.lineWidth = 2
			this.canvasCtx.strokeStyle = 'rgb(57, 63, 101)'

			this.canvasCtx.beginPath()

			let sliceWidth = (WIDTH * 1.0) / bufferLength
			let x = 0

			for (let i = 0; i < bufferLength; i++) {
				let v = dataArray[i] / 128.0
				let y = (v * HEIGHT) / 2

				if (i === 0) {
					this.canvasCtx.moveTo(x, y)
				} else {
					this.canvasCtx.lineTo(x, y)
				}

				x += sliceWidth
			}

			this.canvasCtx.lineTo(WIDTH, HEIGHT / 2)
			this.canvasCtx.stroke()
		}

		draw()
	}

	getDevices() {
		navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
			this.optionsAudioInput = []
			this.helpers.setDevicesInList(
				mediaDevices,
				this.optionsAudioInput,
				'audioinput'
			)

			console.log(this.optionsAudioInput)
		})
	}

	async changeAudioInput(val: any, current: number) {
		this.currentSelected = current
		this.optionAudioInputSelected = val
		if (this.optionAudioInputSelected !== '') {
			this.stream.getTracks().forEach((track: MediaStreamTrack) => {
				track.stop()
			})
			this.context?.close()

			this.stream = await this.getStream({
				audio: {
					deviceId: { exact: this.optionAudioInputSelected },
				},
				video: false,
			})
			this.setUpRecording()
			// this.closeModalConfiguration()
		}
	}

	// openModalConfiguration(): void {
	// 	this.showConfig = !this.showConfig
	//
	// 	if (this.showConfig) {
	// 		this.getDevices()
	// 	}
	// }

	// closeModalConfiguration(): void {
	// 	this.showConfig = false
	// }
}

import { ActionMeMedTypes } from './me-med.action'
import { ActionModel } from '../action'
import { MeMed } from '../../models/me-med'

const initialState: MeMed = { active: false, error: false, loading: false }

export function meMedReducer(state: MeMed = initialState, action: ActionModel) {
	switch (action.type) {
		case ActionMeMedTypes.DoRequest: {
			state = {
                ...initialState,
				loading: true,
			}
			return state
		}

		case ActionMeMedTypes.RequestSuccess: {
			state = {
                ...initialState,
                active: action.payload,
			}
			return state
		}

		case ActionMeMedTypes.RequestFailure: {
			state = {
				...initialState,
				error: true,
			}
			return state
		}

		default:
			return state
	}
}

import { Component, OnInit } from '@angular/core'
import { AppointmentsService } from '../../services/appointments/appointments.service'
import { Observable, Subscription } from 'rxjs'
import { Appointment } from '../../models/appointment'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { Patient } from '../../models/patient'
import { ToastrService } from 'ngx-toastr'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { Router } from '@angular/router'
import { MeMedService } from '../../services/me-med/me-med.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from '../../components/modals/modal-default/modal-default.component'
import { ModalFeedbackComponent } from '../../components/modals/modal-feedback/modal-feedback.component'
import { ModalFinalizedAppointmentComponent } from '../../components/modals/modal-finalized-appointment/modal-finalized-appointment.component'
import { StatsService } from '../../services/stats/stats.service'
import { SocketIoService } from '../../services/socket-io/socket-io.service'
import { AuthService } from '../../services/auth/auth.service'
import { MeMed } from '../../models/me-med'

@Component({
	selector: 'app-appointment',
	templateUrl: './appointment.component.html',
	styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {
	private subscriptions: Subscription[] = []
	private patient$?: Observable<Patient>
	patient?: Patient
	private appointment$?: Observable<Appointment>
	appointment?: Appointment
	private meMed$?: Observable<MeMed>
	meMed?: MeMed

	full: boolean = false
	showExams: boolean = false
	report: string = ''
	releasedPatient: boolean = false

	token: string = ''
	clientName: string = ''

	alreadyInitialized: boolean = false

	isLayerFull: boolean = false

    showFeedback: boolean = false

	constructor(
		private store: Store<AppState>,
		private appointmentService: AppointmentsService,
		private meMedService: MeMedService,
		private toast: ToastrService,
		private router: Router,
		private modalDefault: NgbModal,
		private statsService: StatsService,
		private socketIoService: SocketIoService,
		private authService: AuthService
	) {
		if (this.appointmentService.hasStoragePatientAndAppointment()) {
			this.appointmentService.getStoragePatientAndAppointment()
			this.appointment$ = this.store.select('appointment')
			this.patient$ = this.store.select('patient')
			this.meMed$ = this.store.select('meMed')

			this.subscriptions.push(
				this.appointment$.subscribe((state) => {
					this.appointment = state

					if (!this.alreadyInitialized && this.appointment.hash) {
						this.showExams = true
						this.report =
							this.appointmentService.getReportLocale() ||
							'' ||
							state.note ||
							''

						this.appointmentService.getTokenVideoCall(
							this.appointment.hash
						)
						this.alreadyInitialized = true
					}

					this.token = state.tokenVideoCall || ''
				})
			)

			this.subscriptions.push(
				this.patient$.subscribe((state) => {
					this.patient = state
				})
			)
			this.subscriptions.push(
				this.meMed$.subscribe((state) => {
					this.meMed = state
				})
			)
		}
	}

	ngOnInit(): void {
		if (!this.appointmentService.hasStoragePatientAndAppointment()) {
			this.toast.error(
				'Não existe paciente para ser consultado no momento!'
			)
			this.router.navigate(['/painel/lista-de-pacientes'])
		} else {
			this.socketIoService.emitEventSocket(
				'appointmentStarted',
				{
					qrCode: this.appointment?.hash,
				},
				this.appointment
			)
			this.getStatusAppointment()
			this.initMeMed()
			this.statsService.stats(
				'init_appointment_doctor',
				'start',
				this.appointment?.hash
			)
		}
		//Open modal when confirming feedback submission
		// this.openModalConfirmFeedback()
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => {
			subscription.unsubscribe()
		})

		//@ts-ignore
		if (window.MdHub) {
			//@ts-ignore
			window.MdHub.server.unbindEvents()
			//@ts-ignore
			delete window.MdHub
		}
		this.statsService.stats(
			'init_appointment_doctor',
			'end',
			this.appointment?.hash
		)
	}

	private initMeMed() {
		if (this.appointment) {
			const { clientId } = this.appointment
			const user = this.authService.getUserLocale()
			const { clients } = user
			const clientSelected = clients[clientId]

			if (clientSelected.memed) {
				this.meMedService.initMeMed()
			}
		}
	}

	private emitFinalizedAppointmentWebSocket(): void {
		this.socketIoService.emitEventSocket(
			'appointmentFinalized',
			{
				qrCode: this.appointment?.hash,
			},
			this.appointment
		)
	}

	changeReport(data: string): void {
		this.report = data
		this.appointmentService.setReportLocale(this.report)
	}

	getStatusAppointment(): void {
		if (this.appointment?.id) {
			this.appointmentService.getAppointment(this.appointment.id)
		}
	}

	openModalConfirmReleasePatient(): void {
		const modalRef = this.modalDefault.open(ModalDefaultComponent)
		modalRef.componentInstance.title =
			'Deseja liberar paciente da consulta?'
		modalRef.componentInstance.desc =
			'Essa ação não poderá ser alterada após sua confirmação.'
		modalRef.componentInstance.callbackConfirmation = () => {
			this.setReleasePatient()
		}
	}

	// openModalConfirmFeedback(): void {
	// 	const modalRef = this.modalDefault.open(ModalFeedbackComponent)
	// 	modalRef.componentInstance.title = 'Feedback enviado'
	// 	modalRef.componentInstance.desc =
	// 		'Você pode retornar a lista de pacientes ou rever o prontuário da última consulta'
	// 	modalRef.componentInstance.img = '/plane.svg'
	// 	modalRef.componentInstance.callbackConfirmation = () => {
	// 		this.setReleasePatient()
	// 	}
	// }

	setReleasePatient(): void {
		const self = this
		if (self.appointment?.id) {
			self.statsService.stats(
				'release_patient_appointment_doctor',
				'start',
				self.appointment.hash
			)
			self.store.dispatch(ShowLoading())
			self.appointmentService.releasePatient(self.appointment.id, {
				fnSuccess() {
					self.toast.success('Paciente liberado da video chamada')
					self.emitFinalizedAppointmentWebSocket()
					self.getStatusAppointment()
					self.releasedPatient = true
					self.statsService.stats(
						'release_patient_appointment_doctor',
						'end',
						self.appointment?.hash
					)
				},
				fnError(responseError) {
					if (
						responseError.status >= 400 &&
						responseError.status < 500
					) {
						self.toast.error(
							'Ocorreu um erro na liberação do pacience da video chamada.'
						)
					} else {
						self.toast.error(
							`Status: ${responseError.status}`,
							'Server Error!'
						)
					}
					console.warn(responseError)
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			})
		}
	}

	openConfirmFinalizedAppointment(): void {
        this.showFeedback = true
	}

	toggleLayerExamsFull(): void {
		this.isLayerFull = !this.isLayerFull
	}

	finalizedAppointment(dataFeedback: any): void {
        const self = this

		if (self.appointment?.id) {
			self.statsService.stats(
				'finalized_appointment_doctor',
				'start',
				self.appointment.hash
			)
			self.store.dispatch(ShowLoading())
			self.appointmentService.finalized(
				self.appointment,
				{
					report: self.report || '',
                    dataFeedback
				},
				{
					fnSuccess() {
						self.toast.success('Consulta finalizada com sucesso.')
						self.appointmentService.clearStoragePatientAndAppointment()
						self.router.navigate(['/painel/lista-de-pacientes'])
						self.statsService.stats(
							'finalized_appointment_doctor',
							'end',
							self.appointment?.hash
						)
					},
					fnError(responseError) {
						if (
							responseError.status >= 400 &&
							responseError.status < 500
						) {
							self.toast.error(
								'Ocorreu um erro no encerramento da consulta.'
							)
						} else {
							self.toast.error(
								`Status: ${responseError.status}`,
								'Server Error!'
							)
						}
						console.warn(responseError)
					},
					fnFinalized() {
						self.store.dispatch(HiddenLoading())
					},
				}
			)
		}
	}
}

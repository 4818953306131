import { Directive, ElementRef, Input } from '@angular/core'
import { HelpersService } from '../services/helpers/helpers.service'

@Directive({
    selector: '[setFormControlError]',
})
export class SetFormControlErrorDirective {
    @Input()
    set setFormControlError(body: { objError: any; control?: string }) {
        this.elementRef.nativeElement.innerHTML =
            this.helpersService.getControlMessageError(body.objError, body.control)
    }

    constructor(
        private helpersService: HelpersService,
        private elementRef: ElementRef
    ) {}
}

<nav>
	<app-sidebar-item
		title="Minha conta"
		image="/assets/images/sidebar/account.svg"
		imageActive="/assets/images/sidebar/account-active.svg"
		link="minha-conta"
	></app-sidebar-item>
	<app-sidebar-item
		title="Lista de pacientes"
		image="/assets/images/sidebar/list.svg"
		imageActive="/assets/images/sidebar/list-active.svg"
		link="lista-de-pacientes"
	></app-sidebar-item>
	<app-sidebar-item
		*ngIf="showMeMed"
		title="Prescrição MeMed"
		image="/assets/images/sidebar/memed.svg"
		imageActive="/assets/images/sidebar/memed-active.svg"
		[isButton]="true"
		(click)="openModuleMeMed()"
		[disabled]="disabledMeMed"
	></app-sidebar-item>
	<app-sidebar-item
		*ngIf="showMeMed"
		title="Atestado MeMed"
		image="/assets/images/sidebar/memed-2.svg"
		imageActive="/assets/images/sidebar/memed-2-active.svg"
		[isButton]="true"
		(click)="openModalMedicalRest()"
		[disabled]="disabledMeMed"
	></app-sidebar-item>
	<app-sidebar-item
		title="Perguntas frequentes"
		image="/assets/images/sidebar/faq.svg"
		imageActive="/assets/images/sidebar/faq-active.svg"
		link="faq"
	></app-sidebar-item>
	<app-sidebar-item
		title="Status do sistema"
		image="/assets/images/sidebar/status.svg"
		imageActive="/assets/images/sidebar/status-active.svg"
		link="status"
	></app-sidebar-item>
	<app-sidebar-item
		title="Checagem do sistema"
		image="/assets/images/sidebar/check.svg"
		imageActive="/assets/images/sidebar/check-active.svg"
		link="checagem-do-sistema"
	></app-sidebar-item>
	<app-sidebar-item
		title="Tutorial"
		image="/assets/images/sidebar/omboard.svg"
		imageActive="/assets/images/sidebar/omboard-active.svg"
		link="onboard"
	></app-sidebar-item>
	<app-sidebar-item
		title="Sair"
		image="/assets/images/sidebar/logout.svg"
		imageActive="/assets/images/sidebar/logout-active.svg"
		[isButton]="true"
		(click)="logout()"
	></app-sidebar-item>
</nav>

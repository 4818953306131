import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-score',
	templateUrl: './score.component.html',
	styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit {
	@Input() score: any = 0
	result: number = 0
	@Input() hasDescription: boolean = true

	constructor() {}

	ngOnInit(): void {}

	scoreInit(val: any) {
		return (this.result = (180 / 100) * val)
	}
}

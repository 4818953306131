import { ElementRef } from '@angular/core'
import { RemoteAudioTrack, RemoteParticipant, RemoteTrack, RemoteVideoTrack } from 'twilio-video'
import { Nullable } from '../models/nullable'

/**
 * Attaches all attachable published tracks from the remote participant.
 *
 * @param publications
 * The list of possible publications to attach.
 */
export function attachAttachableTracksForRemoteParticipant(
    participant: RemoteParticipant,
    remoteMediaContainer?: ElementRef
) {
    participant.tracks.forEach((publication) => {
        if (!publication.isSubscribed) return

        if (!trackExistsAndIsAttachable(publication.track)) return

        attachTrack(publication.track, remoteMediaContainer)
    })
}

/**
 * Attaches a remote track.
 *
 * @param track
 * The remote track to attach.
 */
export function attachTrack(
    track: RemoteAudioTrack | RemoteVideoTrack,
    remoteMediaContainer?: ElementRef
) {
    const container = remoteMediaContainer?.nativeElement
    container.appendChild(track.attach())
    const videos = container.querySelectorAll('video')

    if(videos.length > 1) {
        videos[0].remove()
    }
}

/**
 * Guard that a track is attachable.
 *
 * @param track
 * The remote track candidate.
 */
export function trackExistsAndIsAttachable(
    track?: Nullable<RemoteTrack>
): track is RemoteAudioTrack | RemoteVideoTrack {
    return (
        !!track &&
        ((track as RemoteAudioTrack).attach !== undefined ||
            (track as RemoteVideoTrack).attach !== undefined)
    )
}

/**
 * Triggers when a remote participant connects to the room.
 *
 * @param participant
 * The remote participant
 */
export function onParticipantConnected(
    participant: RemoteParticipant,
    remoteMediaContainer?: ElementRef
) {
    manageTracksForRemoteParticipant(participant, remoteMediaContainer)
}

/**
 * Triggers when a remote participant disconnects from the room.
 *
 * @param participant
 * The remote participant
 */
export function onParticipantDisconnected(participant: RemoteParticipant) {
    document.getElementById(participant.sid)?.remove()
}

/**
 * Triggers when a remote track is subscribed to.
 *
 * @param track
 * The remote track
 */
export function onTrackSubscribed(
    track: RemoteTrack,
    remoteMediaContainer?: ElementRef
) {
    if (!trackExistsAndIsAttachable(track)) return

    attachTrack(track, remoteMediaContainer)
}

/**
 * Triggers when a remote track is unsubscribed from.
 *
 * @param track
 * The remote track
 */
export function onTrackUnsubscribed(track: RemoteTrack) {
    if (trackExistsAndIsAttachable(track))
        track.detach().forEach((element) => element.remove())
}

/**
 * Manages track attachment and subscription for a remote participant.
 *
 * @param participant
 * The remote participant
 */
export function manageTracksForRemoteParticipant(
    participant: RemoteParticipant,
    remoteMediaContainer?: ElementRef
) {
    // Handle tracks that this participant has already published.
    attachAttachableTracksForRemoteParticipant(
        participant,
        remoteMediaContainer
    )

    // Handles tracks that this participant eventually publishes.
    participant.on('trackSubscribed', (participant) => {
        onTrackSubscribed(participant, remoteMediaContainer)
    })
    participant.on('trackUnsubscribed', onTrackUnsubscribed)
}

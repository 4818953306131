import { Action } from '@ngrx/store'
import { Patient } from '../../models/patient'

export enum ActionPatientTypes {
	Add = '@PATIENT/ADD',
	Clear = '@PATIENT/CLEAR',
}

export const AddPatient = (patient: Patient) => {
	return <Action>{ type: ActionPatientTypes.Add, payload: patient }
}

export const ClearPatient = () => {
	return <Action>{ type: ActionPatientTypes.Clear, payload: null }
}

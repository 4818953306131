import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-local',
  templateUrl: './loading-local.component.html',
  styleUrls: ['./loading-local.component.scss']
})
export class LoadingLocalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="wrapper">
	<a *ngIf="!current" [routerLink]="urlPath" class="float-button">Pular</a>
	<ngx-slick-carousel
		class="carousel"
		#slickModal="slick-carousel"
		[config]="slideConfig"
		(beforeChange)="change($event)"
	>
		<div ngxSlickItem class="slide" *ngFor="let slide of slides">
			<figure class="mb-5" [class.logo]="slide.id >= 2">
				<img
					[src]="
						slide.id <= 1
							? '/assets/images/logo-hai-big.svg'
							: '/assets/images/logo.svg'
					"
					alt="H.ai"
					title="H.ai"
				/>
			</figure>
			<h2 class="mb-4">{{ slide.title }}</h2>
			<p [innerHTML]="slide.description"></p>
			<br /><br />
			<figure class="icon" *ngIf="slide.image">
				<img
					[src]="'/assets/images/icons/' + slide.image"
					[alt]="slide.title"
					[title]="slide.title"
				/>
			</figure>
		</div>
	</ngx-slick-carousel>
	<div class="wrapper-button">
		<button class="prev btn">VOLTAR</button>
		<div class="d-flex position-relative">
			<button class="next btn">PRÓXIMO</button>
			<a *ngIf="current" [routerLink]="urlPath" class="btn btn-router"
				>COMEÇAR</a
			>
		</div>
	</div>
</div>

import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-config',
	templateUrl: './config.component.html',
	styleUrls: ['./config.component.scss'],
})
export class ConfigComponent implements OnInit {
	current: number = 0
	listItems: any[] = []

	constructor() {
		this.listItems = [
			{ title: 'Serviços', path: '/services' },
			{ title: 'Microfone', path: '/mic' },
			{ title: 'Vídeo', path: '/camera' },
			{ title: 'Áudio', path: '/fone' },
		]
	}

	ngOnInit(): void {}

	setCurrent(num: number) {
		this.current = num
	}

	nextStepSistemCheck() {
		this.current++
	}
}

import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
	selector: 'app-radios',
	templateUrl: './radios.component.html',
	styleUrls: ['./radios.component.scss'],
})
export class RadiosComponent implements OnInit {
    @Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
    @Input() name: string | number | null = ''
	@Input() labelName?: string

	@Input() inputModel: any

	constructor() {}

	ngOnInit(): void {}
}

import { ActionPatientExamsTypes } from './patient-exams.action'
import { ActionModel } from '../action'
import { PatientExams } from '../../models/patient-exams'

const initialState: PatientExams = {
	loading: false,
	error: false,
	historyExams: [],
    historyReports: []
}

export function patientExamsReducer(
	state: PatientExams = initialState,
	action: ActionModel
) {
	switch (action.type) {
		case ActionPatientExamsTypes.DoRequest: {
			state = {
				...state,
				loading: true,
			}
			return state
		}

		case ActionPatientExamsTypes.RequestSuccessExams: {
			state = {
				...state,
				loading: false,
                historyExams: action.payload,
			}
			return state
		}

        case ActionPatientExamsTypes.RequestSuccessReport: {
            state = {
                ...state,
                loading: false,
                historyReports: action.payload,
            }
            return state
        }



		// case ActionPatientExamsTypes.RequestSuccessHistoryExamsFile: {
		// 	let history = state.historyDataExamsFile?.length
		// 		? [...state.historyDataExamsFile, action.payload]
		// 		: [action.payload]
        //
        //     history = history.sort((a, b) => (a.date > b.date ) ? -1 : 1)
        //
		// 	state = {
		// 		...state,
		// 		loading: false,
		// 		historyDataExamsFile: history,
		// 	}
		// 	return state
		// }

		case ActionPatientExamsTypes.RequestFailure: {
			state = {
				...state,
				loading: false,
				error: true,
			}
			return state
		}

        case ActionPatientExamsTypes.RequestClear: {
            state = {
                ...initialState
            }
            return state
        }

		default:
			return state
	}
}

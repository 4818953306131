<div [class]="title ? 'wrapper-status-device' : 'wrapper-status'">
	<div>
		<p class="title" *ngIf="title">{{ title }}</p>
	</div>
	<figure>
		<img
			draggable="false"
			[src]="'/assets/images/status-' + path + '.png'"
			[alt]="title"
			[title]="title"
		/>
	</figure>
	<div [class]="title ? 'mb-2' : 'mt-2'">
		<div *ngFor="let device of devices">
			<p class="pl-2">- {{ device.label }}</p>
		</div>
		<div *ngIf="!devices?.length">
			<p class="pl-2">{{ labelDanger }}</p>
		</div>
	</div>
</div>

<div class='container'>
    <div class='wrapper-not-found'>
        <div>
            <span>=/</span>
        </div>
        <div>
            <span>404</span>
        </div>
    </div>
    <h2>PÁGINA NÃO ENCONTRADA</h2>
    <p>Desculpe a página solicitada não pode ser encontrada.</p>
    <button class="btn text-white p-2 d-inline-block mt-5 m-x-auto w-25" (click)="backToPage()">Voltar</button>
</div>

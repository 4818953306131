<div class="wrapper__faq">
	<h3>Perguntas frequentes</h3>
	<app-accordion
		*ngFor="let faq of listFaq; index as i"
		[title]="faq.title"
		[description]="faq.description"
		[bgOdd]="i % 2 === 0"
	>
		<div accordionVideo>
			<video controls>
				<source [src]="faq.video" />
			</video>
		</div>
	</app-accordion>
</div>

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-status-image',
	templateUrl: './status-image.component.html',
	styleUrls: ['./status-image.component.scss'],
})
export class StatusImageComponent implements OnInit {
	@Input() title?: string
	@Input() labelDanger?: string
	@Input() path?: string = ''
	@Input() devices?: any[] = []

	constructor() {}

	ngOnInit(): void {}
}

<div class="modal-body">
	<figure *ngIf="img">
		<img [src]="'./assets/images' + img" [alt]="title" [title]="title" />
	</figure>
	<h5 *ngIf="title">{{ title }}</h5>
	<p *ngIf="desc">{{ desc }}</p>
</div>
<div class="modal-footer mt-4">
	<a type="button" class="btn" (click)="confirmation()">
		Lista de pacientes
	</a>
	<!--	<button type="button" class="btn outline" (click)="close()">-->
	<!--		Histórico de prontuários-->
	<!--	</button>-->
</div>

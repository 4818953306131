import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HelpersService } from '../../../services/helpers/helpers.service'

@Component({
	selector: 'app-testing-input-video',
	templateUrl: './testing-input-video.component.html',
	styleUrls: ['./testing-input-video.component.scss'],
})
export class TestingInputVideoComponent implements OnInit {
	@ViewChild('originalVideo', { static: false })
	public _videoRef?: ElementRef
	private video?: HTMLVideoElement

	showConfig: boolean = false
	optionVideoSelected: string = 'default'
	optionsVideo: any[] = []
	currentSelected: number = 0

	constructor(private helpers: HelpersService) {}

	ngOnInit(): void {
		this.getDevices()
	}

	ngAfterViewInit() {
		this.video = this._videoRef?.nativeElement
		this.getAccessDevices()
	}

	videoStream(stream: any, video?: HTMLVideoElement) {
		if (video) {
			video.srcObject = stream
			video.play()
		}
	}

	getAccessDevices() {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then((stream) => {
					this.videoStream(stream, this.video)
				})
		}
	}

	getDevices() {
		navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
			this.optionsVideo = []
			this.helpers.setDevicesInList(
				mediaDevices,
				this.optionsVideo,
				'videoinput'
			)
		})
	}

	async changeVideoInput(val: any, current: number) {
		this.currentSelected = current
		this.optionVideoSelected = val
		if (this.optionVideoSelected !== '') {
			navigator.mediaDevices
				.getUserMedia({
					video: {
						deviceId: { exact: this.optionVideoSelected },
					},
				})
				.then((stream) => {
					this.videoStream(stream, this.video)
				})
			this.closeModalConfiguration()
		}
	}

	// openModalConfiguration(): void {
	//     this.showConfig = !this.showConfig
	//
	//     if (this.showConfig) {
	//         this.getDevices()
	//     }
	// }

	closeModalConfiguration(): void {
		this.showConfig = false
	}
}

<ul>
    <li *ngIf='musclePercentage'>
        Porcentagem de músculo: <strong>{{ parseToDecimal(musclePercentage) }}%</strong>
    </li>
    <li *ngIf='fatPercentage'>
        Porcentagem de gordura: <strong>{{ parseToDecimal(fatPercentage) }}%</strong>
    </li>
    <li *ngIf='visceralFat'>
        Gordura visceral: <strong>{{ parseToDecimal(visceralFat) }}cm²</strong>
    </li>
    <li *ngIf='weight'>
        Peso: <strong>{{ parseToDecimal(weight) }}kg</strong>
    </li>
    <li *ngIf='height'>
        Altura: <strong>{{ parseToDecimal(height) }}cm</strong>
    </li>
    <li *ngIf='imc'>
        IMC (Indice Massa Corporal): <strong>{{ parseToDecimal(imc) }}kg/m²</strong>
        <span [class]='typeMessage.type'>{{ typeMessage.status }}</span>
    </li>
</ul>

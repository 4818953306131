<div class="wrapper">
	<h2>Microfone</h2>
	<div class="wrapper-content">
		<div class="devices">
			<div
				*ngFor="let item of optionsAudioInput; index as i"
				(click)="changeAudioInput(item.value, (currentSelected = i))"
				[class.active]="currentSelected == i"
			>
				{{ item.label }}
			</div>
		</div>
		<div class="devices no-data" *ngIf="!optionsAudioInput.length">
			<div>Nenhum device localizado</div>
		</div>
		<div class="content">
			<canvas #audioCanvas></canvas>
		</div>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
	selector: 'app-modal-configuration',
	templateUrl: './modal-configuration.component.html',
	styleUrls: ['./modal-configuration.component.scss'],
})
export class ModalConfigurationComponent implements OnInit {
	@Input() callbackConfiguration?: Function
	@Input() title?: string
	form: FormGroup

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder
	) {
		this.form = this.formBuilder.group({
			mic: [null, [Validators.required]],
			camera: [null, Validators.required],
		})
	}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	creation() {
		if (this.callbackConfiguration) this.callbackConfiguration()
	}

	onSubmit(): void {
		console.log('Submitting!!')
	}
}

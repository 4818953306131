import { Action } from '@ngrx/store'
import { ItemQueue } from '../../models/queue'

export enum ActionAppointmentsFinalizedListTypes {
	DoRequest = '@APPOINTMENTS_FINALIZED/DO_REQUEST',
	RequestSuccess = '@APPOINTMENTS_FINALIZED/REQUEST_SUCCESS',
    RequestFailure = '@APPOINTMENTS_FINALIZED/REQUEST_FAILURE',
    RequestClear = '@APPOINTMENTS_FINALIZED/REQUEST_CLEAR',
}

export const DoRequestAppointmentsFinalizedList = () => {
	return <Action>{ type: ActionAppointmentsFinalizedListTypes.DoRequest }
}

export const RequestSuccessAppointmentsFinalizedList = (queue: ItemQueue[]) => {
    return <Action>{ type: ActionAppointmentsFinalizedListTypes.RequestSuccess, payload: queue }
}

export const RequestFailureAppointmentsFinalizedList = () => {
    return <Action>{ type: ActionAppointmentsFinalizedListTypes.RequestFailure }
}

export const ClearAppointmentsFinalizedList = () => {
    return <Action>{ type: ActionAppointmentsFinalizedListTypes.RequestClear }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { Select2OptionData } from 'ng-select2'
import { FeedbackCidsService } from '../../services/feedback/feedback-cids.service'
import { feedbackChecks } from '../../mocks/feedback'
import { FeedbackChecks } from '../../models/feedback-checks'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultInfoComponent } from '../modals/modal-default-info/modal-default-info.component'

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
	@Output() callbackConfirmation: EventEmitter<any> = new EventEmitter()
	public listCid: Array<Select2OptionData> = []
	public listCidFiltered: Array<Select2OptionData> = []
	public listCidSelected: any[] = []
	public showCids: boolean = false
	public showError: boolean = false
	public showDesc: boolean = false
	public disabledFeedback: boolean = false

	public checks: FeedbackChecks[] = feedbackChecks
	public startValue: string = ''
	public selected: string = ''
	form: FormGroup

	timeout: any

	constructor(
		private formBuilder: FormBuilder,
		private feedbackService: FeedbackCidsService,
		private modalDefault: NgbModal
	) {
		const checks = {}

		this.checks.forEach((item) => {
			// @ts-ignore
			checks[item.name] = new FormControl(item.value)
		})

		this.form = new FormGroup({
			...checks,
			someTechnicalProblem: new FormControl(false),
			description: new FormControl(''),
			confirmAnswers: new FormControl(false),
			notAnswer: new FormControl(false),
		})
	}

	ngOnInit(): void {
		this.getCids()
	}

	getCids(search?: string): any {
		const self = this

		this.feedbackService.getListCid(
			{
				fnSuccess(response: any) {
					const list = response.items.map(
						(el: { id: any; description: any }) => {
							return {
								id: el.id,
								text: el.description,
							}
						}
					)
                    self.listCidFiltered = []

					if (!search) {
						self.listCid = list
                        self.listCidFiltered = self.listCid
					} else {
						self.listCidFiltered = list
					}
				},
				fnError(errorResponse) {
					return errorResponse
				},
			},
			search
		)
	}

	notAnswerFeedback(): void {
		this.checks.forEach((item) => {
			this.form.controls[item.name].setValue(false)
		})

		this.form.controls['confirmAnswers'].setValue(false)
	}

	confirmAnswerFeedback() {
		this.form.controls['confirmAnswers'].setValue(true)
		this.form.controls['notAnswer'].setValue(false)
	}

	toggleDesc() {
		this.showDesc = !this.showDesc
		this.confirmAnswerFeedback()
		this.form.controls['description'].setValue('')
	}

	onSelectChange(item: any) {
		if (!this.listCidSelected.includes(item)) {
			this.listCidSelected.push(item)
            this.startValue = ''
		}
	}

	removeItemListSelected(id: any) {
		this.listCidSelected = this.listCidSelected.filter((ele) => {
			if (ele.id !== id) return ele
		})
	}

	changed(): void {
		clearTimeout(this.timeout)
		if (this.startValue) {
			setTimeout(() => {
				this.getCids(this.startValue)
			}, 100)
		} else {
            this.listCidFiltered = this.listCid
        }
	}

    toggleListCids() {
        setTimeout(() => {
            this.showCids = !this.showCids
        }, 150)
    }

	openModalDataWillBeUsed(): void {
		const modalRef = this.modalDefault.open(ModalDefaultInfoComponent)
		modalRef.componentInstance.callbackConfirmation = () => {
			console.log('Close modal!')
		}
	}

	onSubmit(): void {
		this.showError = false
		let isValid = true

		let isFormValid = false
		Object.entries(this.form.value).forEach((item) => {
			if (!isFormValid && item[1]) {
				isFormValid = true
			}
		})

		if (!isFormValid) {
			isValid = false
			this.showError = true
		}

		if (isValid) {
			const cids = this.listCidSelected.map((item) => item.id)
			const feedback_options: any[] = []

			Object.entries(this.form.value).forEach((item) => {
				let currentLabel: any = this.checks.find(
					(checkItem: any) => checkItem.name === item[0]
				)
				currentLabel = currentLabel?.label

				if (currentLabel) {
					const currentItem: any = {
						option: currentLabel,
						selected: item[1],
					}
					feedback_options.push(currentItem)
				}
			})

			feedback_options.push({
				option: 'algum_problema_tecnico',
				selected: this.form.controls['someTechnicalProblem'].value,
			})

			const data = {
				confirmed_answer: this.form.controls['confirmAnswers'].value,
				answered: this.form.controls['confirmAnswers'].value,
				description: this.form.controls['description'].value || '',
				feedback_options,
				cids,
			}
			this.callbackConfirmation.emit(data)
		}
	}
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ExamsService } from '../../services/exams/exams.service'
import { finalize } from 'rxjs/operators'

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {
    @Output() emmitEvent: EventEmitter<any> = new EventEmitter()
    @Input() public src?: string
    @Input() public title?: string
    @Input() public autoplay: boolean = false
    @Input() public showStateLabel: boolean = false
    @Input() public volume: number = 1.0
    @ViewChild('audioElement', { static: false })
    public _audioRef?: ElementRef
    private audio?: HTMLMediaElement

    public duration: number | undefined = 0
    public current: number = 0
    public audioTimer: string = '0:00'

    blob: string = ''
    linkFinal: string = ''

    state = {
        loading: false,
        error: false
    }

    constructor(private examsService: ExamsService) {
    }

    ngOnInit(): void {
    }

    public pause(): void {
        if (this.audio) {
            this.audio.pause()
        }
    }

    public get paused(): boolean {
        if (this.audio) {
            return this.audio.paused
        } else {
            return true
        }
    }

    public play(): void {
        if (this.src) {
            this.state.loading = true
            this.state.error = false

            this.emmitEvent.emit('eventPlay')

            this.examsService.getFile(this.src)
                .then((data) => {
                    this.blob = URL.createObjectURL(data)

                    if (this.audio && this.blob) {
                        this.audio.src = this.blob
                        this.audio.play()

                        this.state.loading = false
                        this.state.error = false
                    }
                }).catch(() => {
                this.state.loading = false
                this.state.error = true
            })
        }
    }

    downloadAsset(callback: Function) {
        if (this.src) {
            this.state.loading = true
            this.state.error = false

            this.examsService.geFileLinkFinal(this.src)
                .pipe(finalize(() => {
                    this.state.loading = false
                }))
                .subscribe(
                    (data) => {
                        this.linkFinal = data
                        callback()
                    },
                    (err) => {
                        console.warn(err)
                        this.state.error = true
                    }
                )
        }
    }

    public ngAfterViewInit() {
        this.audio = this._audioRef?.nativeElement
        if (this.audio) {
            this.audio.volume = this.volume
            this.audio.autoplay = this.autoplay
            this.timeUpdate()
        }
    }

    timeUpdate(): void {
        this.audio?.addEventListener('timeupdate', () => {
            let curTime = parseInt(`${this.audio?.currentTime}`, 10)
            this.duration = Math.floor(this.audio?.duration || 0)
            this.current = curTime
        })
    }

    setDuration(value: any): void {
        if (this.audio) {
            this.audio.currentTime = Math.floor(value)
            this.getTimeCodeFromNum(this.duration)
        }
    }

    getTimeCodeFromNum(num: any) {
        let seconds = parseInt(num)
        let minutes = parseInt(String(seconds / 60))
        seconds -= minutes * 60
        const hours = parseInt(String(minutes / 60))
        minutes -= hours * 60

        if (hours === 0)
            return `${minutes}:${String(seconds % 60).padStart(2, '0')}`
        return `${String(hours).padStart(2, '0')}:${minutes}:${String(
            seconds % 60
        ).padStart(2, '0')}`
    }
}

import { Directive, Input } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
	selector: '[setValue]',
})
export class SetValueDirective {
	@Input()
	set setValue(val: any) {
		setTimeout(() => {
			this.ngControl.control?.setValue(val)
		}, 1)
	}

	constructor(private ngControl: NgControl) {}
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'
import { Callbacks } from '../../models/callbacks'

@Injectable({
	providedIn: 'root',
})
export class QuizService {
	constructor(private helpers: HelpersService, private http: HttpClient) {}

	getQuiz(patientId: string, callbacks: Callbacks) {
		this.http
			.get<any>(`${environment.api}/latest/${patientId}/quiz`)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe({
				next: (response: any) => {
					callbacks.fnSuccess(response)
				},
				error: (error) => {
					callbacks.fnError(error)
					console.warn(error)
				},
			})
	}

	approveQuiz(id: string, data: any, callbacks: Callbacks) {
		this.http
			.put<any>(`${environment.api}/quiz/${id}/approve`, {
				is_approved: data.approve,
				description: data.description,
			})
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe({
				next: (response: any) => {
					callbacks.fnSuccess(response)
				},
				error: (error) => {
					callbacks.fnError(error)
				},
			})
	}
}

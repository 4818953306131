<span class='flag'
      (mousemove)='onMouseMove($event)' [class]="(type ? type : '') + ' ' + (pointer ? 'cursor-pointer' : '')">
	{{ title }}
    <span
        class='flag-tooltip'
        *ngIf='description'
        [ngStyle]="{ left: x + 'px', top: y + 'px' }"
    >
		{{ description }}
	</span>
</span>

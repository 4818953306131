<div class='input-check control-checkbox'>
    <label [for]="'c-' + id || name" [formGroup]='form'>
        <input
            type='radio'
            [id]="'c-' + id || name"
            [attr.name]='name'
            [formControlName]='"c-" + name'
            [setValue]='inputModel'
        />
        <div class='checkmark'></div>
        <span>
			<ng-content></ng-content>
		</span>
    </label>
    <span
        *ngIf='form.controls[name || ""]?.invalid &&
             (form.controls[name || ""]?.touched || form.controls[name || ""]?.dirty)'
        class='control-error'
        [setFormControlError]='{objError: form.controls[name || ""]?.errors, control: labelName}'
    >
        </span>
</div>

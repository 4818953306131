import { ActionAppointmentsFinalizedListTypes } from './appointments-finalized-list.action'
import { ActionModel } from '../action'
import { AppointmentsFinalizedList } from '../../models/appointments-finalized-list'

const initialState: AppointmentsFinalizedList = {
    loading: false,
    error: false,
    data: []
}

export function appointmentsFinalizedListReducer(state: AppointmentsFinalizedList = initialState, action: ActionModel) {
	switch (action.type) {
		case ActionAppointmentsFinalizedListTypes.DoRequest: {
			state = {
                ...state,
                loading: true,
            }
			return state
		}

        case ActionAppointmentsFinalizedListTypes.RequestSuccess: {
            state = {
                ...state,
                loading: false,
                data: action.payload
            }
            return state
        }

        case ActionAppointmentsFinalizedListTypes.RequestFailure: {
            state = {
                ...state,
                loading: false,
                error: true,
            }
            return state
        }

        case ActionAppointmentsFinalizedListTypes.RequestClear: {
            state = {
                ...initialState,
                loading: false,
                error: false,
            }
            return state
        }

		default:
			return state
	}
}

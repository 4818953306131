import { Component, OnInit } from '@angular/core';
import { VersionService } from '../../services/version/version.service'

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    appVersion: string = ''

    constructor(private versionService: VersionService) { }

    ngOnInit(): void {
        this.getVersion()
    }

    getVersion(): void {
        const self = this

        self.versionService.getVersion({
            fnSuccess(data?: any) {
                self.appVersion = data.split('=')[1]
            },
            fnError(error) {
                console.warn(error)
            },
        })
    }
}

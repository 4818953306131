import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import * as moment from 'moment'
import { environment } from '../../../environments/environment'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { AddPatient, ClearPatient } from '../../state/patient/patient.action'
import {
	AddAppointment,
	ClearAppointment,
} from '../../state/appointment/appointment.action'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { Appointment } from '../../models/appointment'
import { AuthService } from '../auth/auth.service'
import { ItemQueue } from '../../models/queue'

@Injectable({
	providedIn: 'root',
})
export class AppointmentsService {
	constructor(
		private http: HttpClient,
		private helpers: HelpersService,
		private store: Store<AppState>,
		private authService: AuthService
	) {}

	private setPatientAndAppointment(data: any, isPermanent?: boolean) {
		let { patient } = data
		patient = this.helpers.converterSnakeToCamelCase(patient)
		data = this.helpers.converterSnakeToCamelCase(data)
		if (data.patient) {
			delete data.patient
		}
		if (Object.keys(patient).length) {
			this.store.dispatch(AddPatient(patient))
		}
		this.store.dispatch(AddAppointment(data))

		if (isPermanent) {
			let appointmentData = data
			const appointmentOld = localStorage.getItem('appointment') || ''

			if (appointmentOld) {
				appointmentData = { ...JSON.parse(appointmentOld), ...data }
			}

			if (Object.keys(patient).length) {
				localStorage.setItem('patient', JSON.stringify(patient))
			}
			localStorage.setItem('appointment', JSON.stringify(appointmentData))
		}
	}

	clearStoragePatientAndAppointment(): void {
		localStorage.removeItem('patient')
		localStorage.removeItem('appointment')

		this.store.dispatch(ClearAppointment())
		this.store.dispatch(ClearPatient())
	}

	getStoragePatientAndAppointment(): void {
		const patient = JSON.parse(localStorage.getItem('patient') || 'null')
		const appointment = JSON.parse(
			localStorage.getItem('appointment') || 'null'
		)

		if (patient) {
			this.store.dispatch(AddPatient(patient))
		}
		if (appointment) {
			this.store.dispatch(AddAppointment(appointment))
		}
	}

	hasStoragePatientAndAppointment(): boolean {
		const patient = JSON.parse(localStorage.getItem('patient') || 'null')
		const appointment = JSON.parse(
			localStorage.getItem('appointment') || 'null'
		)

		return patient && appointment
	}

	getAppointmentsByDoctor(page: number = 1, callback: Callbacks) {
		const user = this.authService.getUserLocale()
		const params = {
			status: 'finalized',
			doctor: user.id,
			per_page: 50,
            page
		}
		this.http
			.get<any>(`${environment.api}/appointments`, { params })
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(response: any) => {
					callback.fnSuccess(response)
					console.log(
						'GET APPOINTMENTS BY DOCTOR SERVICE: ',
						response
					)
				},
				(err) => {
					console.warn(1, err)
					callback.fnError(err)
				}
			)
	}

	getAppointment(
		id: string,
		saveIsPermanent: boolean = false,
		callback?: Function
	): void {
		this.http
			.get<any>(`${environment.api}/appointments/${id}`)
			.pipe()
			.subscribe(
				(response: any) => {
					const { data } = response
					console.log('APPOINTMENT SERVICE: ', data)
					this.setPatientAndAppointment(data, saveIsPermanent)
					if (callback) callback()
				},
				(err) => {
					console.warn(1, err)
				}
			)
	}

	startAppointment(
		appointment: Appointment | ItemQueue,
		callback: Callbacks
	): void {
		const user = this.authService.getUserLocale()
		this.http
			.patch<any>(`${environment.api}/appointments/${appointment.id}`, {
				status: 'in_attendance',
				started_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
				doctor_id: user.id,
			})
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(response: any) => {
					this.getAppointment(appointment.id, true, () => {
						callback.fnSuccess()
						console.log('START APPOINTMENT SERVICE: ', response)
					})
				},
				(err) => {
					console.warn(1, err)
					callback.fnError(err)
				}
			)
	}

	releasePatient(id: string, callback: Callbacks): void {
		const status = 'finished_video'

		this.http
			.patch(`${environment.api}/appointments/${id}`, {
				status,
			})
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					this.setPatientAndAppointment({ status }, true)
					callback.fnSuccess()
					console.log('RELEASE PATIENT APPOINTMENT SERVICE: ', data)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}

	updateReport(id: string, report?: string): void {
		this.http
			.patch<any>(`${environment.api}/appointments/${id}`, {
				report,
			})
			.pipe()
			.subscribe(
				(data) => {
					console.log('UPDATE REPORT APPOINTMENT SERVICE: ', data)
				},
				(err) => {
					console.warn(err)
				}
			)
	}

	setReportLocale(value: string): void {
		localStorage.setItem('report', value)
	}

	getReportLocale(): string {
		return localStorage.getItem('report') || ''
	}

	removeReportLocale(): void {
		localStorage.removeItem('report')
	}

	finalized(appointment: Appointment, body: any, callback: Callbacks): void {
		this.http
			.patch(`${environment.api}/appointments/${appointment.id}`, {
				status: 'finalized',
				ended_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
			})
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
					this.removeReportLocale()
				})
			)
			.subscribe(
				(data) => {
					callback.fnSuccess()
					console.log('FINALIZED APPOINTMENT SERVICE: ', data)
					this.updateReport(appointment.id, body.report)
					this.feedbackFinalized(appointment, body.dataFeedback)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}

	feedbackFinalized(appointment: Appointment, dataFeedback: any) {
		this.http
			.post(`${environment.api}/feedbacks`, {
				appointment_id: appointment.id,
				client_id: appointment.clientId,
				...dataFeedback,
			})
			.subscribe(
				(data) => {
					console.log(
						'FEEDBACK FINALIZED APPOINTMENT SERVICE: ',
						data
					)
				},
				(err) => {
					console.warn(err)
				}
			)
	}

	removeAppointmentTheQueue(id: string, callback: Callbacks): void {
		this.http
			.patch<any>(`${environment.api}/appointments/${id}`, {
				status: 'canceled',
			})
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					console.log('CANCELED APPOINTMENT SERVICE: ', data)
					callback.fnSuccess()
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}

	getTokenVideoCall(hash: string): void {
		this.http
			.get<any>(`${environment.api}/appointments/hash/${hash}/twilio`)
			.pipe()
			.subscribe(
				(data: any) => {
					const { token } = data.data
					console.log(
						'GET APPOINTMENT SERVICE TOKEN VIDEO CALL: ',
						token
					)
					this.setPatientAndAppointment(
						{ tokenVideoCall: token },
						true
					)
				},
				(err) => {
					console.warn(err)
				}
			)
	}
}

import { FeedbackChecks } from '../models/feedback-checks'

export const feedbackChecks: Array<FeedbackChecks> = [
    {
        name: 'medicalLicenseCertificate',
        label: 'atestato_licenca_medica',
        description: 'Atestato/Licença médica',
        value: false,
    },
    {
        name: 'medicationPrescription',
        label: 'prescricao_de_medicacao',
        description: 'Prescrição de medicação',
        value: false,
    },
    {
        name: 'requestForAdditionalExams',
        label: 'solicitacao_de_exames_adicionais',
        description: 'Solicitação de exames adicionais',
        value: false,
    },
    {
        name: 'requestForFaceToFaceConsultation',
        label: 'solicitacao_para_consulta_presencial',
        description: 'Solicitação para consulta presencial',
        value: false,
    },
]

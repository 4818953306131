<div class='wrapper' [class.full]='isFullScreen'>
    <div class='position-relative'>
        <div class='wrapper-video'>
            <div class='secondary' [class.minimize]='minimizeItem'>
                <div [style]='"display:" + (!webcam ? "none" : "block")'>
                    <video #localMediaDoctorContainer [hidden]='minimizeItem'>
                    </video>
                </div>
            </div>
            <div #localMediaPatientContainer class='principal'>
            </div>
        </div>
        <div class='wrapper-config'>
            <button
                type='button'
                class='mute'
                (click)='openModalConfiguration()'
            >
                <img
                    src='../../../assets/images/controls/config.png' />
            </button>
        </div>
        <div class='wrapper-controls'>
            <div class='controls'>
                <button (click)='toggleMute()' type='button' class='mute' [disabled]='loading'>
					<span>{{
                        (!mute ? 'Desativar' : 'Ativar') + ' microfone'
                        }}</span>
                    <img
                        [src]="'../../../assets/images/controls/mic-'+(!mute ? 'on' : 'off')+'.png'" />
                </button>
                <button (click)='toggleWebcam()' type='button' class='video' [disabled]='loading'>
                    <span>{{(webcam ? 'Desativar' : 'Ativar') + ' vídeo'}}</span>
                    <img
                        [src]="'../../../assets/images/controls/video-'+(webcam ? 'on' : 'off')+'.png'" />
                </button>
                <button type='button' class='full'>
					<span>{{
                        (isFullScreen ? 'Minimizar' : 'Ampliar') + ' vídeo'
                        }}</span>
                    <img
                        (click)='toggleFullscreen()'
                        [src]="'../../../assets/images/controls/full-screen-'+(isFullScreen ? 'on' : 'off')+'.png'" />
                </button>
            </div>
        </div>
        <div class='content-config' *ngIf='config'>
            <form>
                <div class='wrapper-select'>
                    <div>
                        <figure>
                            <img
                                src='../../../assets/images/icons/mic.svg'
                                alt='Microfone'
                                title='Microfone'
                            />
                        </figure>
                        <div>
                            <label for='mic'>Microfone</label>
                            <select [(ngModel)]='optionAudioInputSelected' (change)='changeAudioInput()' name='mic'
                                    id='mic'>
                                <option *ngFor='let item of optionsAudioInput' [value]='item.value'>
                                    {{item.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class='wrapper-select'>
                    <div>
                        <figure>
                            <img
                                src='../../../assets/images/icons/volume.svg'
                                alt='Áudio'
                                title='Áudio'
                            />
                        </figure>
                        <div>
                            <label for='audio'>Áudio</label>
                            <select [(ngModel)]='optionAudioOutputSelected' (change)='changeAudioOutput()'
                                    [disabled]='!isParticipant' name='audio' id='audio'>
                                <option *ngFor='let item of optionsAudioOutput' [value]='item.value'>
                                    {{item.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class='wrapper-select'>
                    <div>
                        <figure>
                            <img
                                src='../../../assets/images/icons/camera.svg'
                                alt='Vídeo'
                                title='Vídeo'
                            />
                        </figure>
                        <div>
                            <label for='video'>Vídeo</label>
                            <select [(ngModel)]='optionVideoInputSelected' (change)='changeVideoInput()' name='video'
                                    id='video'>
                                <option *ngFor='let item of optionsVideoInput' [value]='item.value'>
                                    {{item.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

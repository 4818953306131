<ul>
	<li>
		<p>Nível de estresse</p>
		<span>2</span>
	</li>
	<li>
		<p>Volume cardíaco</p>
		<span>0L/min</span>
	</li>
	<li>
		<p>Risco doença cardíaca</p>
		<span>6%</span>
	</li>
	<li>
		<p>Risco ataque cardíaco</p>
		<span>1,5%</span>
	</li>
	<li>
		<p>Risco AVC</p>
		<span>3%</span>
	</li>
	<li>
		<p>Pontuação geral</p>
		<span>79 pts</span>
	</li>
</ul>

import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { User } from '../../models/user'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import * as moment from 'moment'
import { ToastrService } from 'ngx-toastr'
import { UserService } from '../../services/user/user.service'
import { HelpersService } from '../../services/helpers/helpers.service'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { AddUser } from '../../state/user/user.action'
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth/auth.service'
import { StatsService } from '../../services/stats/stats.service'
import { Appointment } from '../../models/appointment'
import { AppointmentsService } from '../../services/appointments/appointments.service'
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper'
import { ModalDefaultComponent } from '../../components/modals/modal-default/modal-default.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { FileDoctorService } from '../../services/file-upload/file-upload.service'
import { ExamsService } from '../../services/exams/exams.service'

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	private user$?: Observable<User>
	user?: User
	form: FormGroup
	genreOptions: any[]
	ufOptions: any[]
	cityOptions: any[] = []
	specialtiesOptions: any[] = []
	appointments: Appointment[] = []
	loading: boolean = false
	_reload: boolean = true

	imageChangedEvent: any = ''
	croppedImage: any = ''
	openModalFile: boolean = false
	transform: ImageTransform = {}
	scale = 1
	appointment?: Appointment

	blob: string = ''

    page: number = 1
    totalPages: number = 1

    titleTabMyAccount: string = 'Minha conta'
    titleTabMyAppointments: string = 'Consultas realizadas'

	constructor(
		private userService: UserService,
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private store: Store<AppState>,
		private toast: ToastrService,
		private helpers: HelpersService,
		private router: Router,
		private statsService: StatsService,
		private appointmentsService: AppointmentsService,
		private modalDefault: NgbModal,
		private fileDoctorService: FileDoctorService,
		private examsService: ExamsService
	) {
		this.user$ = this.store.select('user')
		this.user$.subscribe((state) => {
			this.setUser(state)
		})

		this.form = this.formBuilder.group({
			name: [this.user?.name, [Validators.required]],
			lastName: [this.user?.lastName, [Validators.required]],
			cpf: [
				this.user?.cpf,
				[Validators.required, GenericValidatorService.isValidCpf],
			],
			email: [this.user?.email, [Validators.required, Validators.email]],
			crmUf: [this.user?.crmUf, [Validators.required]],
			cityId: [this.user?.cityId || '', [Validators.required]],
			memedSpecialtyId: [
				this.user?.memedSpecialtyId || '',
				[Validators.required],
			],
			crm: [this.user?.crm, [Validators.required]],
			birthdate: [this.user?.birthdate, [Validators.required]],
			gender: [this.user?.gender, [Validators.required]],
			password: [null],
			confirmPassword: [null, [GenericValidatorService.checkPasswords]],
		})

		this.genreOptions = [
			{
				value: 'M',
				label: 'Masculino',
			},
			{
				value: 'F',
				label: 'Feminino',
			},
		]

		this.ufOptions = [
			{ value: 'AC', label: 'AC' },
			{ value: 'AL', label: 'AL' },
			{ value: 'AP', label: 'AP' },
			{ value: 'AM', label: 'AM' },
			{ value: 'BA', label: 'BA' },
			{ value: 'CE', label: 'CE' },
			{ value: 'DF', label: 'DF' },
			{ value: 'ES', label: 'ES' },
			{ value: 'GO', label: 'GO' },
			{ value: 'MA', label: 'MA' },
			{ value: 'MT', label: 'MT' },
			{ value: 'MS', label: 'MS' },
			{ value: 'MG', label: 'MG' },
			{ value: 'PA', label: 'PA' },
			{ value: 'PB', label: 'PB' },
			{ value: 'PR', label: 'PR' },
			{ value: 'PE', label: 'PE' },
			{ value: 'PI', label: 'PI' },
			{ value: 'RJ', label: 'RJ' },
			{ value: 'RN', label: 'RN' },
			{ value: 'RS', label: 'RS' },
			{ value: 'RO', label: 'RO' },
			{ value: 'RR', label: 'RR' },
			{ value: 'SC', label: 'SC' },
			{ value: 'SP', label: 'SP' },
			{ value: 'SE', label: 'SE' },
			{ value: 'TO', label: 'TO' },
		]
	}

	ngOnInit(): void {
		this.getUser()
		this.getSpecialties()
	}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event
		if (event.target.value) {
			this.openModalFile = true
		}
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64
	}
	onCloseModalFile() {
		this.openModalFile = false
		this.imageChangedEvent = ''
		this.croppedImage = ''
	}
	loadImageFailed() {
		this.openModalFile = false
		this.onCloseModalFile()

		const modalRef = this.modalDefault.open(ModalDefaultComponent)
		modalRef.componentInstance.title = 'Formato de umagem inválido'
		modalRef.componentInstance.desc =
			'Os formatos de imagems aceito são png | jpeg | bmp | webp | ico'
		modalRef.componentInstance.callbackConfirmation = () => {
			console.log('Formato de imagem inválido')
		}
	}
	sendFileImageProfile() {
		const self = this

		self.fileDoctorService.postFileDoctor(self.croppedImage, {
			fnSuccess(response) {
				self.onCloseModalFile()
				self.getUser()
				self.toast.success('Imagem alterada com sucesso')
			},
			fnError(responseError) {
				self.toast.success('Ocorreu um erro' + responseError)
				console.warn(responseError)
			},
		})
	}
	zoomOut() {
		if (this.scale > 1) {
			this.scale -= 0.1
			this.transform = {
				...this.transform,
				scale: this.scale,
			}
		}
	}
	zoomIn() {
		this.scale += 0.1
		this.transform = {
			...this.transform,
			scale: this.scale,
		}
	}

	getUser(): void {
		if (this.user?.id) {
			this.userService.getUser(this.user.id, () => {
				this.getCities()
			})
		}
	}

	setUser(state: User): void {
		this.user = { ...state }
		this.user.birthdate = moment(this.user.birthdate).format('DD/MM/YYYY')
		this.getAvatar()
	}

	getAppointmentsByDoctor(page: number = 1) {
		const self = this
		self.loading = true
		self.appointmentsService.getAppointmentsByDoctor(page, {
			fnSuccess(response?: any) {
				const list = response.items.map((item: any) => {
					item = self.helpers.converterSnakeToCamelCase(item)
					item.createdAt = self.helpers.converterTsInDate(
						item.createdAt
					)
					item.endedAt = self.helpers.converterTsInDate(item.endedAt)
					if (item.patient) {
						item.patient = self.helpers.converterSnakeToCamelCase(
							item.patient
						)
					}
					return item
				})

                self.appointments = [...self.appointments, ...list]
                self.totalPages = response.total_pages
			},
			fnError(error) {
				self.toast.error('Erro ao buscar a lista de consultas')
			},
			fnFinalized() {
				self.loading = false
			},
		})
	}

	getCities(): void {
		const self = this
		let { crmUf } = self.form?.value

		if (!crmUf) {
			crmUf = this.user?.crmUf
		}

		if (crmUf) {
			self.userService.getCities(crmUf, {
				fnSuccess(response?: any) {
					self.cityOptions = response?.data.map((city: any) => ({
						label: city.attributes.nome,
						value: city.id,
					}))
					self.reload()
				},
				fnError(responseError) {
					self.toast.error(
						`Status: ${responseError.status}`,
						'Server Error!'
					)
				},
			})
		}
	}

	getSpecialties(): void {
		const self = this
		self.userService.getSpecialities({
			fnSuccess(response?: any) {
				self.specialtiesOptions = response?.data.map(
					(specialty: any) => ({
						label: specialty.attributes.nome,
						value: specialty.id,
					})
				)

				self.reload()
			},
			fnError(responseError) {
				self.toast.error(
					`Status: ${responseError.status}`,
					'Server Error!'
				)
			},
		})
	}

	private reload() {
		setTimeout(() => (this._reload = false))
		setTimeout(() => (this._reload = true))
	}

	onSubmit(): void {
		const self = this
		self.statsService.stats('update_profile_doctor', 'start')
		if (self.form?.valid && self.user?.id) {
			let payload = this.helpers.removeNullValuesFromObject(
				self.helpers.converterCamelCaseToSnake(self.form?.value)
			)
			payload.birthdate = moment(payload.birthdate, 'DD/MM/YYYY').format(
				'YYYY-MM-DD'
			)

            payload.email = payload.email.toLowerCase()

			self.store.dispatch(ShowLoading())
			self.userService.updateUser(self.user.id, payload, {
				fnSuccess(response?: any) {
					const { data } = response
					self.authService.setMeMedLocale(data.memed_complete)
					self.store.dispatch(
						AddUser({
							...self.helpers.converterSnakeToCamelCase(payload),
						})
					)
					self.toast.success('Usuário atualizado com sucesso!')
					self.authService.redirectAuth()
					self.statsService.stats('update_profile_doctor', 'end')
				},
				fnError(responseError) {
					if (responseError.status === 400) {
						self.toast.error(
							'Verifique todos os campos obrigatórios e tente novamente',
							'Formulário inválido!'
						)
					} else if (responseError.status === 401) {
						self.toast.error('Você não tem permissão', 'Erro!')
					} else {
						self.toast.error(
							`Status: ${responseError.status}`,
							'Server Error!'
						)
					}
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			})
		} else {
			self.toast.error('Formulário inválido!')
		}
	}

	getAvatar() {
		if (this.user?.profilePictureUrl) {
			this.examsService
				.getFile(this.user.profilePictureUrl)
				.then((data) => {
					this.blob = URL.createObjectURL(data)
					console.log('BLOB => ', this.blob)
				})
		}
	}

    onOpenTab(tabOn: string): void {
        if(tabOn === this.titleTabMyAppointments) {
            this.getAppointmentsByDoctor()
        }
    }

    onScroll() {
        if(this.totalPages > this.page) {
            this.page++
            this.getAppointmentsByDoctor(this.page)
        }
    }
}

<div class='container sidebar profile'>
    <div class='row py-4'>
        <div class='col-md-6 align-self-center'>
            <div class='wrapper-profile'>
                <div class='image online'>
                    <app-image
                        *ngIf='blob'
                        [load]='true'
                        [path]='blob'
                        [title]="user?.name + ' ' + user?.lastName"
                    ></app-image>
                    <app-image
                        *ngIf='!blob'
                        path='../../assets/images/error-image.png'
                        [title]="user?.name + ' ' + user?.lastName"
                    ></app-image>
                    <div class='file'>
                        <form enctype='multipart/form-data'>
                            <label for='file'></label>
                            <input
                                id='file'
                                type='file'
                                name='file'
                                (change)='fileChangeEvent($event)'
                            />
                        </form>
                    </div>
                </div>
                <div class='modal-file modal-body' *ngIf='openModalFile'>
                    <div class='close-file' (click)='onCloseModalFile()'></div>
                    <div class='crop-image'>
                        <image-cropper
                            [imageChangedEvent]='imageChangedEvent'
                            [maintainAspectRatio]='true'
                            [aspectRatio]='4 / 4'
                            format='png'
                            (imageCropped)='imageCropped($event)'
                            [transform]='transform'
                            (loadImageFailed)='loadImageFailed()'
                        ></image-cropper>
                        <div class='wrapper-button'>
                            <div class='configs'>
                                <button
                                    type='button'
                                    class='btn'
                                    [class.disabled]='scale === 1'
                                    (click)='zoomOut()'
                                >
                                    Zoom -
                                </button>
                                <button
                                    type='button'
                                    class='btn'
                                    (click)='zoomIn()'
                                >
                                    Zoom +
                                </button>
                            </div>
                            <button
                                (click)='sendFileImageProfile()'
                                type='button'
                                class='btn'
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
                <div class='data'>
                    <h4>{{ user?.name }} {{ user?.lastName }}</h4>
                    <p>
                        <span>Especialidade:</span>
                        <span *ngFor='let el of specialtiesOptions'>
							<span
                                style='font-weight: 300'
                                *ngIf='el.value === user?.memedSpecialtyId'
                            >
								{{ el.label }}
							</span>
						</span>
                    </p>

                    <p>
                        <span>CRM/UF:</span> {{ user?.crm }}/{{ user?.crmUf }}
                    </p>
                    <p>
                        <span>Situação do cadastro MEMED:</span>
                        {{ user?.memedComplete ? 'ATIVO' : 'INATIVO' }}
                        <!--						<span class="verify"> verificar</span>-->
                    </p>
                    <p><span>Contato:</span> {{ user?.email }}</p>
                </div>
            </div>
        </div>
        <div class='col-md-6 py-5 py-md-0 my-4 my-md-0'>
            <app-score [score]='user?.score'></app-score>
        </div>
    </div>
    <app-tabs (tabOpenEvent)='onOpenTab($event)'>
        <app-tab [tabTitle]='titleTabMyAccount'>
            <form
                action='#'
                autocomplete='off'
                (ngSubmit)='onSubmit()'
                [formGroup]='form'
            >
                <div class='row'>
                    <div class='col-12'>
                        <div class='wrapper-title pt-0'>
                            <h5>Dados pessoais:</h5>
                        </div>
                    </div>
                    <div class='col-lg-6'>
                        <app-input
                            id='name'
                            name='name'
                            typeInput='text'
                            placeholder='Digite seu nome'
                            labelName='Nome*'
                            [form]='form'
                            [inputModel]='user?.name'
                        ></app-input>
                    </div>
                    <div class='col-lg-6'>
                        <app-input
                            id='s-nome'
                            name='lastName'
                            typeInput='text'
                            placeholder='Digite seu sobrenome'
                            labelName='Sobrenome*'
                            [form]='form'
                            [inputModel]='user?.lastName'
                        ></app-input>
                    </div>
                    <div class='col-lg-12'>
                        <app-input
                            id='cpf'
                            name='cpf'
                            typeInput='text'
                            placeholder='Digite seu cpf*'
                            labelName='CPF*'
                            [form]='form'
                            [inputModel]='user?.cpf'
                            inputMask='000.000.000-00'
                        ></app-input>
                    </div>
                    <div class='col-lg-12'>
                        <app-input
                            id='email'
                            name='email'
                            typeInput='email'
                            placeholder='Digite seu E-mail'
                            labelName='E-mail*'
                            [form]='form'
                            [inputModel]='user?.email'
                        ></app-input>
                    </div>
                    <div class='col-lg-6'>
                        <app-select
                            id='crmUf'
                            name='crmUf'
                            [options]='ufOptions'
                            labelName='UF do CRM*'
                            placeholder='Selecione uma opção'
                            [form]='form'
                            [inputModel]='user?.crmUf'
                            (emmitEvent)='getCities()'
                        ></app-select>
                    </div>
                    <div class='col-lg-6'>
                        <app-input
                            id='crm'
                            name='crm'
                            typeInput='tel'
                            placeholder='Apenas números'
                            labelName='CRM (preencher corretamente)*'
                            [form]='form'
                            [inputModel]='user?.crm'
                        ></app-input>
                    </div>
                    <div class='col-lg-12'>
                        <app-select
                            *ngIf='_reload'
                            id='cityId'
                            name='cityId'
                            [options]='cityOptions'
                            labelName='Selecione sua cidade*'
                            placeholder='Selecione uma opção'
                            [form]='form'
                            [inputModel]='user?.cityId'
                        ></app-select>
                    </div>

                    <div class='col-lg-6'>
                        <app-select
                            *ngIf='_reload'
                            id='gender'
                            name='gender'
                            [options]='genreOptions'
                            labelName='Gênero*'
                            placeholder='M ou F'
                            [form]='form'
                            [inputModel]='user?.gender'
                        ></app-select>
                    </div>
                    <div class='col-lg-6'>
                        <app-input
                            id='date'
                            name='birthdate'
                            typeInput='tel'
                            placeholder='EX.: 20/07/1985'
                            labelName='Data de nascimento*'
                            [form]='form'
                            [inputModel]='user?.birthdate'
                            inputMask='00/00/0000'
                        ></app-input>
                    </div>
                    <div class='col-lg-12'>
                        <app-select
                            *ngIf='_reload'
                            id='memedSpecialtyId'
                            name='memedSpecialtyId'
                            [options]='specialtiesOptions'
                            labelName='Especialidade*'
                            placeholder='Selecione uma especialidade'
                            [form]='form'
                            [inputModel]='user?.memedSpecialtyId'
                        ></app-select>
                    </div>
                    <div class='col-12'>
                        <p class='mb-4 mb-lg-0'>*Campos obrigatórios.</p>
                        <div class='wrapper-title pt-0'>
                            <h5>Segurança:</h5>
                        </div>
                    </div>
                    <div class='col-lg-6'>
                        <app-input
                            id='password'
                            name='password'
                            typeInput='password'
                            placeholder='******'
                            labelName='Senha (opcional)'
                            [form]='form'
                        ></app-input>
                    </div>
                    <div class='col-lg-6'>
                        <app-input
                            id='confirmPassword'
                            name='confirmPassword'
                            typeInput='password'
                            placeholder='******'
                            labelName='Confirmação de senha (opcional)'
                            [form]='form'
                        ></app-input>
                    </div>
                </div>
                <div class='wrapper-button py-5'>
                    <button
                        type='submit'
                        class='btn btn-medium mx-auto'
                        [disabled]='form?.invalid'
                    >
                        Salvar dados pessoais
                    </button>
                </div>
            </form>
        </app-tab>
        <app-tab [tabTitle]='titleTabMyAppointments'>
            <div
                class='col-md-6 mt-5 p-0'
                [class.after]='appointments.length > 2'
            >
                <div class='wrapper custom-scroll'
                     infiniteScroll
                     [infiniteScrollDistance]='2'
                     [infiniteScrollThrottle]='50'
                     (scrolled)='onScroll()'
                     [scrollWindow]='false'
                >
                    <div
                        class='wrapper-company'
                        *ngFor='let item of appointments'
                    >
                        <div class='company'>
                            <div class='image'>
                                <app-image
                                    [load]='true'
                                    [path]='item.client?.logo'
                                    [title]='item.client?.name'
                                ></app-image>
                            </div>
                            <div>
                                <h4>{{ item.client?.name }}</h4>
                                <p>
                                    <strong>Paciente:</strong>
                                    {{ item.patient?.name }}
                                    {{ item.patient?.lastName }}
                                </p>
                            </div>
                        </div>
                        <p class='date'>
                            {{ item.createdAt }} - {{ item.endedAt }}
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf='!loading && !appointments.length' class='empty'>
                <app-image
                    titleBox='Você ainda não realizou nenhum atendimento.'
                    [path]="'/assets/images/empty.svg'"
                ></app-image>
                <h3>Você ainda não realizou nenhum atendimento.</h3>
            </div>
        </app-tab>
    </app-tabs>
</div>

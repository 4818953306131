<div>
	<div class="switch" [formGroup]="form">
		<label
			[for]="'c-' + id || name"
			[class.disabled]="disabled"
		>
			{{ labelName }}
		</label>
		<input
			type="checkbox"
			[id]="'c-' + id || name"
			[attr.name]="name"
			[attr.disabled]="disabled ? true : null"
			[formControlName]="name"
			[setValue]="inputModel"
			[checked]="inputModel"
			(change)="fnChangeModel($event)"
		/>
		<label [for]="'c-' + id || name"></label>
	</div>
	<span
		*ngIf="
			form.controls[name || '']?.invalid &&
			(form.controls[name || '']?.touched ||
				form.controls[name || '']?.dirty)
		"
		class="control-error"
		[setFormControlError]="{
			objError: form.controls[name || '']?.errors,
			control: labelName
		}"
	>
	</span>
</div>

import { Action } from '@ngrx/store'
import { ItemQueue } from '../../models/queue'

export enum ActionExamsListTypes {
	DoRequest = '@EXAMS_LIST/DO_REQUEST',
	RequestSuccess = '@EXAMS_LIST/REQUEST_SUCCESS',
    RequestFailure = '@EXAMS_LIST/REQUEST_FAILURE',
    RequestClear = '@EXAMS_LIST/REQUEST_CLEAR',
}

export const DoRequestExamsList = () => {
	return <Action>{ type: ActionExamsListTypes.DoRequest }
}

export const RequestSuccessExamsList = (queue: ItemQueue[]) => {
    return <Action>{ type: ActionExamsListTypes.RequestSuccess, payload: queue }
}

export const RequestFailureExamsList = () => {
    return <Action>{ type: ActionExamsListTypes.RequestFailure }
}

export const ClearExamsList = () => {
    return <Action>{ type: ActionExamsListTypes.RequestClear }
}

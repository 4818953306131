import { Action } from '@ngrx/store'

export enum ActionMeMedTypes {
	DoRequest = '@MEMED/DO_REQUEST',
	RequestSuccess = '@MEMED/REQUEST_SUCCESS',
	RequestFailure = '@MEMED/REQUEST_FAILURE',
}

export const DoRequestMeMed = () => {
	return <Action>{ type: ActionMeMedTypes.DoRequest }
}

export const RequestSuccessMeMed = (active: boolean) => {
	return <Action>{ type: ActionMeMedTypes.RequestSuccess, payload: active }
}

export const RequestFailureMeMed = () => {
	return <Action>{ type: ActionMeMedTypes.RequestFailure }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biometry',
  templateUrl: './biometry.component.html',
  styleUrls: ['./biometry.component.scss']
})
export class BiometryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input } from '@angular/core'

@Component({
	selector: 'app-button-tooltip',
	templateUrl: './button-tooltip.component.html',
	styleUrls: ['./button-tooltip.component.scss'],
})
export class ButtonTooltipComponent implements OnInit {
	@Input() type?: string | any[] = 'not-urgent'
	@Input() title?: string
	@Input() description?: string
	@Input() pointer: boolean = false

	public x: number = 0
	public y: number = 0
	private margin: number = 10

	constructor() {}

	ngOnInit(): void {}
	onMouseMove(e: any) {
		this.x = e.offsetX + this.margin
		this.y = e.offsetY + this.margin
	}
}

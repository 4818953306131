<div
	class="card-content"
	[class.highlighted]="highlighted"
	[class.active]="active"
>
	<div *ngIf="active" class="selected">
		<p>Imagem ampliada</p>
	</div>
	<div class="card-image">
        <app-image *ngIf='loading' path="/assets/images/placeholder.gif" title="Carregando"></app-image>
		<app-image *ngIf='!loading' [path]="blob" [title]="title"></app-image>
		<div class="wrapper-button">
			<!--			<button type="button">-->
			<!--				<img-->
			<!--					src="../../../../assets/images/icons/delete.png"-->
			<!--					alt="Deletar"-->
			<!--					title="Deletar"-->
			<!--				/>-->
			<!--			</button>-->
			<button
				type="button"
				(click)="fnExpandImage()"
				*ngIf="isExpandImage"
			>
				<img
					[src]="
						highlighted
							? '../../../../assets/images/icons/delete.png'
							: '../../../../assets/images/icons/expand.png'
					"
					[alt]="highlighted ? 'Fechar' : 'Expandir'"
					[title]="highlighted ? 'Fechar' : 'Expandir'"
				/>
			</button>
		</div>
	</div>
	<div class="card-body">
		<!--		<h5>{{ title }}</h5>-->
		<p>{{ description }}</p>
	</div>
</div>

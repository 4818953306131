import { ActionPatientTypes } from './patient.action'
import { ActionModel } from '../action'
import { Patient } from '../../models/patient'

const initialState: Patient = {
	id: '',
	name: '',
    lastName: '',
    documentCpf: '',
    gender: ''
}

export function patientReducer(
	state: Patient = initialState,
	action: ActionModel
) {
	switch (action.type) {
		case ActionPatientTypes.Add: {
			state = { ...action.payload }
			return state
		}

		case ActionPatientTypes.Clear: {
			state = initialState
			return state
		}

		default:
			return state
	}
}

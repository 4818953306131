import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { AuthService } from '../auth/auth.service'

@Injectable({
	providedIn: 'root',
})
export class FileDoctorService {
	constructor(private http: HttpClient, private authService: AuthService) {}

	postFileDoctor(fileToUpload: File, callback: Callbacks): void {
		const user = this.authService.getUserLocale()
		const token = this.authService.getToken()
		const formData: FormData = new FormData()

		const file = this.DataURIToBlob(fileToUpload)
		formData.append('profile_picture', file, 'image.jpg')

		this.http
			.patch<any>(
				`${environment.api}/users/${user.id}/profile_picture`,
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callback.fnSuccess(data)
				},
				(err) => {
					callback.fnError(err)
				}
			)
	}

	DataURIToBlob(dataURI: any) {
		const splitDataURI = dataURI.split(',')
		const byteString =
			splitDataURI[0].indexOf('base64') >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1])
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

		const ia = new Uint8Array(byteString.length)
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i)

		return new Blob([ia], { type: mimeString })
	}
}

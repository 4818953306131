<div class="modal-body">
	<div class="wrapper-text">
		<h5 *ngIf="title">{{ title }}</h5>
		<p *ngIf="desc">{{ desc }}</p>
	</div>
	<div class="wrapper-section">
		<div>
			<app-image
				path="../../../../assets/images/icons/section-valid.png"
				[title]="title"
			></app-image>
		</div>
		<div>
			<p *ngIf="sectionData.date">Data: {{ sectionData.date }}</p>
			<p *ngIf="sectionData.browser">
				Browser: {{ sectionData.browser }}
			</p>
			<p *ngIf="sectionData.machine">
				Máquina: {{ sectionData.machine }}
			</p>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button
		type="button"
		class="btn outline"
		*ngIf="btnCancel"
		(click)="close()"
	>
		Cancelar
	</button>
	<button type="button" class="btn btn-xs" (click)="confirmation()">
		{{ titleBtn }}
	</button>
</div>

import { ActionUserTypes } from './user.action'
import { ActionModel } from '../action'
import { User } from '../../models/user'

const initialState: User = {
    id: '',
    name: '',
    lastName: '',
    email: '',
    birthdate: '',
    memedComplete: false,
    roles: []
}

export function userReducer(state: User = initialState, action: ActionModel) {
    switch (action.type) {
        case ActionUserTypes.Add: {
            state = { ...state, ...action.payload }
            return state
        }

        case ActionUserTypes.Clear: {
            state = initialState
            return state
        }

        default:
            return state
    }
}

<div class='wrapper-complaint'>
    <app-audio [src]='link' (emmitEvent)='getTranscription()'></app-audio>
    <div *ngIf='loading || description' class='wrapper-title pt-3 pb-2'>
        <h6>Transcrição:</h6>
    </div>
    <p *ngIf='loading || description'>
        <span *ngIf='loading'>{{loading ? 'Carregando transcrição de audio...' : ''}}</span>
        <span *ngIf='!loading && description'>{{description}}</span>
        <span *ngIf='!loading && !description'>Não foi possível converter o áudio para texto</span>
    </p>
</div>

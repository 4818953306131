<div class="score">
	<img draggable="false" src="/assets/images/score.svg" alt="score" />
	<div class="arrow">
		<div class="score-boll">
			<span
				[style]="'transform: rotate(' + scoreInit(score) + 'deg)'"
			></span>
		</div>
		<span class="position">{{ score }}</span>
	</div>
	<div class="counter">
		<span>0</span>
		<span>100</span>
	</div>
</div>
<div class="wrapper-text">
	<h5>Seu score é de {{ score }}</h5>
	<p *ngIf="hasDescription">
		Para melhorar o seu engajamento na plataforma, você precisa interagir
		mais respondendo enquetes, formulários e feedbacks.
	</p>
</div>

import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Appointment } from '../../models/appointment'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { Router, Event, NavigationEnd, Route } from '@angular/router'
import { AppointmentsService } from '../../services/appointments/appointments.service'
import { HelpersService } from '../../services/helpers/helpers.service'
import { Patient } from '../../models/patient'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	private startedAt: Date | string = ''
	private interval: any
	appointment$: Observable<Appointment>
	patient$: Observable<Patient>
	showMenu: boolean = false
	showAlertAppointmentStarted: boolean = false
	showLinkBackAppointment: boolean = false
	duration: Date | string = ''
	titlePage: string = ''

	constructor(
		private store: Store<AppState>,
		private router: Router,
		private appointmentService: AppointmentsService,
		private helpers: HelpersService
	) {
		this.appointmentService.getStoragePatientAndAppointment()
		this.appointment$ = this.store.select('appointment')
		this.patient$ = this.store.select('patient')
		this.showInfoAlertAppointment()
	}

	ngOnInit(): void {
		window.addEventListener('resize', () => {
			this.closeMenu()
		})
	}

	ngOnDestroy(): void {
		clearInterval(this.interval)
	}

	private showInfoAlertAppointment(): void {
		this.appointment$.subscribe((state) => {
			if (
				this.appointmentService.hasStoragePatientAndAppointment() &&
				state.startedAt
			) {
				this.startedAt = state.startedAt
				this.setDuration()
				this.showAlertAppointmentStarted = true
				this.setShowLinkBackAppointment(this.router.url)
			}
		})

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.getTitlePage()

				this.showAlertAppointmentStarted =
					this.appointmentService.hasStoragePatientAndAppointment()
				this.setShowLinkBackAppointment(this.router.url)
				this.closeMenu()
			}
		})
	}

	toggleMenu(): void {
		this.showMenu = !this.showMenu
	}

	closeMenu(): void {
		this.showMenu = false
	}

	private setShowLinkBackAppointment(url: string): void {
		this.showLinkBackAppointment = url !== '/painel/consulta'
	}

	private setDuration(): void {
		this.duration = this.helpers.getDuration(this.startedAt)

		this.interval = setInterval(() => {
			this.duration = this.helpers.getDuration(this.startedAt)
		}, 5000)
	}

    private getTitlePage() {
        const {config} = this.router
        let currentRoute: Route | undefined

        config.forEach(
            (item) => {
                if (item.children?.length) {
                    item.children.filter(subItem => {
                        if (`${item.path}/${subItem.path}` === this.router.url.replace('/', '')) {
                            currentRoute = subItem
                        }
                    })
                }
                if (item.path === this.router.url.replace('/', '')) {
                    currentRoute = item
                }
            }
        )
        if (currentRoute?.data) {
            this.titlePage = currentRoute?.data.title
        }
    }

}

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-body-data',
	templateUrl: './body-data.component.html',
	styleUrls: ['./body-data.component.scss'],
})
export class BodyDataComponent implements OnInit {
	@Input() weight: string = ''
	@Input() height: string = ''
	@Input() visceralFat: string = ''
	@Input() fatPercentage: string = ''
	@Input() musclePercentage: string = ''
	@Input() imc: string = ''
	typeMessage = {
		type: '',
		status: '',
	}

	constructor() {}

	ngOnInit(): void {
		this.verify()
	}

	parseToDecimal(number: string | undefined, fraction: number = 2) {
		if(!number)
			return '00.00'
		return parseFloat(number).toFixed(fraction)
	}

	verify() {
		const imc = parseFloat(this.imc)
		if (imc < 18.5) {
			this.typeMessage.type = 'underweight'
			this.typeMessage.status = 'Abaixo do peso'
		} else if (imc > 18.5 && imc < 24.9) {
			this.typeMessage.type = 'normal'
			this.typeMessage.status = 'Peso ideal'
		} else if (imc > 25 && imc < 29.9) {
			this.typeMessage.type = 'overweight'
			this.typeMessage.status = 'Obesidade Grau I'
		} else if (imc > 30 && imc < 34.9) {
			this.typeMessage.type = 'obese'
			this.typeMessage.status = 'Obesidade Grau II'
		} else {
			this.typeMessage.type = 'extreme-obese'
			this.typeMessage.status = 'Obesidade Grau III'
		}
	}
}

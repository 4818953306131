import { Injectable } from '@angular/core'
import { HelpersService } from '../helpers/helpers.service'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { ToastrService } from 'ngx-toastr'
import { User } from '../../models/user'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { Patient } from '../../models/patient'
import { Appointment } from '../../models/appointment'
import {
    DoRequestMeMed,
    RequestFailureMeMed,
    RequestSuccessMeMed
} from '../../state/me-med/me-med.action'
import { AuthService } from '../auth/auth.service'

@Injectable({
    providedIn: 'root'
})
export class MeMedService {
    private user$?: Observable<User>
    user?: User
    private patient$?: Observable<Patient>
    patient?: Patient
    private appointment$?: Observable<Appointment>
    appointment?: Appointment

    constructor(
        private http: HttpClient,
        private helpers: HelpersService,
        private toast: ToastrService,
        private store: Store<AppState>,
        private authService: AuthService
    ) {
        this.user$ = this.store.select('user')
        this.user$.subscribe((state) => {
            this.user = state
        })

        this.patient$ = this.store.select('patient')
        this.patient$.subscribe((state) => {
            this.patient = state
        })

        this.appointment$ = this.store.select('appointment')
        this.appointment$.subscribe((state) => {
            this.appointment = state
        })
    }

    initMeMed(): void {
        const self = this
        const meMedComplete = self.authService.getMeMedLocale()

        if (meMedComplete) {
            self.store.dispatch(DoRequestMeMed())
            self.getMeMedToken({
                fnSuccess(response?: any) {
                    let { data } = response
                    data = self.helpers.converterSnakeToCamelCase(data)
                    self.store.dispatch(RequestSuccessMeMed(true))
                    const token = data.memedToken
                    self.insertScript(token)
                },
                fnError(errorResponse) {
                    self.store.dispatch(RequestFailureMeMed())
                    self.toast.error(
                        'Problema ao carregar módulo externo Memed, atualize seu cadastro e tente novamente!'
                    )
                }
            })
        } else {
            self.toast.warning(
                'Seu cadastro não está completo para utilizar o módulo externo da Memed, atualize seu cadastro e tente novamente!'
            )
        }
    }

    getMeMedToken(callback: Callbacks): void {
        this.http
            .get<any>(`${environment.api}/doctor/memed-token`)
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    console.log('GET APPOINTMENT SERVICE TOKEN MEMED: ', data)
                    callback.fnSuccess(data)
                },
                (err) => {
                    console.warn(err)
                    callback.fnError(err)
                }
            )
    }

    insertScript(token: string): void {
        const self = this
        try {
            const script = document.createElement('script')
            script.setAttribute('type', 'text/javascript')
            script.setAttribute('data-color', '#3257A8')
            script.setAttribute('data-token', token)
            script.onload = function() {
                self.initMeMedEvents(token)
            }
            script.src = `${environment.memedUrl}/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js`
            document.body.appendChild(script)
        } catch (error) {
            self.store.dispatch(RequestFailureMeMed())
            this.toast.error('Não foi possivel iniciar o módulo Memed')
        }
    }

    initMeMedEvents(token: string) {
        const self = this
        // @ts-ignore
        window.MdSinapsePrescricao.event.add(
            'core:moduleInit',
            function moduleInitHandler(module: { name: string }) {
                // O módulo da prescrição foi iniciado,
                if (module.name === 'plataforma.prescricao') {
                    self.store.dispatch(RequestSuccessMeMed(true))

                    // @ts-ignore
                    window.MdHub.event.add(
                        'prescricaoSalva',
                        async function prescricaoSalvaCallback(
                            prescriptionId: number | string
                        ) {
                            await self.savePrescriptionId(prescriptionId, token)
                        }
                    )
                    // @ts-ignore
                    window.MdHub.event.add(
                        'prescricaoExcluida',
                        async function prescricaoExcluidaCallback(
                            prescriptionId: number | string
                        ) {
                            await self.deletePrescriptionId(prescriptionId)
                        }
                    )
                }
            }
        )
        // @ts-ignore
        window.MdSinapsePrescricao.event.add(
            'core:moduleHide',
            function moduleClosed(module: { moduleName: string }) {
                if (
                    module.moduleName === 'plataforma.prescricao' &&
                    //@ts-ignore
                    window.MdSinapsePrescricao.finalizeReport
                ) {
                    // setFinalized(true);
                }
            }
        )
    }

    async showMeMed() {
        // @ts-ignore
        await window.MdHub.command.send(
            'plataforma.prescricao',
            'setPaciente',
            {
                nome: `${this.patient?.name} ${this.patient?.lastName}`,
                telefone: this.patient?.contactPhone?.replace(/\D+/g, ''),
                endereco: `${this.patient?.addresses?.street} - ${this.patient?.addresses?.number}`,
                cidade: this.patient?.addresses?.city,
                idExterno: this.patient?.id
            }
        )
        // @ts-ignore
        await window.MdHub.command.send(
            'plataforma.prescricao',
            'setFeatureToggle',
            {
                removePatient: false
            }
        )
        // @ts-ignore
        await window.MdHub.module.show('plataforma.prescricao')
    }

    async medicalRest(data: { days: number | string; cid: number | string }) {
        if (data.days && data.cid) {
            await this.showMeMed()
            // @ts-ignore
            await window.MdHub.command.send(
                'plataforma.prescricao',
                'addItem',
                {
                    nome: 'Atestado Médico',
                    posologia: `Atesto para os devidos fins que o paciente acima deverá permanecer afastado de suas atividades por ${data.days} dias.

        <br/><br/>CID: ${data.cid}

        <br/><br/>À disposição`
                }
            )
        }
    }

    async savePrescriptionId(prescriptionId: number | string, token: string) {
        this.http
            .post(`${environment.api}/prescriptions`, {
                prescription_id: prescriptionId,
                appointment_id: this.appointment?.id,
                token
            })
            .subscribe((data) => {
                console.log('SAVE PRESCRIPTION MEMED SERVICE', data)
            })
    }

    async deletePrescriptionId(prescriptionId: number | string) {
        this.http
            .delete(`${environment.api}/prescriptions/${prescriptionId}`)
            .subscribe((data) => {
                console.log('DELETE PRESCRIPTION MEMED SERVICE', data)
            })
    }
}

import { Action } from '@ngrx/store'
import { Appointment } from '../../models/appointment'

export enum ActionAppointmentTypes {
	Add = '@APPOINTMENT/ADD',
	Clear = '@APPOINTMENT/CLEAR',
}

export const AddAppointment = (patient: Appointment) => {
	return <Action>{ type: ActionAppointmentTypes.Add, payload: patient }
}

export const ClearAppointment = () => {
	return <Action>{ type: ActionAppointmentTypes.Clear, payload: null }
}

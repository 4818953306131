import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router'

@Component({
	selector: 'app-modal-feedback',
	templateUrl: './modal-feedback.component.html',
	styleUrls: ['./modal-feedback.component.scss'],
})
export class ModalFeedbackComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() title?: string
	@Input() desc?: string
	@Input() img?: string = ''

	constructor(public activeModal: NgbActiveModal, private router: Router) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	confirmation() {
		// if (this.callbackConfirmation) this.callbackConfirmation()
		this.router.navigate(['/'])

		this.close()
	}
}

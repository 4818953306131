<div class="page container sidebar">
	<app-loading-progress *ngIf="queue?.loading"></app-loading-progress>
	<app-tabs (tabOpenEvent)="onOpenTab($event)">
		<div class="wrapper-button">
			<div class="wrapper-switch">
				<p>Saúde ocupacional</p>
				<label class="switch">
					<input
						type="checkbox"
						(change)="toggleIsOccupationalHealth()"
					/>
					<span class="slider round"></span>
				</label>
			</div>
			<button
				class="btn btn-update"
				[class.update]="queue?.loading"
				(click)="getList()"
			>
				<img
					src="/assets/images/icons/reload.svg"
					alt="Atualizar"
				/>Atualizar lista
			</button>
		</div>
		<app-tab [tabTitle]="titleTabAppointment">
			<div *ngIf="queue?.data?.length">
				<app-table-list
					[listHeader]="listHeaderPatientWaitingAppointment"
					[listBody]="queue?.data"
					[page]="currentPage"
					[total]="queue?.total"
					(emmitChangePagination)="getList($event)"
				>
					<ng-template let-item #body>
						<td width="20" class="center">
							<span
								class="status"
								[class.online]="
									consumers.indexOf(item.originId) >= 0
								"
							></span>
						</td>
						<td width="130" class="level">
							<span
								[style.background]="
									'#' +
									(item.emergencyInfo?.color || 'ebebeb')
								"
								>{{ item.emergencyInfo?.label || '--' }}</span
							>
						</td>
						<td width="100" class="center">
							<span class="timer">{{
								helpers.getDuration(item.createdAt)
							}}</span>
						</td>
						<td class="specialty">
							<span>
								{{ item.specialty?.specialty || '--' }}
							</span>
						</td>
						<td class="tags text-center">
							<img
								*ngIf="item.tags && item.tags.length"
								src="/assets/images/status-success.png"
								alt="Saúde ocupacional"
							/>
						</td>
						<td width="300">
							{{ item.patient.name }} {{ item.patient.lastName }}
						</td>
						<td>{{ item.client.name }}</td>
						<td width="200">{{ item.originName }}</td>
						<td>
							{{
								getStatusAppointment(
									consumers.indexOf(item.originId) !== -1,
									item.screeningCompleted
								)
							}}
						</td>
						<td width="180" class="center">
							<button
								type="button"
								class="btn btn-small"
								[disabled]="
									consumers.indexOf(item.originId) === -1 ||
									!item.screeningCompleted
								"
								(click)="startAppointment(item)"
							>
								Iniciar consulta
							</button>
						</td>
						<td width="180" class="center">
							<div>
								<button
									type="button"
									class="btn btn-small outline"
									(click)="
										openLayerExams(item, queue?.data, true)
									"
								>
									Exames realizados
								</button>
							</div>
						</td>
						<td width="20" class="center">
							<button
								*ngIf="item.screeningCompleted"
								type="button"
								(click)="openModalCancelAppointment(item)"
							>
								<figure>
									<img
										src="../../../assets/images/Delete.svg"
										alt="Remover paciente da Lista de espera"
										title="Remover paciente da Lista de espera"
									/>
								</figure>
							</button>
						</td>
					</ng-template>
				</app-table-list>
			</div>
			<div *ngIf="!queue?.loading && !queue?.data?.length" class="empty">
				<app-image
					titleBox="Não há pacientes aguardando atendimento no momento."
					[path]="'/assets/images/empty.svg'"
				></app-image>
				<h3>Não há pacientes aguardando atendimento no momento.</h3>
			</div>
		</app-tab>
		<app-tab [tabTitle]="titleTabExams">
			<div *ngIf="queue?.data?.length">
				<app-table-list
					[listHeader]="listHeaderPatientExams"
					[listBody]="queue?.data"
					[page]="currentPage"
					[total]="queue?.total"
					(emmitChangePagination)="getList($event)"
				>
					<ng-template let-item #body>
						<td width="150" class="center">
							<span class="timer">{{
								helpers.getDate(item.createdAt, true)
							}}</span>
						</td>
						<td class="tags text-center">
							<img
								*ngIf="item.tags && item.tags.length"
								src="/assets/images/status-success.png"
								alt="Saúde ocupacional"
							/>
						</td>
						<td width="300">
							{{ item.patient.name }} {{ item.patient.lastName }}
						</td>
						<td width="200">{{ item.client.name }}</td>
						<td width="200">{{ item.originName }}</td>
						<td width="180" class="center">
							<div>
								<button
									type="button"
									class="btn btn-small outline"
									(click)="
										openLayerExams(item, queue?.data, false)
									"
								>
									Exames realizados
								</button>
							</div>
						</td>
					</ng-template>
				</app-table-list>
			</div>
			<div *ngIf="!queue?.loading && !queue?.data?.length" class="empty">
				<app-image
					titleBox="Não há pacientes na lista."
					[path]="'/assets/images/empty.svg'"
					title="Não há pacientes na lista."
				></app-image>
				<h3>Não há pacientes na lista.</h3>
			</div>
		</app-tab>
		<app-tab [tabTitle]="titleTabAppointmentsFinalized">
			<div *ngIf="queue?.data?.length">
				<app-table-list
					[listHeader]="listHeaderAppointmentsFinalized"
					[listBody]="queue?.data"
					[page]="currentPage"
					[total]="queue?.total"
					(emmitChangePagination)="getList($event)"
				>
					<ng-template let-item #body>
						<td width="150" class="center">
							<span class="timer">{{
								helpers.getDate(item.endedAt, true)
							}}</span>
						</td>
						<td class="specialty">
							<span>
								{{ item.specialty?.specialty || '--' }}
							</span>
						</td>
						<td class="tags text-center">
							<img
								*ngIf="item.tags && item.tags.length"
								src="/assets/images/status-success.png"
								alt="Saúde ocupacional"
							/>
						</td>
						<td width="300">
							{{ item.patient.name }} {{ item.patient.lastName }}
						</td>
						<td>{{ item.client.name }}</td>
						<td width="200">{{ item.originName }}</td>
						<td width="180" class="center">
							<div>
								<button
									type="button"
									class="btn btn-small outline"
									(click)="
										openLayerExams(item, queue?.data, false)
									"
								>
									Exames realizados
								</button>
							</div>
						</td>
					</ng-template>
				</app-table-list>
			</div>
			<div *ngIf="!queue?.loading && !queue?.data?.length" class="empty">
				<app-image
					titleBox="Você não finalizou nenhuma consulta."
					[path]="'/assets/images/empty.svg'"
				></app-image>
				<h3>Você não finalizou nenhuma consulta.</h3>
			</div>
		</app-tab>
	</app-tabs>

	<app-workbench-layer [open]="showLayer" (emmitEvent)="closeLayer()">
		<div
			workbenchHeader
			[class]="
				'd-flex justify-content-between align-items-center w-100 ' +
				(!hasButtonStartAppointment ? 'py-1' : '')
			"
		>
			<p>{{ patient?.name }}</p>
			<button
				*ngIf="hasButtonStartAppointment"
				type="button"
				class="btn btn-small"
				title="Iniciar Consulta"
				[disabled]="
					consumers.indexOf(currentOriginId) === -1 ||
					!currentScreeningCompleted
				"
				(click)="startAppointment(currentAppointment)"
			>
				Iniciar Consulta
			</button>
		</div>
		<div workbenchContent>
			<app-exams
				*ngIf="showLayer && _reload"
				[patientId]="currentPatientId"
			></app-exams>
		</div>
	</app-workbench-layer>
</div>

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-onboard',
	templateUrl: './onboard.component.html',
	styleUrls: ['./onboard.component.scss'],
})
export class OnboardComponent implements OnInit {
	@Input() public title?: string
	@Input() public subTitle?: string
	@Input() public description?: string
	@Input() public src?: string
	@Input() public direction?: string = 'normal'

	constructor() {}

	ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth/auth.service'
import { Event, NavigationEnd, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { MeMedService } from '../../services/me-med/me-med.service'
import { ModalMedicalCertificateComponent } from '../modals/modal-medical-certificate/modal-medical-certificate.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { MeMed } from '../../models/me-med'
import { StatsService } from '../../services/stats/stats.service'

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	private meMed$?: Observable<MeMed>
	meMed?: MeMed

	showMeMed?: boolean
	disabledMeMed: boolean = true

	constructor(
		private authService: AuthService,
		private router: Router,
		private store: Store<AppState>,
		private modalMedicalRest: NgbModal,
		private meMedService: MeMedService,
		private statsService: StatsService
	) {
		this.meMed$ = this.store.select('meMed')
		this.meMed$.subscribe((state) => {
			this.meMed = state
			if (this.meMed) {
				this.disabledMeMed =
					this.meMed.loading || this.meMed.error || !this.meMed.active
			}
		})

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.showOptionsMeMed()
			}
		})
	}

	ngOnInit(): void {}

	showOptionsMeMed(): void {
		this.showMeMed = this.router.url === '/painel/consulta'
	}

	async openModuleMeMed() {
		if (!this.disabledMeMed) {
			this.statsService.stats('open_module_memed', 'point')
			await this.meMedService.showMeMed()
		}
	}

	async showMedicalRest(payload: { days: string; cid: string }) {
		await this.meMedService.medicalRest(payload)
	}

	openModalMedicalRest(): void {
		if (!this.disabledMeMed) {
			this.statsService.stats('open_module_rest_memed', 'point')
			const modalRef = this.modalMedicalRest.open(
				ModalMedicalCertificateComponent
			)
			modalRef.componentInstance.title = 'Atestado médico'
			//@ts-ignore
			modalRef.componentInstance.emmitEvent.subscribe(async (payload) => {
				await this.showMedicalRest(payload)
			})
		}
	}

	logout() {
		this.authService.logout().subscribe(
			() => {
				this.authService.logOutSectionLocal().subscribe(()=> {
                    // @ts-ignore
                    window.location = '/login'
                })
			},
			(err) => {
				console.warn(err)
			}
		)
	}
}

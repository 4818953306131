import { ActionAppointmentTypes } from './appointment.action'
import { ActionModel } from '../action'
import { Appointment } from '../../models/appointment'

const initialState: Appointment = {
    id: '',
    hash: '',
    originId: '',
    clientId: '',
    attendantId: '',
    status: '',
    type: 'appointment',
    tokenVideoCall: '',
    note: '',
    report: '',
    screeningCompleted: false,
    completedSteps: ''
}

export function appointmentReducer(
    state: Appointment = initialState,
    action: ActionModel
) {
    switch (action.type) {
        case ActionAppointmentTypes.Add: {
            state = {
                ...state,
                ...action.payload
            }
            return state
        }

        case ActionAppointmentTypes.Clear: {
            state = initialState
            return state
        }

        default:
            return state
    }
}

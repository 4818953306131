import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

@Component({
	selector: 'app-modal-finalized-appointment',
	templateUrl: './modal-finalized-appointment.component.html',
	styleUrls: ['./modal-finalized-appointment.component.scss'],
})
export class ModalFinalizedAppointmentComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() title?: string
	@Input() desc?: string
    showError: boolean = false
	form: FormGroup

	public checks: Array<any> = [
		{ description: 'Prescrevi atestado', value: 'Prescrevi atestado' },
		{ description: 'Prescrevi algum medicamento', value: 'Prescrevi algum medicamento' },
		{ description: 'Nenhuma prescrição', value: 'Nenhuma prescrição' },
		{ description: 'Não responder', value: 'Prefiro não responder' },
	]

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder
	) {
		this.form = this.formBuilder.group({
			prescriptionOptions: new FormArray([]),
		})
	}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	onCheckChange(event: any) {
		const formArray: FormArray = this.form.get(
			'prescriptionOptions'
		) as FormArray

		if (event.target.checked) {
			formArray.push(new FormControl(event.target.value))
		} else {
			let i: number = 0

			// @ts-ignore
			formArray.controls.forEach((ctrl: FormControl) => {
				if (ctrl.value == event.target.value) {
					formArray.removeAt(i)
					return
				}
				i++
			})
		}
	}

	onSubmit(): void {
        if(this.form.value.prescriptionOptions.length) {
            this.showError = false
            if (this.callbackConfirmation) this.callbackConfirmation()
            this.close()
        } else {
            this.showError = true
        }
	}
}

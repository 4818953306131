import { Injectable } from '@angular/core'
import * as moment from 'moment'
import jwtDecode from 'jwt-decode'
import { VersionService } from '../version/version.service'

@Injectable({
	providedIn: 'root',
})
export class HelpersService {
	constructor(private versionService: VersionService) {}

	private controlMessageError(typeError: string, control?: string): string {
		switch (typeError) {
			case 'required':
				return `O campo ${control} é requerido!`
			case 'email':
				return `E-mail inválido!`
			case 'cpfInvalid':
				return `CPF inválido!`
			case 'notSamePassword':
				return `A confirmação da senha não corresponde!`
			default:
				return ''
		}
	}

	public getControlMessageError(objError: any, control?: string): string {
		const typeError = Object.keys(objError).length
			? Object.keys(objError)[0]
			: ''
		return this.controlMessageError(typeError, control)
	}

	public converterSnakeToCamelCase(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[
					d.replace(/(\_\w)/g, (k) => {
						return k[1].toUpperCase()
					})
				] = obj[d]
			}
		}
		return newObj
	}

	public converterCamelCaseToSnake(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[this.camelToUnderscore(d)] = obj[d]
			}
		}
		return newObj
	}

	public camelToUnderscore(key: string): string {
		const result = key.replace(/([A-Z])/g, ' $1')
		return result.split(' ').join('_').toLowerCase()
	}

	decodePayloadJWT(token: string): any {
		try {
			return jwtDecode(token)
		} catch (Error) {
			return null
		}
	}

	public getDuration(date: Date | string): string {
		const end = moment()
		const init = moment.utc(date).local()
		const duration = moment.duration(end.diff(init))
		const durationYears = duration.years()
		const durationMonths = duration.months()
		const durationDays = duration.days()
		const durationHours = duration.hours()
		const durationMinutes = duration.minutes()
		let msg = `__YEARS____MONTHS____DAYS____HOURS____MINUTES__`

		const msgYear = durationYears ? durationYears + 'ano(s) ' : ''
		const msgMonth = durationMonths ? durationMonths + 'mês(es) ' : ''
		const msgDays = durationDays ? durationDays + 'd ' : ''
		const msgHours = durationHours ? durationHours + 'h ' : ''
		const msgMinutes = durationMinutes ? durationMinutes + 'm ' : '0m'

		if (date) {
			return msg
				.replace('__YEARS__', msgYear)
				.replace('__MONTHS__', msgMonth)
				.replace('__DAYS__', msgDays)
				.replace('__HOURS__', msgHours)
				.replace('__MINUTES__', msgMinutes)
		}
		return '-- --'
	}

	public getDateToAge(date: Date | string): string {
		if (!date) return '--'
		return `${moment().diff(moment.utc(date).local(), 'years')}`
	}

	public getDate(date: Date | string, withHours: boolean = false): string {
		if (!date) return '--'
		let format = withHours ? 'DD/MM/YYYY -- HH:mm' : 'DD/MM/YYYY'
		let dateString = moment.utc(date).local().format(format)
		return dateString.replace('--', 'às')
	}

	public converterTsInDate(
		date: Date | string,
		isTs: boolean = false
	): string {
		if (!date) return '--'
		const today = moment()
		const yesterday = moment().add(-1, 'days')
		let newDate = moment.utc(date).local()
		if (isTs) {
			newDate = moment.utc(date, 'X').local()
		}
		let dateString = `${newDate.format('DD/MM/YYYY -- HH:mm')}`

		if (newDate.format('DD/MM/YYYY') === today.format('DD/MM/YYYY')) {
			dateString = `hoje ${newDate.format('-- HH:mm')}`
		} else if (
			newDate.format('DD/MM/YYYY') === yesterday.format('DD/MM/YYYY')
		) {
			dateString = `ontem ${newDate.format('-- HH:mm')}`
		}

		return dateString.replace('--', 'às')
	}

	public scrollToElement($element: any): void {
		$element.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		})
	}

	public getQueryVariable(variable: string): string {
		const query = window.location.search.substring(1)
		const vars = query.split('&')
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=')
			if (pair[0] == variable) return pair[1]
		}
		return ''
	}

	public transformNumber(n: string, toFixed = 2) {
		return (Math.round(parseFloat(n) * 100) / 100).toFixed(toFixed)
	}

	public transformString(value: any, valueDefault: string = ''): string {
		return value !== null && value !== undefined ? `${value}` : valueDefault
	}

	public removeNullValuesFromObject(obj: any) {
		Object.keys(obj).forEach((key) => {
			if (obj[key] === null || obj[key] === undefined) {
				delete obj[key]
			}
		})

		return obj
	}

	public clearLocalStorage(currentVersion: string) {
		let version = localStorage.getItem('version') || '0.0.0'

		currentVersion = currentVersion.split('=')[1]
		currentVersion = currentVersion.replace('\n', '')

		if (version !== currentVersion) {
			localStorage.clear()
			localStorage.setItem('version', currentVersion)

			//@ts-ignore
			window.location = '/login'
		}
	}

	public setDevicesInList(
		mediaDevices: MediaDeviceInfo[],
		list: any[],
		kind: string
	): void {
		let count = 1
		mediaDevices.forEach((mediaDevice) => {
			if (mediaDevice.kind === kind) {
				list.push({
					label:
						mediaDevice.label ||
						`${kind.toLocaleLowerCase()} ${count}`,
					value: mediaDevice.deviceId,
				})
				count++
			}
		})
	}

	public addMaskCpf(v: string) {
		if (!v) return '--'
		v = v.replace(/\D/g, '')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
		return v
	}

	parseUA = (() => {
		//useragent strings are just a set of phrases each optionally followed by a set of properties encapsulated in paretheses
		const part = /\s*([^\s/]+)(\/(\S+)|)(\s+\(([^)]+)\)|)/g
		//these properties are delimited by semicolons
		const delim = /;\s*/
		//the properties may be simple key-value pairs if;
		const single = [
			//it is a single comma separation,
			/^([^,]+),\s*([^,]+)$/,
			//it is a single space separation,
			/^(\S+)\s+(\S+)$/,
			//it is a single colon separation,
			/^([^:]+):([^:]+)$/,
			//it is a single slash separation
			/^([^/]+)\/([^/]+)$/,
			//or is a special string
			/^(.NET CLR|Windows)\s+(.+)$/,
		]
		//otherwise it is unparsable because everyone does it differently, looking at you iPhone
		const many = / +/
		//oh yeah, bots like to use links
		const link = /^\+(.+)$/

		const inner = (properties: any, property: any) => {
			let tmp

			if ((tmp = property.match(link))) {
				properties.link = tmp[1]
			} else if (
				(tmp = single.reduce(
					(match, regex) => match || property.match(regex),
					null
				))
			) {
				properties[tmp[1]] = tmp[2]
			} else if (many.test(property)) {
				if (!properties.properties) properties.properties = []
				properties.properties.push(property)
			} else {
				properties[property] = true
			}

			return properties
		}

		return (input: any) => {
			const output: any = {}
			for (let match; (match = part.exec(input)); '') {
				output[match[1]] = {
					...(match[5] && match[5].split(delim).reduce(inner, {})),
					...(match[3] && { version: match[3] }),
				}
			}
			return output
		}
	})()
}

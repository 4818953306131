<div class="accordion" [class.active]="isOpen" [class.bgOdd]="bgOdd">
	<div class="accordion-item">
		<div class="accordion-text">
			<div class="accordion-title" (click)="showContentFaq()">
				<h2>{{ title }}</h2>
			</div>
			<div *ngIf="isOpen" class="accordion-collapse">
				<h3 [innerHTML]="description"></h3>
			</div>
		</div>
		<div *ngIf="!isOpen"></div>
		<div *ngIf="isOpen" class="video">
			<ng-content select="[accordionVideo]"></ng-content>
		</div>
		<button
			[class.open]="isOpen"
			type="button"
			(click)="showContentFaq()"
		></button>
	</div>
</div>

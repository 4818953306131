<div class="container sidebar" [class.released-patient]="releasedPatient">
	<div
		class="wrapper-button mb-4"
		*ngIf="(releasedPatient || appointment?.status === 'finished_video') && !showFeedback"
	>
		<button
			class="btn"
			type="button"
			[disabled]="
				meMed?.loading ||
				(meMed?.error && appointment?.status !== 'finished_video')
			"
			(click)="openConfirmFinalizedAppointment()"
		>
			Encerrar consulta
		</button>
	</div>
	<div class="row">
		<div class="col-lg-6" [class.toggle-flex]="!full">
			<app-video-call
				*ngIf="
					!releasedPatient &&
					token &&
					appointment?.status !== 'finished_video' &&
					appointment?.hash
				"
				[roomName]="appointment?.hash"
				[token]="token"
			></app-video-call>
			<div class="button-tooltip">
				<app-button-tooltip
					(click)="openModalConfirmReleasePatient()"
					[title]="'Liberar paciente'"
					description="Encerrar video chamada com o paciente"
					[pointer]="true"
					type="emergency"
				></app-button-tooltip>
			</div>
			<app-report
				[fullPageReport]="
					releasedPatient || appointment?.status === 'finished_video'
				"
				(emmitEventReportData)="changeReport($event)"
				*ngIf="showExams"
				[data]="report"
			></app-report>
			<app-feedback *ngIf="showFeedback" (callbackConfirmation)='finalizedAppointment($event)'></app-feedback>
		</div>
		<div class="col-lg-6">
			<div [class]="'box-exams' + (isLayerFull ? ' is-full' : '')">
				<div class="exams-header">
					<p>{{ patient?.name }}</p>
					<button type="button" (click)="toggleLayerExamsFull()">
						<img
							[src]="
								isLayerFull
									? '../../../assets/images/controls/full-screen-clean-off.png'
									: '../../../assets/images/controls/full-screen-clean-on.png'
							"
							alt="Exames full"
							title="Exames full"
						/>
					</button>
				</div>
				<div class="exams-content custom-scroll">
					<app-exams
						*ngIf="showExams"
						[patientId]="patient?.id"
						[setExamCardWidth]="true"
					></app-exams>
				</div>
			</div>
		</div>
	</div>
</div>

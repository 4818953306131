import { Component, OnInit } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'
import { QueueService } from '../../services/queue/queue.service'
import { ItemQueue, Queue } from '../../models/queue'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { ListHeader } from '../../models/list-header'
import { HelpersService } from '../../services/helpers/helpers.service'
import { ClearPatient } from '../../state/patient/patient.action'
import { Patient } from '../../models/patient'
import { ModalDefaultComponent } from '../../components/modals/modal-default/modal-default.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AppointmentsService } from '../../services/appointments/appointments.service'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { SocketIoService } from '../../services/socket-io/socket-io.service'
import { User } from '../../models/user'
import { StatsService } from '../../services/stats/stats.service'
import {
	listHeaderPatientWaitingAppointment,
	listHeaderPatientExams,
	listHeaderAppointmentsFinalized,
} from '../../mocks/queue'
import { Appointment } from '../../models/appointment'
import { Socket } from 'socket.io-client/build/esm/socket'
import { ClearQueue } from '../../state/queue/queue.action'

@Component({
	selector: 'app-queue',
	templateUrl: './queue.component.html',
	styleUrls: ['./queue.component.scss'],
})
export class QueueComponent implements OnInit {
	private subscriptions: Subscription[] = []
	loading: boolean = false
	private patient$?: Observable<Patient>
	patient?: Patient

	private user$?: Observable<User>
	user?: User

	private queue$: Observable<Queue>
	queue?: Queue
	currentPage: number = 1

	isOccupationalHealth: boolean = false

	consumers: string[] = []

	currentTabOn: string = ''

	titleTabAppointment: string
	titleTabExams: string
	titleTabAppointmentsFinalized: string
	listHeaderPatientWaitingAppointment: ListHeader[]
	listHeaderPatientExams: ListHeader[]
	listHeaderAppointmentsFinalized: ListHeader[]

	showLayer: boolean = false

	currentPatientId: string = ''
	currentAppointment?: ItemQueue
	currentAppointmentHash: string = ''
	currentOriginId: string = ''
	currentScreeningCompleted: boolean = false

	currentList: ItemQueue[] = []
	disabledPrevAppointment: boolean = false
	disabledNextAppointment: boolean = false
	hasButtonStartAppointment: boolean = false

	_reload: boolean = true

	private socket?: Socket

	constructor(
		private queueService: QueueService,
		private appointmentService: AppointmentsService,
		private store: Store<AppState>,
		private router: Router,
		public helpers: HelpersService,
		private modalDefault: NgbModal,
		private toast: ToastrService,
		private socketIoService: SocketIoService,
		private statsService: StatsService
	) {
		this.patient$ = this.store.select('patient')
		this.user$ = this.store.select('user')
		this.queue$ = this.store.select('queue')

		this.subscriptions.push(
			this.queue$.subscribe((state) => {
				this.queue = state
				this.updateButtonStartAppointment()
			})
		)

		this.subscriptions.push(
			this.patient$.subscribe((state) => {
				this.patient = state
			})
		)

		this.subscriptions.push(
			this.user$.subscribe((state) => {
				this.user = state
			})
		)

		this.titleTabAppointment = 'Consultas'
		this.titleTabExams = 'Exames offline'
		this.titleTabAppointmentsFinalized = 'Consultas realizadas'
		this.listHeaderPatientWaitingAppointment =
			listHeaderPatientWaitingAppointment
		this.listHeaderPatientExams = listHeaderPatientExams
		this.listHeaderAppointmentsFinalized = listHeaderAppointmentsFinalized
	}

	ngOnInit(): void {
		this.statsService.stats('in_queue_doctor', 'start')
		this.queueService.getAppointmentList('queue')
		this.watchEventsSocket()
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => {
			subscription.unsubscribe()
		})

		this.statsService.stats('in_queue_doctor', 'end')
	}

	private watchEventsSocket(): void {
		this.socket = this.socketIoService.getSocket()

		if (this.socket) {
			this.socket?.emit('get-connected-consumers', (response: any) => {
				this.consumers =
					this.socketIoService.getConsumersOnline(response)
			})
		}

		this.socket?.on('connect', () => {})

		this.socket?.on('consumer-connected', (consumer, otherConsumers) => {
			this.consumers =
				this.socketIoService.getConsumersOnline(otherConsumers)
		})

		this.socket?.on('consumer-disconnected', (consumer, otherConsumers) => {
			this.consumers =
				this.socketIoService.getConsumersOnline(otherConsumers)
		})

		this.socket?.on('panel_cmd', (data, callback) => {
			try {
				this.socketIoService.onContentEvent(
					data,
					callback,
					'appointmentCanceled',
					() => {
						this.getAppointmentsList()
					}
				)

				this.socketIoService.onContentEvent(
					data,
					callback,
					'updatePatientList',
					() => {
						if (this.currentTabOn === this.titleTabAppointment) {
							this.getAppointmentsList()
						} else {
							this.toast.warning(
								'Lista de Consultas teve atualizações'
							)
						}
					}
				)

				this.socketIoService.onContentEvent(
					data,
					callback,
					'updateOfflineExamList',
					() => {
						if (this.currentTabOn === this.titleTabExams) {
							this.getExamsOfflineList()
						} else {
							this.toast.warning(
								'Lista de Exames offline teve atualizações'
							)
						}
					}
				)
			} catch (e) {}
		})
	}

	private emitUpdateQueueWebSocket(
		appointment: Appointment | ItemQueue
	): void {
		this.socketIoService.emitEventSocket(
			'updatePatientList',
			null,
			appointment
		)
	}

	private reload() {
		setTimeout(() => (this._reload = false))
		setTimeout(() => (this._reload = true))
	}

	onOpenTab(tabOn: string): void {
		if (this.currentTabOn !== tabOn) {
			this.store.dispatch(ClearQueue())
		}

		this.currentTabOn = tabOn
		this.getList()
	}

	getList(page: number = 1) {
		this.currentPage = page
		if (this.currentTabOn === this.titleTabAppointment) {
			this.getAppointmentsList(page)
		} else if (this.currentTabOn === this.titleTabExams) {
			this.getExamsOfflineList(page)
		} else if (this.currentTabOn === this.titleTabAppointmentsFinalized) {
			this.getAppointmentsFinalizedList(page)
		}
	}

	getAppointmentsList(page: number = 1) {
		this.queueService.getAppointmentList(
			'queue',
			'appointment',
			undefined,
			page,
			this.isOccupationalHealth
		)
	}

	getExamsOfflineList(page: number = 1) {
		this.queueService.getAppointmentList(
			null,
			'exams',
			undefined,
			page,
			this.isOccupationalHealth
		)
	}

	getAppointmentsFinalizedList(page: number = 1) {
		this.queueService.getAppointmentList(
			'finalized',
			'appointment',
			undefined,
			page,
			this.isOccupationalHealth
		)
	}

	updateButtonStartAppointment() {
		if (this.hasButtonStartAppointment) {
			this.currentList = this.queue?.data || []
			setTimeout(() => {
				this.refreshCurrentScreeningCompleted()
			})
		}
	}

	openLayerExams(
		appointment: ItemQueue,
		queue?: ItemQueue[],
		hasButtonStartAppointment?: boolean
	): void {
		this.currentAppointment = appointment
		this.hasButtonStartAppointment = hasButtonStartAppointment || false
		this.currentPatientId = appointment.patient.id
		this.currentAppointmentHash = appointment.hash
		this.currentOriginId = appointment.originId
		this.setCurrentScreeningCompleted(appointment.screeningCompleted)
		this.showLayer = true
		this.currentList = queue || []

		this.statsService.stats('layer_exams_queue_doctor', 'start')
	}

	closeLayer(): void {
		this.showLayer = false
		this.store.dispatch(ClearPatient())
		this.statsService.stats('layer_exams_queue_doctor', 'end')

		this.currentPatientId = ''
		this.currentAppointmentHash = ''
		this.currentOriginId = ''
		this.currentScreeningCompleted = false
		this.currentList = []
	}

	setCurrentScreeningCompleted(screeningCompleted: boolean): void {
		this.currentScreeningCompleted = screeningCompleted
	}

	refreshCurrentScreeningCompleted(): void {
		if (this.currentPatientId) {
			const currentAppointment = this.currentList.find(
				(item) => item.id === this.currentAppointment?.id
			)
			this.setCurrentScreeningCompleted(
				currentAppointment?.screeningCompleted || false
			)
		}
	}

	openModalCancelAppointment(appointment: Appointment) {
		const self = this
		if (self.appointmentService.hasStoragePatientAndAppointment()) {
			self.toast.error(
				'Existe um paciente em teleatendimento.',
				'Você não pode realizar esta ação'
			)
		} else {
			const modalRef = self.modalDefault.open(ModalDefaultComponent)
			modalRef.componentInstance.title =
				'Deseja remover o paciente da fila?'
			modalRef.componentInstance.desc =
				'Essa a ação não poderá ser desfeita.'
			modalRef.componentInstance.callbackConfirmation = () => {
				self.statsService.stats('remove_patient_queue_doctor', 'start')
				self.appointmentService.removeAppointmentTheQueue(
					appointment.id,
					{
						fnSuccess() {
							self.statsService.stats(
								'remove_patient_queue_doctor',
								'end'
							)
							self.getList()
							self.emitUpdateQueueWebSocket(appointment)
							self.socketIoService.emitEventSocket(
								'appointmentCanceled',
								{ qrCode: appointment.hash },
								appointment
							)
						},
						fnError(responseError) {
							self.toast.error(
								`Status: ${responseError.status}`,
								'Server Error!'
							)
						},
						fnFinalized() {
							modalRef.close()
						},
					}
				)
			}
		}
	}

	startAppointment(appointment?: Appointment | ItemQueue): void {
		const self = this

		if (self.appointmentService.hasStoragePatientAndAppointment()) {
			self.toast.error(
				'Existe um paciente em teleatendimento.',
				'Você não pode realizar esta ação'
			)
		} else if (appointment) {
			self.statsService.stats(
				'start_appointment_doctor',
				'start',
				appointment.hash
			)
			self.store.dispatch(ShowLoading())
			console.log('appointmentService.startAppointment', appointment)
			self.appointmentService.startAppointment(appointment, {
				fnSuccess() {
					self.router.navigate(['/painel/consulta'])
					self.emitUpdateQueueWebSocket(appointment)
					self.statsService.stats(
						'start_appointment_doctor',
						'end',
						appointment.hash
					)
				},
				fnError(responseError) {
					if (responseError.status === 401) {
						self.toast.error(
							'Você precisa ser um médico para iniciar uma consulta!',
							'Ops!'
						)
					} else {
						self.toast.error(
							`Status: ${responseError.status}`,
							'Server Error!'
						)
					}
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			})
		} else {
			self.toast.warning(
				'Você precisa selecionar um paciente para iniciar uma consulta!',
				'Ops!'
			)
		}
	}

	getStatusAppointment(
		originOline?: boolean,
		screeningCompleted?: boolean
	): string {
		if (originOline && !screeningCompleted) {
			return 'Em triagem'
		}
		if (originOline && screeningCompleted) {
			return 'Aguardando consulta'
		}
		return 'Offline'
	}

	toggleIsOccupationalHealth(): void {
		this.isOccupationalHealth = !this.isOccupationalHealth

        this.getList()
	}
}

import { ListHeader } from '../models/list-header'

export const listHeaderPatientWaitingAppointment: ListHeader[] = [
	{
		value: 'online',
		label: 'Cabine',
		align: 'center',
	},
	{
		value: 'emergencyLevel',
		label: '',
		align: 'center',
	},
	{
		value: 'waiting',
		label: 'Em espera',
		align: 'center',
		className: 'text-center',
	},
	{
		value: 'specialty',
		label: 'Especialidade',
		align: 'center',
		className: 'text-center',
	},
	{
		value: 'tags',
		label: 'Saúde ocupacional',
		align: 'center',
		className: 'text-center',
	},
	{
		value: 'name',
		label: 'Nome',
	},
	{
		value: 'client',
		label: 'Cliente',
	},
	{
		value: 'origin',
		label: 'Origem',
	},
	{
		value: 'status',
		label: 'Status',
	},
	{
		value: 'initialed-appointment',
		label: '',
		align: 'center',
	},
	{
		value: 'exams',
		label: '',
		align: 'center',
	},
	{
		value: 'action',
		label: '',
		align: 'center',
	},
]

export const listHeaderPatientExams: ListHeader[] = [
	{
		value: 'createdAt',
		label: 'Data',
	},
	{
		value: 'tags',
		label: 'Saúde ocupacional',
		align: 'center',
		className: 'text-center',
	},
	{
		value: 'name',
		label: 'Nome',
	},
	{
		value: 'client',
		label: 'Cliente',
	},
	{
		value: 'origin',
		label: 'Origem',
	},
	{
		value: 'exams',
		label: '',
		align: 'center',
	},
]

export const listHeaderAppointmentsFinalized: ListHeader[] = [
	{
		value: 'endedAt',
		label: 'Data',
	},
	{
		value: 'specialty',
		label: 'Especialidade',
		align: 'center',
		className: 'text-center',
	},
	{
		value: 'tags',
		label: 'Saúde ocupacional',
		align: 'center',
		className: 'text-center',
	},
	{
		value: 'name',
		label: 'Nome',
	},
	{
		value: 'client',
		label: 'Cliente',
	},
	{
		value: 'origin',
		label: 'Origem',
	},
	{
		value: 'exams',
		label: '',
		align: 'center',
	},
]

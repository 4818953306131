import { Action } from '@ngrx/store'

export enum ActionPatientExamsTypes {
	DoRequest = '@PATIENT_EXAMS/DO_REQUEST',
	RequestSuccessExams = '@PATIENT_EXAMS/REQUEST_SUCCESS_EXAMS',
	RequestSuccessReport = '@PATIENT_EXAMS/REQUEST_SUCCESS_REPORT',
	RequestFailure = '@PATIENT_EXAMS/REQUEST_FAILURE',
	RequestClear = '@PATIENT_EXAMS/REQUEST_CLEAR',
}

export const DoRequestPatientExams = () => {
	return <Action>{ type: ActionPatientExamsTypes.DoRequest }
}

export const RequestSuccessPatientExams = (exams: any) => {
    return <Action>{
        type: ActionPatientExamsTypes.RequestSuccessExams,
        payload: exams,
    }
}

export const RequestSuccessPatientReport = (report: any) => {
    return <Action>{
        type: ActionPatientExamsTypes.RequestSuccessReport,
        payload: report,
    }
}

export const RequestFailurePatientExams = () => {
	return <Action>{ type: ActionPatientExamsTypes.RequestFailure }
}

export const RequestClearPatientExams = () => {
    return <Action>{ type: ActionPatientExamsTypes.RequestClear }
}

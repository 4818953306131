<div class="modal-body text-center">
	<h5 *ngIf="title">{{ title }}</h5>
</div>
<div class="modal-content">
	<form
		action="#"
		autocomplete="off"
		(ngSubmit)="onSubmit()"
		[formGroup]="form"
	>
		<div class="form-modal px-4">
			<div class="w-100">
				<app-select
					id="mic"
					name="mic"
					[options]="[]"
					labelName="Selecione o Microfone"
					[form]="form"
					[inputModel]="'Microfone'"
				></app-select>
			</div>
			<div class="w-100">
				<app-select
					id="camera"
					name="camera"
					[options]="[]"
					labelName="Selecione a Câmera"
					[form]="form"
					[inputModel]="'Camera'"
				></app-select>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn outline" (click)="close()">
				Cancelar
			</button>
			<button type="submit" class="btn btn-xs">Confirmar</button>
		</div>
	</form>
</div>

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from '../../models/callbacks'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class StatusService {
	constructor(private http: HttpClient) {}

	getStatusApis(callbacks: Callbacks) {
		this.http
			.get(`${environment.api}/health_check?complete=true`)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callbacks.fnSuccess(data)
				},
				(error) => {
					callbacks.fnError(error)
				}
			)
	}
}

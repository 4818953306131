import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import { environment } from '../../../environments/environment'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { AddUser } from '../../state/user/user.action'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(
		private http: HttpClient,
		private helpers: HelpersService,
		private store: Store<AppState>
	) {}

	getUser(id: string, callback?: Function): void {
		this.http
			.get<any>(`${environment.api}/users/${id}/doctor`)
			.pipe()
			.subscribe(
				(data) => {
					console.log('GET USER SERVICE: ', data)
					this.store.dispatch(
						AddUser(this.helpers.converterSnakeToCamelCase(data))
					)
					if (callback) callback()
				},
				(err) => {
					console.warn(err)
				}
			)
	}

	getCities(uf: string, callback: Callbacks): void {
		this.http
			.get<any>(
				`${environment.memedApi}/cidades?page[limit]=2000&filter[uf]=${uf}`
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					console.log('GET CITIES SERVICE: ', data)
					callback.fnSuccess(data)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}

	getSpecialities(callback: Callbacks): void {
		this.http
			.get<any>(`${environment.memedApi}/especialidades`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					console.log('GET SPECIALTIES SERVICE: ', data)
					callback.fnSuccess(data)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}

	updateUser(id: string, payload: any, callback: Callbacks): void {
		this.http
			.patch<any>(`${environment.api}/users/${id}/doctor`, payload)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					console.log('UPDATE USER SERVICE: ', data)
					callback.fnSuccess(data)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}
}

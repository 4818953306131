import { Injectable } from '@angular/core'
import { ChartConfiguration } from 'chart.js'
import * as moment from 'moment'
import { HistoryExamItem } from '../../models/patient-exams'

@Injectable({
	providedIn: 'root',
})
export class ExamsChartService {
	constructor() {}

	generateChartTemperature(
		data: HistoryExamItem[]
	): ChartConfiguration['data'] {
		let datasets: any = []
		let labels: string[] = []
		data.forEach((item) => {
			datasets.push(item.examValue.temp)
			labels.push(moment(item.examValue.ts, 'X').format('DD/MM/YYYY'))
		})

		return {
			datasets: [
				{
					data: datasets,
					label: 'Temperatura',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#34518F',
					pointBackgroundColor: '#34518F',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
				},
			],
			labels,
		}
	}

	generateChartBloodPressure(
		data: HistoryExamItem[]
	): ChartConfiguration['data'] {
		let datasetsSbp: any = []
		let datasetsDbp: any = []
		let labels: string[] = []

		data.forEach((item) => {
			datasetsSbp.push(item.examValue.sbp)
			datasetsDbp.push(item.examValue.dbp)
			labels.push(moment(item.examValue.ts, 'X').format('DD/MM/YYYY'))
		})

		return {
			datasets: [
				{
					data: datasetsSbp,
					label: 'PAS',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#34518F',
					pointBackgroundColor: '#34518F',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
				},
				{
					data: datasetsDbp,
					label: 'PAD',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#EB5757',
					pointBackgroundColor: '#EB5757',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
				},
			],
			labels,
		}
	}

	generateChartGlucose(data: HistoryExamItem[]): ChartConfiguration['data'] {
		let datasets: any = []
		let labels: string[] = []
		data.forEach((item) => {
			datasets.push(item.examValue.value)
			labels.push(moment(item.examValue.ts, 'X').format('DD/MM/YYYY'))
		})

		return {
			datasets: [
				{
					data: datasets,
					label: 'Glicemia',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#34518F',
					pointBackgroundColor: '#34518F',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
				},
			],
			labels,
		}
	}

	generateChartHeartRate(
		data: HistoryExamItem[]
	): ChartConfiguration['data'] {
		let datasets: any = []
		let labels: string[] = []
		data.forEach((item) => {
			datasets.push(item.examValue.hr)
			labels.push(moment(item.examValue.ts, 'X').format('DD/MM/YYYY'))
		})

		return {
			datasets: [
				{
					data: datasets,
					label: 'Frequência cardíaca',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#34518F',
					pointBackgroundColor: '#34518F',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
				},
			],
			labels,
		}
	}

	generateChartRespiratoryFrequency(
		data: HistoryExamItem[]
	): ChartConfiguration['data'] {
		let datasets: any = []
		let labels: string[] = []
		data.forEach((item) => {
			datasets.push(item.examValue.rr)
			labels.push(moment(item.examValue.ts, 'X').format('DD/MM/YYYY'))
		})

		return {
			datasets: [
				{
					data: datasets,
					label: 'Frequência respiratória',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#34518F',
					pointBackgroundColor: '#34518F',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
				},
			],
			labels,
		}
	}

	generateChartSpo2(data: HistoryExamItem[]): ChartConfiguration['data'] {
		let datasets: any = []
		let labels: string[] = []
		data.forEach((item) => {
			datasets.push(item.examValue.value)
			labels.push(moment(item.examValue.ts, 'X').format('DD/MM/YYYY'))
		})

		return {
			datasets: [
				{
					data: datasets,
					label: 'Saturação periférica de oxigênio',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#34518F',
					pointBackgroundColor: '#34518F',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
				},
			],
			labels,
		}
	}

	generateChartAudiometry(data: any): ChartConfiguration['data'] {
		let datasetsLeft: any[] = []
		let datasetsRight: any[] = []
        let labels: number[] = [500, 1000, 1500, 2000, 4000, 8000]

		datasetsLeft = data.left.map((item: any) => {
			return {
                y: item.leftEarVolume,
                x: item.frequency
            }
		})

		datasetsRight = data.right.map((item: any) => {
			return {
                y: item.rightEarVolume,
                x: item.frequency
            }
		})

		return {
			datasets: [
				{
					data: datasetsLeft,
					label: 'Esquerdo',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#34518F',
					pointBackgroundColor: '#34518F',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
                    showLine: true,
				},
				{
					data: datasetsRight,
					label: 'Direito',
					backgroundColor: 'rgba(255,255,255,.3)',
					borderColor: '#EB5757',
					pointBackgroundColor: '#EB5757',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)',
					fill: 'origin',
                    showLine: true
				},
			],
			labels,
		}
	}
}

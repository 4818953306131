import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class FeedbackCidsService {
	constructor(private http: HttpClient) {}

	getListCid(callback: Callbacks, search?: string): any {
		let params: any = {
			per_page: 300,
		}

		if (search) {
			params = {
				...params,
				search,
			}
		}

		this.http
			.get<any>(`${environment.api}/cids`, {
				params,
			})
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callback.fnSuccess(data)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}
}

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
	@Input() isShowAlert: boolean = true
	@Input() typeAlert: string = 'primary'
	public isClosed: boolean = false

	constructor() {}

	ngOnInit(): void {}

	closeAlert(): void {
		this.isClosed = true
		setTimeout(() => {
			this.isShowAlert = false
		}, 600)
	}
}

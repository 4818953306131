import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'

@Component({
    selector: 'app-ecg',
    templateUrl: './ecg.component.html',
    styleUrls: ['./ecg.component.scss']
})
export class EcgComponent implements OnInit {
    @ViewChild('ecg', { static: false }) public ecg?: ElementRef
    @Input() public optionsEcg: any
    public canvas?: HTMLCanvasElement
    public ctx: any

    private data: any
    private min: any = Math.min()
    private max: any = Math.max()
    private diff: any = 0
    private verticalCenter2: any = 0
    private scale: any = 0.5
    private yScale: any = 0.1
    private verticalCenter: any
    private gridMinorStep: any
    private gridMajorStep: any
    private device: any

    constructor() {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.init()
        }, 1000)
    }

    private init() {
        this.canvas = this.ecg?.nativeElement
        if (this.canvas) {
            this.verticalCenter = this.canvas.height / 2
        }
        this.gridMinorStep = parseInt(String(512 / 25))
        this.gridMajorStep = this.gridMinorStep * 5
        if (this.optionsEcg?.wave || this.optionsEcg?.waveList) {
            this.generateEcg()
        }
    }

    private generateEcg() {
        if (this.canvas) {
            this.ctx = this.canvas.getContext('2d')
            this.device = this.optionsEcg?.device
            let tempData = this.optionsEcg?.wave ? this.optionsEcg?.wave.split(',') :
                this.optionsEcg?.waveList ? this.optionsEcg?.waveList : []

            if (!this.optionsEcg?.waveList) {
                for (let i = 0; i < tempData.length; i += 1) {
                    if (tempData) {
                        tempData[i] = parseInt(tempData[i])
                    }
                }
            }

            this.data = tempData

            this.data.forEach((v: number) => {
                if (this.min > v) this.min = v
                if (this.max < v) this.max = v
            })

            this.diff = this.max - this.min

            this.verticalCenter2 = this.min + this.diff / 2

            this.drawWave()
        }
    }

    private defineLineStyle(step: number) {
        if (this.ctx) {
            this.ctx.strokeStyle = '#999999'
            this.ctx.lineWidth = '.2'
            if (step % this.gridMajorStep === 0) {
                this.ctx.strokeStyle = 'red'
                this.ctx.lineWidth = '.5'
            }
        }
    }

    private drawWave() {
        this.drawGrid()
        if (this.canvas && this.ctx) {
            const f = this.canvas.height / this.diff
            this.ctx.strokeStyle = 'black'
            this.ctx.lineWidth = '1.0'
            this.ctx.beginPath()
            this.ctx.moveTo(0, this.verticalCenter)

            let offset = 0

            if (this.device === 'i2Dtx' || (this.optionsEcg?.waveList && this.optionsEcg?.waveList.length)) {
                this.yScale = 0.5 * -1
                offset = this.verticalCenter2 - this.verticalCenter
            }

            for (let x = 0; x < this.data.length; x++) {
                this.ctx.lineTo(x * this.scale, (this.data[x] - offset) * f * this.yScale + this.verticalCenter)
            }
            this.ctx.stroke()
        }
    }

    private drawGrid() {
        if (this.canvas && this.ctx) {
            this.canvas.width = this.data?.length * this.scale
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

            for (let x = 1; x < this.data.length; x++) {
                if (x % this.gridMinorStep === 0) {
                    this.defineLineStyle(x)

                    this.ctx.beginPath()
                    this.ctx.moveTo(x * this.scale, 0)
                    this.ctx.lineTo(x * this.scale, this.canvas.height)
                    this.ctx.stroke()
                }
            }

            for (let y = 1; y < this.canvas.height / this.scale; y++) {
                if (y % this.gridMinorStep === 0) {
                    this.defineLineStyle(y)
                    this.ctx.beginPath()
                    this.ctx.moveTo(0, y * this.scale)
                    this.ctx.lineTo(this.canvas.width, y * this.scale)
                    this.ctx.stroke()
                }
            }
        }
    }

}

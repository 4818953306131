<div *ngFor="let quiz of quizes" class="wrapper-quiz-responses">
	<app-loading-progress *ngIf="loading"></app-loading-progress>
	<!--	<div class="wrapper-title px-3 pb-4">-->
	<!--		<h3>Questionário saúde ocupacional</h3>-->
	<!--	</div>-->
	<div
		[ngClass]="quiz.type !== 'occupational_health' ? 'mb-3' : 'mb-0'"
		class="wrapper-quiz-responses-body custom-scroll"
	>
		<div *ngFor="let pillar of quiz.json?.pillars" class="pillars">
			<h4>{{ pillar.title }}</h4>
			<p *ngIf="pillar.description">{{ pillar.description }}</p>
			<div class="wrapper-responses">
				<div
					*ngFor="let question of pillar.questions; let i = index"
					class="questions"
				>
					<h5>
						<span>{{ i + 1 }} - </span>{{ question.label }}
					</h5>
					<p class="response">{{ question.value }}</p>
					<div
						*ngIf="conditional(question)"
						class="conditional-question"
					>
						<div
							*ngFor="
								let conditionalQuestion of question.conditional
									.questions;
								let j = index
							"
						>
							<h5>
								<span>{{ i + 1 }}.{{ j + 1 }} -</span>
								{{ conditionalQuestion.label }}
							</h5>
							<p class="response">
								{{ conditionalQuestion.value }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		*ngIf="quiz.type === 'occupational_health'"
		class="wrapper-quiz-responses-footer px-3 pb-3"
	>
		<button
			type="button"
			[class.btn-outline-success]="
				(isApproved != null && !isApproved) || isApproved == null
			"
			[class.btn-success]="isApproved != null && isApproved"
			[disabled]="loading || !hasPermissionCurrentDoctor()"
			(click)="approveQuiz()"
		>
			Liberado
		</button>
		<button
			type="button"
			[class.btn-outline-danger]="
				(isApproved != null && isApproved) || isApproved == null
			"
			[class.btn-danger]="isApproved != null && !isApproved"
			[disabled]="loading || !hasPermissionCurrentDoctor()"
			(click)="approveQuiz(false)"
		>
			Encaminhado à MT
		</button>
	</div>
	<div
		*ngIf="
			isApproved != null && doctor && quiz.type === 'occupational_health'
		"
		class="px-3 pt-3"
	>
		<p>
			{{ isApproved ? 'A' : 'Re' }}provado por: {{ doctor?.name }}
			{{ doctor?.lastName }} - CRM {{ doctor?.crm }}{{ doctor?.crmUf }}
		</p>
	</div>
	<div
		*ngIf="
			description &&
			isApproved != null &&
			doctor &&
			quiz.type === 'occupational_health'
		"
	>
		<sub class="pl-3">Motivo da reprovação</sub>
		<p class="px-3">{{ description }}</p>
	</div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ExamsService } from '../../../services/exams/exams.service'

@Component({
    selector: 'app-imaging-exams',
    templateUrl: './imaging-exams.component.html',
    styleUrls: ['./imaging-exams.component.scss']
})
export class ImagingExamsComponent implements OnInit {
    @Input() id?: string
    @Input() pathExams?: string
    @Input() title?: string
    @Input() description?: string
    @Input() highlighted: boolean = false
    @Input() active: boolean = false
    @Input() isExpandImage: boolean = true

    @Output() expandImage: EventEmitter<any> = new EventEmitter()

    blob: string = ''
    linkFinal: string = ''
    loading: boolean = false

    constructor(private examsService: ExamsService) {
    }

    ngOnInit(): void {
        if (this.pathExams) {
            this.loading = true
            this.examsService.getFile(this.pathExams)
                .then((data) => {
                    this.blob = URL.createObjectURL(data)
                    this.loading = false
                }).catch((error) => {
                this.loading = false
            })
        }
    }

    fnExpandImage(): void {
        this.expandImage.emit('expandImage')
    }

}

import { ActionQueueTypes } from './queue.action'
import { ActionModel } from '../action'
import { Queue } from '../../models/queue'

const initialState: Queue = {
	loading: false,
	error: false,
	data: [],
    total: 0
}

export function queueReducer(state: Queue = initialState, action: ActionModel) {
	switch (action.type) {
		case ActionQueueTypes.DoRequest: {
			state = {
				...state,
				loading: true,
                error: false,
			}
			return state
		}

		case ActionQueueTypes.RequestSuccess: {
            const { queue, total} = action.payload
			state = {
				...state,
				loading: false,
				data: queue,
                total
			}
			return state
		}

		case ActionQueueTypes.RequestFailure: {
			state = {
				...state,
				loading: false,
				error: true,
			}
			return state
		}

		case ActionQueueTypes.RequestClear: {
			state = {
				...initialState,
			}
			return state
		}

		default:
			return state
	}
}

<ul *ngIf="patient?.name">
	<li *ngIf="patient?.documentCpf">
		CPF: {{ helpers.addMaskCpf(patient?.documentCpf || '') }}
	</li>
	<li *ngIf="patient?.birthdate">
		Data de nascimento: {{ helpers.getDate(patient?.birthdate || '') }}
	</li>
	<li *ngIf="patient?.age">Idade: {{ patient?.age }} anos</li>
	<li>Sexo biológico: {{ patient?.gender }}</li>
	<li *ngIf="patient?.contactEmail">E-mail: {{ patient?.contactEmail }}</li>
	<li *ngIf="patient?.contactPhone">Telefone: {{ patient?.contactPhone }}</li>
</ul>

<div
    class='report'
    [class.full-page]='fullPageReport'
    [class.full]='full'
    [class.hidden]='hidden && !fullPageReport'
>
    <div class='report-head'>
        <div>
            <app-image
                [path]="'/assets/images/medical-report.png'"
                title='Prontuário'
            ></app-image>
            <h6>Prontuário</h6>
        </div>
        <div>
            <button
                *ngIf='!hidden && !fullPageReport'
                type='button'
                (click)='setFullReport()'
            >
                <img
                    [src]="
						full
							? '../../../assets/images/controls/full-screen-clean-off.png'
							: '../../../assets/images/controls/full-screen-clean-on.png'
					"
                    alt='Prontuário full'
                    title='Prontuário full'
                />
            </button>
        </div>
    </div>
    <div class='report-content'>
        <ckeditor
            [editor]='Editor'
            [config]='configEditor'
            [(ngModel)]='value'
            [data]='value'
            (change)='onChange($event)'
        ></ckeditor>
    </div>
</div>

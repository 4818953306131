<div class="wrapper position-relative">
	<h2>Áudio</h2>
	<div class="wrapper-content">
		<div class="devices">
			<div
				*ngFor="let item of optionsAudioOutput; index as i"
				(click)="changeAudioOutput(item.value, (currentSelected = i))"
				[class.active]="currentSelected == i"
			>
				{{ item.label }}
			</div>
		</div>
		<div class="devices no-data" *ngIf="!optionsAudioOutput.length">
			<div>Nenhum device localizado</div>
		</div>
		<div class="content p-0 sh-0">
			<video
				#audioElement
				src="/assets/testing/test-audio.mp4"
				controls
			></video>
		</div>
	</div>
</div>

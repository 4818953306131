import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
// @ts-ignore
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
	@Input() data: string = ''
	@Input() fullPageReport: boolean = false
	Editor = ClassicEditor
	configEditor: any
	value: string = ''
	@Input() full: boolean = false
	@Input() hidden: boolean = false
	@Output() emmitEventReport: EventEmitter<any> = new EventEmitter()
	@Output() emmitEventReportData: EventEmitter<string> = new EventEmitter()

	constructor() {
		this.configEditor = {
			removePlugins: [
				'EasyImage',
				'ImageUpload',
				'MediaEmbed',
				'Table',
				'Anchor',
				'Indent',
			],
		}
	}

	ngOnInit(): void {
		this.value = this.data
	}

	setFullReport(): void {
		this.full = !this.full
		this.emmitEventReport.emit('full')
	}

	onChange({ editor }: ChangeEvent) {
		const data = editor.getData()
		this.emmitEventReportData.emit(data)
	}
}

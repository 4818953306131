import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'

@Component({
	selector: 'app-modal-issue-medical-certificate',
	templateUrl: './modal-medical-certificate.component.html',
	styleUrls: ['./modal-medical-certificate.component.scss'],
})
export class ModalMedicalCertificateComponent implements OnInit {
	@Input() callbackCreation?: Function
	@Input() title?: string
    @Output() emmitEvent: EventEmitter<any> = new EventEmitter()
	form: FormGroup

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder,
        private toast: ToastrService
	) {
		this.form = this.formBuilder.group({
			days: [null, [Validators.required]],
			cid: [null, Validators.required],
		})
	}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	onSubmit(): void {
        if (this.form.valid) {
            this.emmitEvent.emit(this.form.value)
            this.close()
        } else {
            this.toast.error('Erro no formulário.')
        }
    }
}

<div class="modal-body text-center">
	<h5 *ngIf="title">{{ title }}</h5>
</div>
<div class="modal-content">
	<form
		action="#"
		autocomplete="off"
		(ngSubmit)="onSubmit()"
		[formGroup]="form"
	>
		<div class="form-modal px-4">
			<textarea
                [formGroup]='form'
                formControlName='description'
                name='description'
                id='description'
            ></textarea>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn outline" (click)="close()">
		Cancelar
	</button>
	<button type="button" class="btn btn-xs" (click)="onSubmit()">
		Confirmar
	</button>
</div>

<div class="modal-body text-center">
	<h5 *ngIf="title">{{ title }}</h5>
</div>
<div class="modal-content">
	<form
		action="#"
		autocomplete="off"
		(ngSubmit)="onSubmit()"
		[formGroup]="form"
	>
		<div class="form-modal px-4">
			<div class="w-100">
				<app-input
					id="days"
					name="days"
					typeInput="text"
					placeholder="Número de dias"
					labelName="Número de dias"
					[form]="form"
				></app-input>
			</div>
			<div class="w-100">
				<app-input
					id="cid"
					name="cid"
					typeInput="text"
					placeholder="CID"
					labelName="CID"
					[form]="form"
				></app-input>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn outline" (click)="close()">
		Cancelar
	</button>
	<button type="button" class="btn btn-xs" (click)="onSubmit()">
		Confirmar
	</button>
</div>

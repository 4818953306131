import { ActionExamsListTypes } from './exams-list.action'
import { ActionModel } from '../action'
import { ExamsList } from '../../models/exams-list'

const initialState: ExamsList = {
    loading: false,
    error: false,
    data: []
}

export function examsListReducer(state: ExamsList = initialState, action: ActionModel) {
	switch (action.type) {
		case ActionExamsListTypes.DoRequest: {
			state = {
                ...state,
                loading: true,
            }
			return state
		}

        case ActionExamsListTypes.RequestSuccess: {
            state = {
                ...state,
                loading: false,
                data: action.payload
            }
            return state
        }

        case ActionExamsListTypes.RequestFailure: {
            state = {
                ...state,
                loading: false,
                error: true,
            }
            return state
        }

        case ActionExamsListTypes.RequestClear: {
            state = {
                ...initialState,
                loading: false,
                error: false,
            }
            return state
        }

		default:
			return state
	}
}

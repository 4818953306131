import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { HelpersService } from '../../services/helpers/helpers.service'
import { ExamsService } from '../../services/exams/exams.service'
import {
    HistoryAppointmentPatientExams,
    HistoryExamItem,
    HistoryExamItemWithChart,
    HistoryReportItem,
    PatientExams
} from '../../models/patient-exams'
import { AppState } from '../../state/app-state'
import { RequestClearPatientExams } from '../../state/patient-exams/patient-exams.action'
import { Socket } from 'socket.io-client/build/esm/socket'
import { SocketIoService } from '../../services/socket-io/socket-io.service'
import { ClearAppointment } from '../../state/appointment/appointment.action'
import { Patient } from '../../models/patient'

import { ChartConfiguration, ChartType } from 'chart.js'
import { ExamsChartService } from '../../services/exams/exams-chart.service'

@Component({
    selector: 'app-exams',
    templateUrl: './exams.component.html',
    styleUrls: ['./exams.component.scss']
})
export class ExamsComponent implements OnInit {
    @Input() patientId?: string = ''
    @Input() isPageResultExams: boolean = false
    @Input() identifier: string = ''
    @Input() setExamCardWidth: boolean = false
    @Output() emmitEventError: EventEmitter<any> = new EventEmitter()

    private patient$?: Observable<Patient>
    patient?: Patient

    private subscriptions: Subscription[] = []

    patientExams$?: Observable<PatientExams>

    socket?: Socket

    _reload: boolean = true

    isEmptyQuiz: boolean = false

    historyAppointmentPatientExams: HistoryAppointmentPatientExams[] = []

    historyBodyData: any[] = []
    historyAudiometry: any[] = []
    historyComplaintList: HistoryAppointmentPatientExams[] = []
    historyLungList: HistoryAppointmentPatientExams[] = []
    historyHeartList: HistoryAppointmentPatientExams[] = []
    historySkinList: HistoryAppointmentPatientExams[] = []
    historyThroatList: HistoryAppointmentPatientExams[] = []
    historyEyesList: any[] = []
    historyVisualAcuityList: any[] = []
    historyHeardList: any[] = []
    historyReports: HistoryReportItem[] = []

    historyTemperature: HistoryExamItemWithChart = {
        list: [],
        chart: {}
    }

    historyGlucose: HistoryExamItemWithChart = {
        list: [],
        chart: {}
    }

    historyEcg: HistoryExamItemWithChart = {
        list: [],
        chart: {}
    }

    historyBloodPressure: HistoryExamItemWithChart = {
        list: [],
        chart: {}
    }

    historySpO2: HistoryExamItemWithChart = {
        list: [],
        chart: {}
    }

    itemWithExpandedImage: any
    listWithExpandedImage: any[] = []
    titleSectionWithExpandedImage: string = ''
    directionFlagWithExpandedImage?: string = ''
    showLayer: boolean = false

    public lineChartOptions: ChartConfiguration['options'] = {
        responsive: false,
        elements: {
            line: {
                tension: 0.3
            }
        },
        scales: {
            x: {},
            'y-axis-0': {
                position: 'left'
            },
            'y-axis-1': {
                position: 'right',
                grid: {
                    color: 'rgba(255,0,0,0.3)'
                },
                ticks: {
                    color: 'transparent'
                }
            }
        }
    }
    public lineChartOptions2: ChartConfiguration['options'] = {
        responsive: false,
        elements: {
            line: {
                tension: 0.3
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function(value, index, ticks) {
                        return value + 'Hz'
                    }
                }
            },
            y: {
                min: 0,
                max: 100,
                reverse: true,
                ticks: {
                    callback: function(value, index, ticks) {
                        return value + 'dB'
                    }
                }
            },
            'y-axis-1': {
                position: 'right',
                grid: {
                    color: 'rgba(255,0,0,0.3)'
                },
                ticks: {
                    color: 'transparent'
                }
            }
        }
    }
    public lineChartType: ChartType = 'line'
    public lineChartType2: ChartType = 'scatter'

    constructor(
        public helpers: HelpersService,
        private examsService: ExamsService,
        private store: Store<AppState>,
        private socketIoService: SocketIoService,
        private examsChartService: ExamsChartService
    ) {
        this.patientExams$ = this.store.select('patientExams')

        this.subscriptions.push(
            this.patientExams$.subscribe((state) => {
                this.getPatientAndExams(state)
            })
        )

        this.patient$ = this.store.select('patient')
        this.patient$.subscribe((state) => {
            this.patient = state
        })
    }

    ngOnInit(): void {
        this.initialize()
    }

    ngOnDestroy(): void {
        this.patientId = ''
        this.resetAll()
        this.store.dispatch(RequestClearPatientExams())
        this.store.dispatch(ClearAppointment())
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe()
        })
    }

    setIsEmptyQuiz(value: boolean) {
        this.isEmptyQuiz = value
    }

    private reload() {
        setTimeout(() => (this._reload = false))
        setTimeout(() => (this._reload = true))
    }

    initialize(): void {
        const self = this
        if (self.patientId) {
            if (self.isPageResultExams) {
                self.examsService.getPatientInfoAndExams(
                    self.patientId,
                    self.isPageResultExams,
                    {
                        fnSuccess(data?: any) {
                            //
                        },
                        fnError(error) {
                            self.emmitEventError.emit(error)
                        }
                    }
                )
            } else {
                self.examsService.getPatientInfoAndExams(self.patientId)
            }
            self.watchWebSocket()
        }
    }

    getPatientAndExams(state: PatientExams) {
        this.resetAll()
        this.setHistoryReports(state)
        this.setHistoryAppointmentPatientExams(state)

        this.sortByLists()

        this.setExamsList('anamnese', this.historyComplaintList)
        this.setExamsList('pulmao', this.historyLungList)
        this.setExamsList('coracao', this.historyHeartList)
        this.setExamsList('garganta', this.historyThroatList)
        this.setExamsList('pele', this.historySkinList)
        this.setExamsListWithListsRightAndLeft('olho', this.historyEyesList)
        this.setExamsListWithListsRightAndLeft('ouvido', this.historyHeardList)
        this.setHistoryBodyData()

        this.setHistoryDataBioMonitor('temperature', this.historyTemperature)
        this.setHistoryDataBioMonitor('glucose', this.historyGlucose)
        this.setHistoryDataBioMonitor('pressure', this.historyBloodPressure)
        this.setHistoryDataBioMonitor('spo2', this.historySpO2)
        this.setHistoryDataBioMonitor('ecg', this.historyEcg)

        this.setHistoryAudiometry()
        this.setHistoryVisualAcuity()
    }

    setHistoryReports(state: PatientExams) {
        this.historyReports = state.historyReports.map((item) => {
            return {
                ...item,
                createdAt: this.helpers.converterTsInDate(item.createdAt)
            }
        })
    }

    setHistoryAppointmentPatientExams(state: PatientExams) {
        state.historyExams.forEach((itemExams) => {
            itemExams.exams.forEach((item) => {
                const appointmentId = item.appointmentId
                const index = this.historyAppointmentPatientExams.findIndex(
                    (itemHistory: any) =>
                        itemHistory.appointmentId === appointmentId
                )

                if (index !== -1) {
                    this.historyAppointmentPatientExams[index].exams.push(item)
                } else {
                    const exams: HistoryExamItem[] = []
                    exams.push(item)

                    let obj = {
                        appointmentId,
                        appointmentCreatedAt: this.helpers.converterTsInDate(
                            item.appointmentCreatedAt
                        ),
                        createdAt: item.appointmentCreatedAt,
                        exams
                    }

                    this.historyAppointmentPatientExams.push(obj)
                }
            })
        })
    }

    setExamsList(type: string, list: HistoryAppointmentPatientExams[]) {
        this.historyAppointmentPatientExams.forEach(
            (itemHistoryAppointmentPatientExams) => {
                const exams: HistoryExamItem[] = []
                itemHistoryAppointmentPatientExams.exams.forEach((item) => {
                    if (item.examSlug.includes(type)) {
                        exams.push(item)
                    }
                })

                if (exams.length) {
                    list.push({
                        appointmentId:
                        itemHistoryAppointmentPatientExams.appointmentId,
                        appointmentCreatedAt:
                        itemHistoryAppointmentPatientExams.appointmentCreatedAt,
                        exams
                    })
                }
            }
        )
    }

    setExamsListWithListsRightAndLeft(type: string, list: any[]) {
        const localeList: HistoryAppointmentPatientExams[] = []
        this.setExamsList(type, localeList)

        localeList.forEach((item) => {
            const left: HistoryExamItem[] = []
            const right: HistoryExamItem[] = []

            const obj = {
                appointmentId: item.appointmentId,
                appointmentCreatedAt: item.appointmentCreatedAt,
                left,
                right
            }
            item.exams.forEach((exam) => {
                if (exam.examSlug.includes('esquerdo')) {
                    obj.left.push(exam)
                } else {
                    obj.right.push(exam)
                }
            })

            list.push(obj)
        })
    }

    setHistoryBodyData() {
        const localeList: HistoryAppointmentPatientExams[] = []
        this.setExamsList('body_measures', localeList)

        localeList.forEach((item) => {
            const exams: any[] = []
            const obj = {
                appointmentId: item.appointmentId,
                appointmentCreatedAt: item.appointmentCreatedAt,
                exams
            }
            item.exams.forEach((exam) => {
                const value = this.helpers.converterSnakeToCamelCase(
                    JSON.parse(`${exam.examValue}`)
                )
                obj.exams.push(value)
            })
            this.historyBodyData.push(obj)
        })
    }

    setHistoryVisualAcuity() {
        const localeList: HistoryAppointmentPatientExams[] = []
        this.setExamsList('visual_acuity', localeList)

        localeList.forEach((item) => {
            const exams: any[] = []
            const obj = {
                appointmentId: item.appointmentId,
                appointmentCreatedAt: item.appointmentCreatedAt,
                exams
            }
            item.exams.forEach((exam) => {
                const value = this.helpers.converterSnakeToCamelCase(
                    JSON.parse(`${exam.examValue}`)
                )
                if (Object.values(value).length) {
                    obj.exams.push(value)
                }
            })
            this.historyVisualAcuityList.push(obj)
        })
    }

    setHistoryAudiometry() {
        const localeList: HistoryAppointmentPatientExams[] = []
        this.setExamsList('audiometry', localeList)

        localeList.forEach((item) => {
            const exams: any = {
                right: [],
                left: []
            }
            const obj = {
                appointmentId: item.appointmentId,
                appointmentCreatedAt: item.appointmentCreatedAt,
                exams
            }
            item.exams.forEach((exam) => {
                const examValue = JSON.parse(`${exam.examValue}`)

                examValue.forEach((value: any) => {
                    if (value.ear === 'LEFT_EAR') {
                        obj.exams.left.push(value)
                    } else {
                        obj.exams.right.push(value)
                    }
                })
            })

            obj.exams.left.sort((a: any, b: any) => {
                return a.frequency - b.frequency
            })

            obj.exams.right.sort((a: any, b: any) => {
                return a.frequency - b.frequency
            })

            const chart = this.examsChartService.generateChartAudiometry(
                obj.exams
            )

            this.historyAudiometry.push({ ...obj, chart })
        })
    }

    setHistoryDataBioMonitor(type: string, examList: HistoryExamItemWithChart) {
        const localeList: HistoryAppointmentPatientExams[] = []
        this.setExamsList(type, localeList)

        localeList.forEach((item) => {
            item.exams = item.exams.map((exam) => {
                return {
                    ...exam,
                    examValue: JSON.parse(`${exam.examValue}`)
                }
            })

            examList.list = [...examList.list, ...item.exams]
        })

        switch (type) {
            case 'temperature':
                examList.list = this.examsService.setExamTemperatureWithColor(
                    examList.list
                )
                examList.chart['temp'] =
                    this.examsChartService.generateChartTemperature(
                        examList.list
                    )
                break
            case 'glucose':
                examList.list = this.examsService.setExamGlucoseWithColor(
                    examList.list
                )
                examList.chart['glucose'] =
                    this.examsChartService.generateChartGlucose(examList.list)
                break
            case 'ecg':
                examList.list = this.examsService.setExamECG(examList.list)
                examList.list = this.examsService.setColorExamHeartRate(
                    examList.list
                )
                setTimeout(() => {
                    examList.list =
                        this.examsService.setColorRespiratoryFrequency(
                            examList.list
                        )
                }, 50)
                examList.chart['heartRate'] =
                    this.examsChartService.generateChartHeartRate(examList.list)
                examList.chart['respiratoryFrequency'] =
                    this.examsChartService.generateChartRespiratoryFrequency(
                        examList.list
                    )
                break
            case 'pressure':
                examList.list = this.examsService.setExamBloodPressureWithColor(
                    examList.list
                )
                examList.chart['bloodPressure'] =
                    this.examsChartService.generateChartBloodPressure(
                        examList.list
                    )
                break
            case 'spo2':
                examList.list = this.examsService.setExamSpo2WithColor(
                    examList.list
                )
                examList.chart['spo2'] =
                    this.examsChartService.generateChartSpo2(examList.list)
                break
        }
    }

    sortByLists() {
        this.historyAppointmentPatientExams.sort((a, b) => {
            return (
                new Date(b.createdAt || '').getTime() -
                new Date(a.createdAt || '').getTime()
            )
        })
    }

    watchWebSocket(): void {
        this.socket = this.socketIoService.getSocket()

        this.socket?.on('panel_cmd', (data, callback) => {
            try {
                this.socketIoService.onContentEvent(
                    data,
                    callback,
                    'updatePatientExamList',
                    () => {
                        if (this.patientId) {
                            this.examsService.getPatientInfoAndExams(
                                this.patientId
                            )
                        }
                    }
                )
            } catch (e) {
            }
        })
    }

    closeInternalLayer(): void {
        this.showLayer = false
        this.setItemWithExpandedImage(null)
        this.listWithExpandedImage = []
        this.titleSectionWithExpandedImage = ''
        this.directionFlagWithExpandedImage = ''
    }

    openInternalLayer(
        currentItem: any,
        list?: any,
        title?: string,
        direction?: string
    ): void {
        this.setItemWithExpandedImage(currentItem)
        this.listWithExpandedImage = list || []
        this.titleSectionWithExpandedImage = title || ''
        this.directionFlagWithExpandedImage = direction
        this.showLayer = true
    }

    setItemWithExpandedImage(item: any): void {
        this.itemWithExpandedImage = item
        this.reload()
    }

    getColorTagVisualAcuity(value: string = ''): string {
        value = value ? value.replace(',', '.').replace('%', '') : '0'
        const percent: number = parseFloat(value)

        if (percent <= 40)
            return 'emergency'
        if (percent >= 40 && percent <= 49)
            return 'very-urgent'
        if (percent >= 49 && percent <= 60)
            return 'urgent'
        if (percent >= 60 && percent <= 80)
            return 'not-urgent'
        if (percent >= 80 && percent <= 100)
            return 'little-urgent'
        return ''
    }

    resetHistoryLists(): void {
        this.resetHistoryListExamsFile()
    }

    resetHistoryDataBioMonitor(): void {
        this.historyBodyData = []
        this.historyTemperature = {
            list: [],
            chart: {}
        }
        this.historyGlucose = {
            list: [],
            chart: {}
        }
        this.historyEcg = {
            list: [],
            chart: {}
        }
        this.historyBloodPressure = {
            list: [],
            chart: {}
        }

        this.historySpO2 = {
            list: [],
            chart: {}
        }
    }

    resetHistoryListExamsFile(): void {
        this.historyComplaintList = []
        this.historyLungList = []
        this.historyHeartList = []
        this.historySkinList = []
        this.historyThroatList = []
        this.historyEyesList = []
        this.historyHeardList = []
        this.historyReports = []
        this.historyAudiometry = []
    }

    resetAll(): void {
        this.historyAppointmentPatientExams = []
        this.historyVisualAcuityList = []

        this.resetHistoryLists()
        this.resetHistoryDataBioMonitor()
    }
}

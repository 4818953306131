import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-modal-active-section',
	templateUrl: './modal-active-section.component.html',
	styleUrls: ['./modal-active-section.component.scss'],
})
export class ModalActiveSectionComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() title?: string
	@Input() desc?: string
	@Input() titleBtn?: string = 'Continuar'
	@Input() btnCancel?: boolean = true

	@Input() sectionData = {
		date: '',
		title: '',
		browser: '',
		machine: '',
	}

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	confirmation() {
		if (this.callbackConfirmation) this.callbackConfirmation()
		this.close()
	}
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import {
	DoRequestQueue,
	RequestFailureQueue,
	RequestSuccessQueue,
} from '../../state/queue/queue.action'
import { HelpersService } from '../helpers/helpers.service'
import { StatsService } from '../stats/stats.service'
import { finalize } from 'rxjs/operators'
import { AuthService } from '../auth/auth.service'

@Injectable({
	providedIn: 'root',
})
export class QueueService {
	constructor(
		private http: HttpClient,
		private store: Store<AppState>,
		private helpers: HelpersService,
		private statsService: StatsService,
		private authService: AuthService
	) {}

	getAppointmentList(
		status?: string | null,
		type: string = 'appointment',
		callback?: Function,
		page?: number,
        tags?: boolean
	): void {
		this.statsService.stats('get_appointment_list', 'start')
		this.store.dispatch(DoRequestQueue())

		const user = this.authService.getUserLocale()
		const clients = Object.keys(user.clients || {})
		let strClients = clients[0]

		if (clients.length > 1) {
			strClients = clients.join(',')
		}

		let params: any = {
			types: type,
			clients: strClients,
			per_page: 10,
		}

		if (status) {
			params.status = status
		}

		if (page) {
			params.page = page
		}

        if(tags) {
            params.tags = ['occupational_health']
        }

		this.http
			.get<any>(`${environment.api}/appointments`, { params })
			.pipe(
				finalize(() => {
					if (callback) callback()
				})
			)
			.subscribe(
				(data: any) => {
					const { total } = data
					data = data.items.map((item: any) => {
						if (item.patient) {
							item.patient =
								this.helpers.converterSnakeToCamelCase(
									item.patient
								)
						}
						if (item.client) {
							item.client =
								this.helpers.converterSnakeToCamelCase(
									item.client
								)
						}
						return this.helpers.converterSnakeToCamelCase(item)
					})
					console.log('QUEUE', data, total)
					this.store.dispatch(RequestSuccessQueue(data, total))
					this.statsService.stats('get_appointment_list', 'end')
				},
				() => {
					this.store.dispatch(RequestFailureQueue())
				}
			)
	}
}

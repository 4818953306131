<div class='wrapper-feedback custom-scroll'>
    <form
        *ngIf='form'
        autocomplete='off'
        (ngSubmit)='onSubmit()'
        [formGroup]='form'
    >
        <div class='wrapper-select'>
            <label>Selecione o código <span>CID</span> desse atendimento</label>
            <div class='content-select'>
                <div [formGroup]='form' class='wrapper-list-cids'>
                    <input type='text' [(ngModel)]='startValue'
                           (input)='changed()'
                           (change)='changed()'
                           (focus)='toggleListCids()'
                           (blur)='toggleListCids()'
                           [ngModelOptions]='{standalone: true}'
                           placeholder='Selecione o código CID'
                    />
                    <ul *ngIf='showCids' class='list-cids custom-scroll'>
                        <li *ngFor='let item of listCidFiltered' (click)='onSelectChange(item)'>
                            {{item.text}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class='content-list' *ngIf='listCidSelected.length'>
				<span
                    *ngFor='let item of listCidSelected; index as i'
                    [id]='item.id'
                >{{ item.id }} - {{ item.text }}
                    <button
                        type='button'
                        (click)='removeItemListSelected(item.id)'
                    >
						<img
                            src='/assets/images/icons/close-btn.svg'
                            alt='Remover item selecionado'
                            [title]="item.id + ' - ' + item.text"
                        /></button
                    ></span>
            </div>
        </div>
        <div class='wrapper-title'>
            <h4>Neste atendimento tivemos</h4>
        </div>
        <div class='w-100' *ngFor='let item of checks'>
            <app-checkbox-custom
                [id]='item.name'
                [name]='item.name'
                [labelName]='item.description'
                [form]='form'
                [inputModel]='item.value'
                (change)='confirmAnswerFeedback()'
            >
            </app-checkbox-custom>
        </div>
        <div class='w-100 pt-4'>
            <app-checkbox-custom
                id='some-technical-problem'
                name='someTechnicalProblem'
                labelName='Algum problema técnico'
                [form]='form'
                [inputModel]='false'
                (change)='toggleDesc()'
            >
            </app-checkbox-custom>
            <div *ngIf='showDesc'>
				<textarea
                    [formGroup]='form'
                    formControlName='description'
                    name='description'
                    id='description'
                ></textarea>
            </div>
        </div>
        <div class='mt-5'>
            <app-checkbox
                id='confirm-answers'
                name='confirmAnswers'
                [form]='form'
                [inputModel]='false'
                labelName='Confirmo minhas respostas acima'
                value='Confirmo minhas respostas acima'
                (change)='confirmAnswerFeedback()'
            >
            </app-checkbox>
            <app-checkbox
                id='not-answer'
                name='notAnswer'
                [form]='form'
                labelName='Não quero responder'
                [inputModel]='false'
                value='Não quero responder'
                (change)='notAnswerFeedback()'
            >
            </app-checkbox>
        </div>
        <div *ngIf='showError' class='w-100'>
            <p class='text-error'>
                Você precisa selecionar pelo menos uma opção do atendimento
            </p>
        </div>
        <div class='wrapper-button'>
            <p (click)='openModalDataWillBeUsed()'>
                Saiba como esses dados serão utilizados
            </p>
            <button type='submit' class='btn'>Confirmar</button>
        </div>
    </form>
</div>

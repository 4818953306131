import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Pillar } from '../../models/pillar'
import { QuizService } from '../../services/quiz/quiz.service'
import { User } from '../../models/user'
import { ToastrService } from 'ngx-toastr'
import { HelpersService } from '../../services/helpers/helpers.service'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalQuizComponent } from '../modals/modal-quiz/modal-quiz.component'

interface Quiz {
	id: string
	isApproved: boolean | null
	description: string
	type: string
	json: {
		pillars: Pillar[]
	}
	doctor?: User
}

@Component({
	selector: 'app-quiz-responses',
	templateUrl: './quiz-responses.component.html',
	styleUrls: ['./quiz-responses.component.scss'],
})
export class QuizResponsesComponent implements OnInit {
	@Input() patientId: string = ''
	@Output() isEmptyValues: EventEmitter<any> = new EventEmitter()

	private user$?: Observable<User>
	user?: User

	quizId: string = ''
	isApproved: boolean | null = null
	description: string = ''
	quizes: Quiz[] = []

	doctor?: User

	loading: boolean = false

	constructor(
		private quizService: QuizService,
		private toast: ToastrService,
		private helpers: HelpersService,
		private store: Store<AppState>,
		private modalMedicalRest: NgbModal
	) {
		this.user$ = this.store.select('user')
		this.user$.subscribe((state) => {
			this.user = state
		})
	}

	ngOnInit(): void {
		if (this.patientId) {
			this.getQuiz()
		}
	}

	conditional(question: any): boolean {
		if (!question.conditional) return false
		const listValues = question.conditional.value.split(',')
		return listValues.includes(question.value)
	}

	getQuiz() {
		const self = this
		this.quizService.getQuiz(this.patientId, {
			fnSuccess(response: any) {
				response = self.helpers.converterSnakeToCamelCase(response)
				const quizes = response.items
				self.quizes = quizes

				for (const quiz of quizes) {
					self.quizId = quiz.id
					self.isApproved = quiz.is_approved
					self.description = quiz.description
					const { pillars } = quiz.json

					if (quiz.doctor) {
						self.doctor = self.helpers.converterSnakeToCamelCase(
							quiz.doctor
						)
					}

					self.isEmptyValues.emit(pillars.length === 0)
				}
			},
			fnError(error) {
				self.isEmptyValues.emit(true)
			},
		})
	}

	approveQuiz(approve: boolean = true) {
		const self = this
		if (self.quizId) {
			if (!approve) {
				const modalRef = this.modalMedicalRest.open(ModalQuizComponent)
				modalRef.componentInstance.title =
					'Qual o motivo da reprovação? (opcional)'

				modalRef.componentInstance.emmitEvent.subscribe(
					(payload: any) => {
						const { description } = payload
						const data = {
							description,
							approve,
						}
						this.sendApproveQuiz(data)
					}
				)
			} else {
				const data = {
					description: '',
					approve,
				}
				this.sendApproveQuiz(data)
			}
		}
	}

	sendApproveQuiz(data: any) {
		const self = this
		if (self.hasPermissionCurrentDoctor()) {
			self.loading = true
			self.quizService.approveQuiz(self.quizId, data, {
				fnSuccess() {
					self.toast.success('Enviado com sucesso!')
					self.isApproved = data.approve
					self.description = data.description
				},
				fnError() {
					self.toast.error(
						'Ocorreu um erro no envio. Tente novamente.',
						'OPS!!!'
					)
				},
				fnFinalized() {
					self.loading = false
				},
			})
		} else {
			self.toast.error(
				'Você não tem permissão para executar esta ação!',
				'OPS!!!'
			)
		}
	}

	hasPermissionCurrentDoctor(): boolean {
		if (this.doctor) {
			return this.user?.id === this.doctor?.id
		}
		return true
	}
}

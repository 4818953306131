<div #top></div>
<app-loading-progress
	*ngIf="(patientExams$ | async)?.loading"
></app-loading-progress>
<app-box [arrow]="false" [titleBox]="patient?.name + ' ' + patient?.lastName">
	<div headerBox>
		<app-patient-info></app-patient-info>
	</div>
</app-box>
<app-box
	*ngIf="patientId && !isEmptyQuiz"
	[arrow]="false"
	titleBox="Questionários"
>
	<div headerBox>
		<app-quiz-responses
			(isEmptyValues)="setIsEmptyQuiz($event)"
			[patientId]="patientId"
		></app-quiz-responses>
	</div>
</app-box>
<app-box
	*ngIf="historyBodyData?.length"
	[arrow]="false"
	titleBox="Dados corporais"
>
	<div headerBox>
		<div *ngFor="let itemHistory of historyBodyData">
			<p class="date-history mt-4">
				{{ itemHistory.appointmentCreatedAt }}
			</p>

			<div *ngFor="let item of itemHistory.exams">
				<app-body-data
					[weight]="item.weight"
					[height]="item.height"
					[visceralFat]="item.visceralFat"
					[musclePercentage]="item.musclePercentage"
					[fatPercentage]="item.fatPercentage"
					[imc]="item.result"
				></app-body-data>
				<br />
			</div>
		</div>
	</div>
</app-box>
<app-box *ngIf="historyReports.length" titleBox="Prontuários" [arrow]="false">
	<div headerBox>
		<div *ngFor="let history of historyReports">
			<div *ngIf="history.report">
				<p class="date-history">{{ history.createdAt }}</p>
				<div
					class="text-history-report"
					[innerHTML]="history.report"
				></div>
				<p *ngIf="history.doctor" class="signature">
					{{ history.doctor.name }} {{ history.doctor.lastName }} -
					CRM: {{ history.doctor?.documentCrm
					}}{{ history.doctor?.crmUf }}
				</p>
			</div>
		</div>
	</div>
</app-box>
<app-box *ngIf="historyComplaintList.length" titleBox="Queixa" [arrow]="false">
	<div headerBox>
		<div
			*ngFor="let itemHistory of historyComplaintList; index as i"
			class="li-block"
		>
			<p class="date-history pt-3">
				{{ itemHistory.appointmentCreatedAt }}
			</p>
			<div *ngFor="let item of itemHistory.exams">
				<app-complaint
					[link]="item.fileUrl"
					[id]="item.id"
					[withTranscription]="i === 0"
				></app-complaint>
			</div>
		</div>
	</div>
</app-box>
<div>
	<app-box
		*ngIf="historyAudiometry.length"
		titleBox="Audiometria"
		[arrow]="false"
	>
		<div headerBox>
			<div *ngFor="let itemHistory of historyAudiometry">
				<div class="mb-5">
					<p class="date-history mb-3">
						{{ itemHistory.appointmentCreatedAt }}
					</p>
					<div class="wrapper-chart">
						<canvas
							baseChart
							[data]="itemHistory.chart"
							[options]="lineChartOptions2"
							[type]="lineChartType2"
						></canvas>
					</div>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		*ngIf="historyVisualAcuityList.length"
		titleBox="Acuidade visual"
		[arrow]="false"
	>
		<div headerBox>
			<div class="mb-3">
				<i class="text-left"
					><i>Estas informações são baseadas no Snellen chart</i></i
				>
			</div>
			<div *ngFor="let itemHistory of historyVisualAcuityList">
				<div class="mb-5">
					<p class="date-history mb-3">
						{{ itemHistory.appointmentCreatedAt }}
					</p>
					<div *ngFor="let item of itemHistory.exams">
						<div class="mb-3">
							<span>Olho esquerdo:</span>
							<span class="span-tag" *ngIf="item.left.blind">
								Não possui visão
							</span>
							<span
								[class]="
									'span-tag ' +
									getColorTagVisualAcuity(
										item.left?.percentage
									)
								"
								*ngIf="!item.left.blind"
								>{{
									item.left?.last_read_row ||
										'Não possui dados'
								}}</span
							>
						</div>
						<div>
							<span>Olho direito:</span>
							<span class="span-tag" *ngIf="item.right.blind">
								Não possui visão
							</span>
							<span
								[class]="
									'span-tag ' +
									getColorTagVisualAcuity(
										item.right?.percentage
									)
								"
								*ngIf="!item.right.blind"
								>{{
									item.right?.last_read_row ||
										'Não possui dados'
								}}</span
							>
						</div>
						<hr />
					</div>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		*ngIf="historyTemperature.list.length"
		titleBox="Temperatura"
		[arrow]="false"
	>
		<div headerBox>
			<div
				class="d-flex justify-content-between align-items-start grafic"
			>
				<div class="wrapper-info">
					<p *ngFor="let itemHistory of historyTemperature.list">
						<span [class]="itemHistory?.color"
							>{{ itemHistory.examValue.temp.toFixed(2) }}°C</span
						>
						<i>{{
							helpers.converterTsInDate(itemHistory.createdAt)
						}}</i>
					</p>
				</div>
				<div class="wrapper-chart">
					<canvas
						baseChart
						[data]="historyTemperature.chart.temp"
						[options]="lineChartOptions"
						[type]="lineChartType"
					></canvas>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		*ngIf="historyBloodPressure.list?.length"
		titleBox="Pressão arterial"
		[arrow]="false"
	>
		<div headerBox>
			<div
				class="d-flex justify-content-between align-items-start grafic"
			>
				<div class="wrapper-info">
					<div class="info-head">
						<p>PAS</p>
						<p>PAD</p>
					</div>
					<div *ngFor="let itemHistory of historyBloodPressure.list">
						<div class="">
							<p class="multiples">
								<span [class]="itemHistory?.color">{{
									itemHistory.examValue.sbp
								}}</span>
								<span [class]="itemHistory?.color">{{
									itemHistory.examValue.dbp
								}}</span>
								<i>{{
									helpers.converterTsInDate(
										itemHistory.createdAt
									)
								}}</i>
							</p>
						</div>
					</div>
				</div>
				<div class="wrapper-chart">
					<canvas
						baseChart
						[data]="historyBloodPressure.chart.bloodPressure"
						[options]="lineChartOptions"
						[type]="lineChartType"
					></canvas>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		*ngIf="historyEcg.list?.length"
		titleBox="Frequência cardíaca"
		[arrow]="false"
	>
		<div headerBox>
			<div
				class="d-flex justify-content-between align-items-start grafic"
			>
				<div>
					<div
						class="wrapper-box-content"
						*ngFor="let itemHistory of historyEcg.list"
					>
						<div class="wrapper-info">
							<p>
								<span
									[class]="itemHistory.colors?.colorHeartRate"
									>{{ itemHistory.examValue.hr }} bpm</span
								>
								<i>{{
									helpers.converterTsInDate(
										itemHistory.createdAt
									)
								}}</i>
							</p>
						</div>
					</div>
				</div>
				<div class="wrapper-chart">
					<canvas
						baseChart
						[data]="historyEcg.chart.heartRate"
						[options]="lineChartOptions"
						[type]="lineChartType"
					></canvas>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		titleBox="Frequência respiratória"
		*ngIf="historyEcg.list?.length"
		[arrow]="false"
	>
		<div headerBox>
			<div
				class="d-flex justify-content-between align-items-start grafic"
			>
				<div>
					<div
						class="wrapper-box-content"
						*ngFor="let itemHistory of historyEcg.list"
					>
						<div class="wrapper-info">
							<p>
								<span
									[class]="
										itemHistory.colors
											?.colorRespiratoryFrequency
									"
									>{{ itemHistory.examValue.rr }} bpm</span
								>
								<i>{{
									helpers.converterTsInDate(
										itemHistory.createdAt
									)
								}}</i>
							</p>
						</div>
					</div>
				</div>
				<div class="wrapper-chart">
					<canvas
						baseChart
						[data]="historyEcg.chart.respiratoryFrequency"
						[options]="lineChartOptions"
						[type]="lineChartType"
					></canvas>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		titleBox="Saturação periférica de oxigênio"
		*ngIf="historySpO2.list?.length"
		[arrow]="false"
	>
		<div headerBox>
			<div
				class="d-flex justify-content-between align-items-start grafic"
			>
				<div>
					<div
						class="wrapper-box-content"
						*ngFor="let itemHistory of historySpO2.list"
					>
						<div class="wrapper-info">
							<p class="d-flex align-items-center">
								<span class="ml-2" [class]="itemHistory?.color"
									>{{ itemHistory.examValue.value }} %</span
								>
								<i>{{
									helpers.converterTsInDate(
										itemHistory.createdAt
									)
								}}</i>
							</p>
						</div>
					</div>
				</div>
				<div class="wrapper-chart">
					<canvas
						baseChart
						[data]="historySpO2.chart.spo2"
						[options]="lineChartOptions"
						[type]="lineChartType"
					></canvas>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		titleBox="Glicose"
		*ngIf="historyGlucose.list?.length"
		[arrow]="false"
	>
		<div headerBox>
			<div
				class="d-flex justify-content-between align-items-start grafic"
			>
				<div>
					<div
						class="wrapper-box-content"
						*ngFor="let itemHistory of historyGlucose.list"
					>
						<div class="wrapper-info">
							<p>
								<span
									>{{
										itemHistory.examValue.value.toFixed(3)
									}}
									MMG/DL</span
								>
								<span
									*ngIf="itemHistory.examValue.lastMealTime"
									class="fasting-datetime"
									>Jejum:
									<b
										>{{
											itemHistory.examValue.lastMealTime
												.hour
										}}
										hora(s)</b
									></span
								>
								<i>{{
									helpers.converterTsInDate(
										itemHistory.createdAt
									)
								}}</i>
							</p>
						</div>
					</div>
				</div>
				<div class="wrapper-chart">
					<canvas
						baseChart
						[data]="historyGlucose.chart.glucose"
						[options]="lineChartOptions"
						[type]="lineChartType"
					></canvas>
				</div>
			</div>
		</div>
	</app-box>
	<app-box
		titleBox="Eletrocardiograma"
		*ngIf="historyEcg.list?.length"
		[arrow]="false"
	>
		<div
			headerBox
			class="wrapper-box-content odd"
			*ngFor="let itemHistory of historyEcg.list"
		>
			<i>{{ helpers.converterTsInDate(itemHistory.createdAt) }}</i>
			<app-ecg
				*ngIf="
					itemHistory.examValue.wave ||
					(itemHistory.examValue.waveList &&
						itemHistory.examValue.waveList.length)
				"
				[optionsEcg]="itemHistory.examValue"
			></app-ecg>
		</div>
	</app-box>
</div>
<app-box *ngIf="historyHeardList.length" titleBox="Ouvidos" [arrow]="false">
	<div *ngIf="historyHeardList.length" headerBox>
		<div *ngFor="let itemHistory of historyHeardList">
			<div
				*ngIf="itemHistory.left.length || itemHistory.right.length"
				class="wrapper-box"
			>
				<p class="date-history">
					{{ itemHistory.appointmentCreatedAt }}
				</p>
				<div *ngIf="itemHistory.left.length" class="mb-3">
					<h5>
						<span>E</span>Esquerdo
						<i>{{ itemHistory.left.length }}</i>
					</h5>

					<div class="row">
						<div
							class="col-sm-6 mb-4"
							[class]="
								setExamCardWidth
									? 'col-lg-6 col-xl-4'
									: 'col-xl-3'
							"
							*ngFor="let item of itemHistory.left"
						>
							<app-imaging-exams
								[id]="item.id"
								[pathExams]="item.fileUrlThumbnail"
								[title]="
									helpers.transformString(
										item.examValue,
										'Ouvido esquerdo'
									)
								"
								[description]="
									helpers.transformString(
										item.examValue,
										'Ouvido esquerdo'
									)
								"
								(expandImage)="
									openInternalLayer(
										item,
										itemHistory.left,
										'Ouvidos',
										'E'
									)
								"
							></app-imaging-exams>
						</div>
					</div>
				</div>
				<div *ngIf="itemHistory.right.length">
					<h5>
						<span>D</span>Direito
						<i>{{ itemHistory.right.length }}</i>
					</h5>

					<div class="row">
						<div
							class="col-sm-6 mb-4"
							[class]="
								setExamCardWidth
									? 'col-lg-6 col-xl-4'
									: 'col-xl-3'
							"
							*ngFor="let item of itemHistory.right"
						>
							<app-imaging-exams
								[id]="item.id"
								[pathExams]="item.fileUrlThumbnail"
								[title]="
									helpers.transformString(
										item.examValue,
										'Ouvido direito'
									)
								"
								[description]="
									helpers.transformString(
										item.examValue,
										'Ouvido direito'
									)
								"
								(expandImage)="
									openInternalLayer(
										item,
										itemHistory.right,
										'Ouvidos',
										'D'
									)
								"
							></app-imaging-exams>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-box>
<app-box
	*ngIf="historySkinList.length"
	titleBox="Dermatoscópio"
	[arrow]="false"
>
	<div *ngIf="historySkinList.length" headerBox>
		<div class="wrapper-box mt-3">
			<div *ngFor="let itemHistory of historySkinList">
				<div *ngIf="itemHistory.exams.length">
					<p class="date-history">
						{{ itemHistory.appointmentCreatedAt }}
					</p>
					<div class="row">
						<div
							class="col-sm-6 mb-4"
							[class]="
								setExamCardWidth
									? 'col-lg-6 col-xl-4'
									: 'col-xl-3'
							"
							*ngFor="let item of itemHistory.exams"
						>
							<app-imaging-exams
								[id]="item.id"
								[pathExams]="item.fileUrlThumbnail"
								[title]="
									helpers.transformString(
										item.examValue,
										'Dermatoscópio'
									)
								"
								[description]="
									helpers.transformString(
										item.examValue,
										'Dermatoscópio'
									)
								"
								(expandImage)="
									openInternalLayer(
										item,
										itemHistory.exams,
										'Dermatoscópio'
									)
								"
							></app-imaging-exams>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-box>
<app-box *ngIf="historyThroatList.length" titleBox="Garganta" [arrow]="false">
	<div *ngIf="historyThroatList.length" headerBox>
		<div class="wrapper-box mt-3">
			<div *ngFor="let itemHistory of historyThroatList">
				<div *ngIf="itemHistory.exams.length">
					<p class="date-history">
						{{ itemHistory.appointmentCreatedAt }}
					</p>
					<div class="row">
						<div
							class="col-sm-6 mb-4"
							[class]="
								setExamCardWidth
									? 'col-lg-6 col-xl-4'
									: 'col-xl-3'
							"
							*ngFor="let item of itemHistory.exams"
						>
							<app-imaging-exams
								[id]="item.id"
								[pathExams]="item.fileUrlThumbnail"
								[title]="
									helpers.transformString(
										item.examValue,
										'Garganta'
									)
								"
								[description]="
									helpers.transformString(
										item.examValue,
										'Garganta'
									)
								"
								(expandImage)="
									openInternalLayer(
										item,
										itemHistory.exams,
										'Garganta'
									)
								"
							></app-imaging-exams>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-box>
<app-box *ngIf="historyEyesList.length" titleBox="Olhos" [arrow]="false">
	<div *ngIf="historyEyesList.length" headerBox>
		<div *ngFor="let itemHistory of historyEyesList">
			<div
				*ngIf="itemHistory.left.length || itemHistory.right.length"
				class="wrapper-box"
			>
				<p class="date-history">
					{{ itemHistory.appointmentCreatedAt }}
				</p>
				<div *ngIf="itemHistory.left.length" class="mb-3">
					<h5>
						<span>E</span>Esquerdo
						<i>{{ itemHistory.left.length }}</i>
					</h5>

					<div class="row">
						<div
							class="col-sm-6 mb-4"
							[class]="
								setExamCardWidth
									? 'col-lg-6 col-xl-4'
									: 'col-xl-3'
							"
							*ngFor="let item of itemHistory.left"
						>
							<app-imaging-exams
								[id]="item.id"
								[pathExams]="item.fileUrlThumbnail"
								[title]="
									helpers.transformString(
										item.examValue,
										'Olho esquerdo'
									)
								"
								[description]="
									helpers.transformString(
										item.examValue,
										'Olho esquerdo'
									)
								"
								(expandImage)="
									openInternalLayer(
										item,
										itemHistory.left,
										'Olhos',
										'E'
									)
								"
							></app-imaging-exams>
						</div>
					</div>
				</div>
				<div *ngIf="itemHistory.right.length">
					<h5>
						<span>D</span>Direito
						<i>{{ itemHistory.right.length }}</i>
					</h5>

					<div class="row">
						<div
							class="col-sm-6 mb-4"
							[class]="
								setExamCardWidth
									? 'col-lg-6 col-xl-4'
									: 'col-xl-3'
							"
							*ngFor="let item of itemHistory.right"
						>
							<app-imaging-exams
								[id]="item.id"
								[pathExams]="item.fileUrlThumbnail"
								[title]="
									helpers.transformString(
										item.examValue,
										'Olho direito'
									)
								"
								[description]="
									helpers.transformString(
										item.examValue,
										'Olho direito'
									)
								"
								(expandImage)="
									openInternalLayer(
										item,
										itemHistory.right,
										'Olhos',
										'D'
									)
								"
							></app-imaging-exams>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-box>
<app-box
	*ngIf="historyHeartList.length"
	titleBox="Ausculta cardíaca"
	[arrow]="false"
>
	<div headerBox>
		<div class="wrapper-box mt-3">
			<div *ngFor="let itemHistory of historyHeartList">
				<div *ngIf="itemHistory.exams.length">
					<p class="date-history pt-3">
						{{ itemHistory.appointmentCreatedAt }}
					</p>
					<app-audio
						*ngFor="let audio of itemHistory.exams"
						[src]="audio.fileUrl"
						[title]="
							helpers.transformString(
								audio.examValue,
								'Ausculta cardíaca'
							)
						"
					></app-audio>
				</div>
			</div>
		</div>
	</div>
</app-box>
<app-box
	*ngIf="historyLungList.length"
	titleBox="Ausculta pulmonar"
	[arrow]="false"
>
	<div headerBox>
		<div class="wrapper-box mt-3">
			<div *ngFor="let itemHistory of historyLungList">
				<div *ngIf="itemHistory.exams.length">
					<p class="date-history pt-3">
						{{ itemHistory.appointmentCreatedAt }}
					</p>
					<app-audio
						*ngFor="let audio of itemHistory.exams"
						[src]="audio.fileUrl"
						[title]="
							helpers.transformString(
								audio.examValue,
								'Ausculta pulmonar'
							)
						"
					></app-audio>
				</div>
			</div>
		</div>
	</div>
</app-box>
<div class="back-top">
	<button type="button" (click)="helpers.scrollToElement(top)">
		Voltar ao topo
	</button>
</div>

<div *ngIf="itemWithExpandedImage">
	<app-workbench-layer [open]="showLayer" (emmitEvent)="closeInternalLayer()">
		<div workbenchHeader>
			<p>Retornar para Exame</p>
		</div>
		<div workbenchContent class="pt-4">
			<div #imageSelected></div>
			<app-box
				[titleBox]="titleSectionWithExpandedImage"
				[arrow]="false"
				[qtdFiles]="listWithExpandedImage?.length"
			>
				<div headerBox>
					<div class="highlighted">
						<app-imaging-exams
							*ngIf="_reload"
							[id]="itemWithExpandedImage.id"
							[pathExams]="itemWithExpandedImage.fileUrl"
							[title]="
								helpers.transformString(
									itemWithExpandedImage.examValue,
									titleSectionWithExpandedImage
								)
							"
							[description]="
								helpers.transformString(
									itemWithExpandedImage.examValue,
									titleSectionWithExpandedImage
								)
							"
							[isExpandImage]="false"
						></app-imaging-exams>
					</div>
					<div class="wrapper-box mt-3">
						<h5 *ngIf="directionFlagWithExpandedImage">
							<span>{{ directionFlagWithExpandedImage }}</span
							>{{
								directionFlagWithExpandedImage === 'D'
									? 'Direito'
									: 'Esquerdo'
							}}
						</h5>
						<div class="row">
							<div
								class="col-sm-6 mb-4 col-lg-3"
								*ngFor="let item of listWithExpandedImage"
							>
								<app-imaging-exams
									[active]="
										itemWithExpandedImage.id === item.id
									"
									[id]="item.id"
									[pathExams]="item.fileUrlThumbnail"
									[title]="
										helpers.transformString(
											item.examValue,
											titleSectionWithExpandedImage
										)
									"
									[description]="
										helpers.transformString(
											item.examValue,
											titleSectionWithExpandedImage
										)
									"
									(expandImage)="
										setItemWithExpandedImage(item);
										helpers.scrollToElement(imageSelected)
									"
								></app-imaging-exams>
							</div>
						</div>
					</div>
				</div>
			</app-box>
		</div>
	</app-workbench-layer>
</div>

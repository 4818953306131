import { Component, OnInit } from '@angular/core'
import { StatsService } from '../../services/stats/stats.service'

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
	listFaq: any[] = []
	constructor(private statsService: StatsService) {
		this.listFaq = [
			{
				title: 'Como acessar minha conta e alterar meus dados?',
				description:
					'No menu principal selecionar a opção "minha conta"',
				video: '../../../assets/videos/minha-conta.mp4',
			},
			{
				title: 'Como faço para sair da minha conta atual na aplicação?',
				description: 'No menu principal selecione a opção "Sair"',
				video: '../../../assets/videos/sair-da-conta.mp4',
			},
			{
				title: 'Como acessar as consultas que eu ja realizei?',
				description:
					'No menu principal selecionar a opção "Lista de pacientes", estando na página vá até "consultas realizadas"',
				video: '../../../assets/videos/consultas-realizadas.mp4',
			},
			{
				title: 'Eu posso continuar um atendimento e como faz?',
				description:
					'Sim, se você estiver com um atendimento em andamento irá ficar um popup flutuando no parte superior da tela, clicando em "Continuar atendimento" você será redirecionado para a tela de atendimento',
				video: '../../../assets/videos/continuar-atendimento.mp4',
			},
			{
				title: 'Como liberar o paciente?',
				description:
					'Se você estiver com um atendimento em andamento irá ficar um popup flutuando no parte superior da tela, clique em "Continuar atendimento", você será redirecionado para a tela de atendimento, clique no botão "Liberar paciente" que se encontra abaixo da video chamada',
				video: '../../../assets/videos/liberar-paciente.mov',
			},
			{
				title: 'Como prescrever uma receita MEMED durante a consulta?',
				description:
					'No menu principal, durante uma consulta, selecione a opção "Prescrição MEMED". Abrirá um módulo da própria MEMED.',
				video: '../../../assets/videos/meme-presc.mp4',
			},
			{
				title: 'Como prescrever atestado MEMED durante a consulta?',
				description:
					'No menu principal, durante uma consulta, selecione a opção "Atestado MEMED". Abrirá uma popup para selecionar o número de dias e o CID e depois abrirá um módulo da própria MEMED para finalizar a prescrição.',
				video: '../../../assets/videos/meme-atestado.mp4',
			},
			{
				title: 'Qual versão do sistema atual?',
				description:
					'No canto inferior esquerdo você escontrará a versão atual do sistema"',
				video: '../../../assets/videos/versao.mp4',
			},
		]
	}

	ngOnInit(): void {
		this.statsService.stats('init_faq', 'start')
	}

	ngOnDestroy() {
		this.statsService.stats('init_faq', 'end')
	}
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import { AppointmentsService } from '../appointments/appointments.service'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { environment } from '../../../environments/environment'
import {
	DoRequestPatientExams,
	RequestFailurePatientExams,
	RequestSuccessPatientExams,
	RequestSuccessPatientReport,
} from '../../state/patient-exams/patient-exams.action'
import { Callbacks } from '../../models/callbacks'
import { AddPatient, ClearPatient } from '../../state/patient/patient.action'
import { AuthService } from '../auth/auth.service'
import { HistoryExamItem } from '../../models/patient-exams'
import { finalize } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class ExamsService {
	patientAge: number = 0

	constructor(
		private http: HttpClient,
		private helpers: HelpersService,
		private appointmentsService: AppointmentsService,
		private authService: AuthService,
		private store: Store<AppState>
	) {}

	getPatientInfoAndExams(
		patientId: string,
		isPageResultExams: boolean = false,
        callbacks?: Callbacks
	): void {
		this.store.dispatch(ClearPatient())
		this.store.dispatch(DoRequestPatientExams())

		let headers: any = {}
		let url = `${environment.api}/patients/${patientId}/history`

		if (isPageResultExams) {
			url = `${environment.api}/embeds/patients/${patientId}/history`
			const identifier = localStorage.getItem('identifier') || ''
			if (identifier) {
				headers = {
					identifier: identifier,
				}
			}
		}

		this.http
			.get<any>(url, {
				headers,
			})
            .pipe(finalize(()=> {
                if(callbacks?.fnFinalized) {
                    callbacks?.fnFinalized()
                }
            }))
			.subscribe(
				(response: any) => {
					let { exams, report, patient } = response

					exams = exams.map((item: any) => ({
						...this.helpers.converterSnakeToCamelCase(item),
						exams: item.exams.map((exam: any) => ({
							...this.helpers.converterSnakeToCamelCase(exam),
						})),
					}))
					report = report.map((report: any) => {
						report = this.helpers.converterSnakeToCamelCase(report)
						if (report.doctor) {
							report.doctor =
								this.helpers.converterSnakeToCamelCase(
									report.doctor
								)
						}
						return report
					})
					patient = this.helpers.converterSnakeToCamelCase(patient)

					this.store.dispatch(RequestSuccessPatientExams(exams))
					this.store.dispatch(RequestSuccessPatientReport(report))
					this.store.dispatch(
						AddPatient({ ...patient, id: patientId })
					)

					this.patientAge = patient.age

					console.log('GET PATIENT INFO AND EXAMS SERVICE', response)
                    if(callbacks?.fnSuccess) {
                        callbacks?.fnSuccess(response)
                    }
				},
				(err) => {
					this.store.dispatch(RequestFailurePatientExams())
                    if(callbacks?.fnError) {
                        callbacks?.fnError(err)
                    }
					console.warn(err)
				}
			)
	}

	getFile(path: string) {
		const token = this.authService.getToken()
		const options = {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		}
		return fetch(path, options).then((res) => res.blob())
	}

	getAudioTranscription(audioId: string, callback: Callbacks): void {
		this.http
			.get<any>(`${environment.api}/exams/${audioId}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					console.log('GET AUDIO TRANSCRIPTION SERVICE', data)
					callback.fnSuccess(data)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}

	geFileLinkFinal(url: string) {
		return this.http.get<any>(url)
	}

	setExamTemperatureWithColor(list: HistoryExamItem[]): HistoryExamItem[] {
		return list.map((item) => {
			const value = (<any>item.examValue).bt
			let color: string = ''

			if (value.temp <= 37.2) {
				color = 'little-urgent'
			} else if (value.temp > 37.2 && value.temp <= 38.5) {
				color = 'urgent'
			} else {
				color = 'emergency'
			}
			return {
				...item,
				examValue: value,
				color,
			}
		})
	}

	setExamGlucoseWithColor(list: HistoryExamItem[]): HistoryExamItem[] {
		return list.map((item) => {
			const value = this.helpers.converterSnakeToCamelCase((<any>item.examValue).bg)
			return {
				...item,
				examValue: value,
			}
		})
	}

	setExamEcgWithColor() {}

	setExamSpo2WithColor(list: HistoryExamItem[]): HistoryExamItem[] {
		return list.map((item) => {
			const value = (<any>item.examValue).spo2
			let color: string = ''

			if (value.value >= 96) {
				color = 'little-urgent'
			} else if (value.value >= 94 && value.value < 96) {
				color = 'urgent'
			} else {
				color = 'emergency'
			}
			return {
				...item,
				examValue: value,
				color,
			}
		})
	}

	setExamBloodPressureWithColor(list: HistoryExamItem[]): HistoryExamItem[] {
		return list.map((item) => {
			const value = (<any>item.examValue).bp
			let color: string = ''

			if (
				value.sbp > 10 &&
				value.sbp < 120 &&
				value.dbp > 10 &&
				value.dbp < 80
			) {
				color = 'little-urgent'
			} else if (
				value.sbp >= 120 &&
				value.sbp <= 129 &&
				value.dbp > 10 &&
				value.dbp < 80
			) {
				color = 'urgent'
			} else if (
				(value.sbp >= 130 && value.sbp < 140) ||
				(value.dbp >= 80 && value.dbp < 90)
			) {
				color = 'very-urgent'
			} else if (value.sbp >= 140 || value.dbp >= 90) {
				color = 'emergency'
			} else {
				color = ''
			}
			return {
				...item,
				examValue: value,
				color,
			}
		})
	}

	setExamECG(list: HistoryExamItem[]): HistoryExamItem[] {
		return list.map((item) => {
			const value = (<any>item.examValue).ecg
			return {
				...item,
				examValue: value,
			}
		})
	}

	setColorExamHeartRate(list: HistoryExamItem[]): HistoryExamItem[] {
		return list.map((item) => {
			const { examValue } = item
			let colorHeartRate: string = ''

			if (examValue.hr >= 60 && examValue.hr <= 100) {
				colorHeartRate = 'little-urgent'
			} else if (examValue.hr > 50 && examValue.hr < 60) {
				colorHeartRate = 'very-urgent'
			} else if (examValue.hr > 100) {
				colorHeartRate = 'urgent'
			}

			return {
				...item,
				colors: { ...item.colors, colorHeartRate },
			}
		})
	}

	setColorRespiratoryFrequency(list: HistoryExamItem[]): HistoryExamItem[] {
		return list.map((item) => {
			const { examValue } = item
			let colorRespiratoryFrequency: string = ''

			if (this.patientAge >= 13 && this.patientAge <= 18) {
				if (examValue.rr >= 12 && examValue.rr <= 16) {
					colorRespiratoryFrequency = 'little-urgent'
				} else if (examValue.rr >= 20) {
					colorRespiratoryFrequency = 'very-urgent'
				} else if (examValue.rr < 12) {
					colorRespiratoryFrequency = 'urgent'
				}
			} else if (this.patientAge >= 18 && this.patientAge <= 40) {
				if (examValue.rr >= 12 && examValue.rr <= 20) {
					colorRespiratoryFrequency = 'little-urgent'
				} else if (examValue.rr >= 25) {
					colorRespiratoryFrequency = 'very-urgent'
				} else if (examValue.rr < 12) {
					colorRespiratoryFrequency = 'urgent'
				}
			} else if (this.patientAge >= 40) {
				if (examValue.rr >= 16 && examValue.rr <= 25) {
					colorRespiratoryFrequency = 'little-urgent'
				} else if (examValue.rr >= 25) {
					colorRespiratoryFrequency = 'very-urgent'
				} else if (examValue.rr < 12) {
					colorRespiratoryFrequency = 'urgent'
				}
			}

			return {
				...item,
				colors: { ...item.colors, colorRespiratoryFrequency },
			}
		})

		//    ref: https://telemedicinamorsch.com.br/blog/frequencia-respiratoria-normal
	}
}

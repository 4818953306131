<div class="control">
	<div class="form-group">
		<label
			[for]="'i-' + id"
			[class.default]="!labelName"
			[formGroup]="form"
		>
			<span *ngIf="labelName" class="custom-label control-label">{{
				labelName
			}}</span>
			<input
				[id]="'i-' + id"
				[type]="!showPass ? typeInput : 'text'"
				[attr.name]="name"
				[formControlName]="name"
				[placeholder]="placeholder"
				[setValue]="inputModel"
				[mask]="inputMask"
				class="custom-input"
				maxlength="50"
			/>
			<button
				*ngIf="typeInput == 'password'"
				(click)="toggleInputPass()"
				type="button"
				class="show-pass"
				[class.active]="showPass"
				data-input="name"
			>
				<figure>
					<img src="../../../../assets/images/eye.svg" alt="" />
				</figure>
			</button>
		</label>
		<span *ngIf="callout" class="callout">{{ callout }}</span>
		<span
			*ngIf="
				form.controls[name || '']?.invalid &&
				(form.controls[name || '']?.touched ||
					form.controls[name || '']?.dirty)
			"
			class="control-error"
			[setFormControlError]="{
				objError: form.controls[name || '']?.errors,
				control: labelName
			}"
		>
		</span>
		<ng-content></ng-content>
	</div>
</div>

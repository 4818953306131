import { ActionReducerMap } from '@ngrx/store'
import { userReducer } from './user/user.reducer'
import { patientReducer } from './patient/patient.reducer'
import { loadingReducer } from './loading/loading.reducer'
import { queueReducer } from './queue/queue.reducer'
import { examsListReducer } from './exams-list/exams-list.reducer'
import { appointmentsFinalizedListReducer } from './appointments-finalized-list/appointments-finalized-list.reducer'
import { appointmentReducer } from './appointment/appointment.reducer'
import { patientExamsReducer } from './patient-exams/patient-exams.reducer'
import { meMedReducer } from './me-med/me-med.reducer'

export const stateReducers: ActionReducerMap<any> = {
	user: userReducer,
	patient: patientReducer,
	loading: loadingReducer,
	queue: queueReducer,
	examsList: examsListReducer,
    appointmentsFinalizedList: appointmentsFinalizedListReducer,
	appointment: appointmentReducer,
	patientExams: patientExamsReducer,
	meMed: meMedReducer,
}

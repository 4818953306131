import { AbstractControl, ValidationErrors } from '@angular/forms'

export class GenericValidatorService {
	constructor() {}

	static isValidCpf(control: AbstractControl): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (value === undefined) return { cpfInvalid: true }
			const cpf = value.replace(/[^\d]+/g, '')
			let Soma
			let Resto
			Soma = 0
			if (
				cpf.length !== 11 ||
				cpf === '00000000000' ||
				cpf === '11111111111' ||
				cpf === '22222222222' ||
				cpf === '33333333333' ||
				cpf === '44444444444' ||
				cpf === '55555555555' ||
				cpf === '66666666666' ||
				cpf === '77777777777' ||
				cpf === '88888888888' ||
				cpf === '99999999999'
			)
				return { cpfInvalid: true }

			for (let i = 1; i <= 9; i++)
				Soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
			Resto = (Soma * 10) % 11

			if (Resto === 10 || Resto === 11) Resto = 0

			if (Resto !== parseInt(cpf.substring(9, 10)))
				return { cpfInvalid: true }

			Soma = 0

			for (let i = 1; i <= 10; i++)
				Soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
			Resto = (Soma * 10) % 11

			if (Resto === 10 || Resto === 11) Resto = 0

			if (Resto !== parseInt(cpf.substring(10, 11)))
				return { cpfInvalid: true }
		}
		return null
	}

	static checkPasswords(control: AbstractControl): ValidationErrors | null {
		return !!control.parent &&
			!!control.parent.value &&
			// @ts-ignore
			control.value === control?.parent?.controls['password']?.value
			? null
			: { notSamePassword: false }
	}
}

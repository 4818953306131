import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-sidebar-item',
	templateUrl: './sidebar-item.component.html',
	styleUrls: ['./sidebar-item.component.scss'],
})
export class SidebarItemComponent implements OnInit {
	@Input() image: string = ''
	@Input() imageActive: string = ''
	@Input() title: string = ''
	@Input() link?: string = ''
	@Input() isButton?: boolean = false
	@Input() disabled?: boolean = false
	@Input() isTargetBlank?: boolean = false
	@Input() handler?: Function

    currentRoute?: string

	constructor() {
    }

	ngOnInit(): void {}

	fnHandler(): void {
		if (this.handler) this.handler()
	}
}

import { ActionLoadingTypes } from './loading.action'
import { ActionModel } from '../action'

export function loadingReducer(state: boolean = false, action: ActionModel) {
	switch (action.type) {
		case ActionLoadingTypes.ShowLoading: {
			state = true
			return state
		}

		case ActionLoadingTypes.HiddenLoading: {
			state = false
			return state
		}

		default:
			return state
	}
}

<div class="modal-body">
    <h5 *ngIf='title'>{{title}}</h5>
    <p *ngIf='desc'>{{desc}}</p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn outline"
        (click)="close()"
    >
        Cancelar
    </button>
    <button type="button" class="btn btn-xs" (click)="confirmation()">
        Confirmar
    </button>
</div>

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import { environment } from '../../../environments/environment'
import { Socket } from 'socket.io-client/build/esm/socket'
import * as io from 'socket.io-client'
import { User } from '../../models/user'
import { Client } from '../../models/client'
import { Appointment } from '../../models/appointment'
import { ItemQueue } from '../../models/queue'

@Injectable({
    providedIn: 'root'
})
export class SocketIoService {
    private socket?: Socket
    private user: User

    constructor(private http: HttpClient, private helpers: HelpersService) {
        const token = localStorage.getItem('token') || ''
        const { user } = this.helpers.decodePayloadJWT(token)
        this.user = user

        this.socket = this.getSocket()
    }

    getSocket(): Socket | undefined {
        const token = localStorage.getItem('token') || ''
        if (!this.socket && token) {
            const { user } = this.helpers.decodePayloadJWT(token)

            this.socket = io.connect(environment.ws, {
                auth: {
                    access_token: token,
                    entity_type: 'panel',
                    panel: { id: user.id, name: user.name },
                    client: { id: null, name: null }
                }
            })
        }

        //@ts-ignore
        window.socket = this.socket
        return this.socket
    }

    getConsumersOnline(data: any): string[] {
        let list: string[] = []
        data.consumers.forEach((item: any) => {
            if (item.cabin) {
                list.push(item.cabin.id)
            }
        })
        return list
    }

    emitEventSocket(action: string, body?: any, appointment?: Appointment | ItemQueue) {
        const clients: any = this.user?.clients
        let currentClient: Client | undefined = undefined

        const clientId = Object.keys(clients).find((id) => {
            return id === appointment?.clientId
        })

        if (clientId) {
            currentClient = clients[clientId]
        }

        this.socket?.emit(
            'panel_cmd',
            {
                action: action,
                appointmentId: appointment?.id || null,
                patientId: appointment?.patient?.id || null,
                client: {
                    id: clientId || null,
                    name: currentClient?.name || null
                },
                content: {
                    ...body
                }
            },
            (response: any) => {
                console.log(`response: ${JSON.stringify(response)}`)
            }
        )
    }

    onContentEvent(data: any, callback: Function, action: string, handler: Function) {
        const token = localStorage.getItem('token') || ''
        const { user } = this.helpers.decodePayloadJWT(token)
        let { clients } = user

        clients = Object.keys(clients || {})

        if (clients.includes(data.client.id) && action === data.action) {
            handler(data)
            if (callback) callback({ status: 'ok' })
        }
    }
}

<header [class]='(showMenu ? "open" : "")'>
    <aside>
        <div class='logo'>
            <img class='mobile' src='/assets/images/logo-small.svg' alt='logo' />
            <img class='desktop' src='/assets/images/logo.svg' alt='logo' />
        </div>
        <app-sidebar></app-sidebar>
        <button type='button'
                class='close'
                (click)='toggleMenu()'
        >
            <img src='/assets/images/close-menu.svg' />
        </button>
        <app-version></app-version>
    </aside>
    <div class='wrapper-header'>
        <div>
            <button type='button'
                    class='menu'
                    (click)='toggleMenu()'
            >
                <img src='/assets/images/sidebar/hamburger.svg' alt='Menu' />
            </button>
        </div>

        <div class='wrapper-title'>
            <h2 class='title-page'>{{titlePage}}</h2>
        </div>

        <div class='wrapper-duration'>
            <span *ngIf='!showLinkBackAppointment' class='alert-primary'>
                Duração do atendimento: {{ duration }}
            </span>
        </div>
    </div>
    <app-alert
        typeAlert='warning'
        *ngIf='showAlertAppointmentStarted && showLinkBackAppointment'
    >
        <p title>
			<span
            >Você está atendendo
				<strong>{{ (patient$ | async)?.name }} {{ (patient$ | async)?.lastName }}</strong>
				há {{ duration }}</span
            >
            <a *ngIf='showLinkBackAppointment' routerLink='/painel/consulta'
            >Continuar atendimento</a
            >
        </p>
    </app-alert>
</header>

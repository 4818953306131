import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'

@Component({
	selector: 'app-modal-issue-quiz',
	templateUrl: './modal-quiz.component.html',
	styleUrls: ['./modal-quiz.component.scss'],
})
export class ModalQuizComponent implements OnInit {
	@Input() callbackCreation?: Function
	@Input() title?: string
	@Output() emmitEvent: EventEmitter<any> = new EventEmitter()
	form: FormGroup

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder,
		private toast: ToastrService
	) {
		this.form = this.formBuilder.group({
			description: ['', [Validators.required]],
		})
	}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	onSubmit(): void {
		this.emmitEvent.emit(this.form.value)
		this.close()
		// if (this.form.valid) {
		// } else {
		// 	this.toast.error('Erro no formulário.')
		// }
	}
}

<div class="onboard" [class]="direction">
	<div class="wrapper-text">
		<h2 [innerHTML]="title"></h2>
		<h4 *ngIf="subTitle">{{ subTitle }}</h4>
		<h3 [innerHTML]="description"></h3>
	</div>
	<div class="wrapper-figure">
		<figure>
			<img
				draggable="false"
				src="{{ '/assets/images/' + src }}"
				alt="{{ title }} {{ '- ' + description }}"
				title="{{ title }} {{ '- ' + description }}"
			/>
		</figure>
	</div>
</div>
<ng-content></ng-content>

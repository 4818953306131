<div class='modal-body text-center'>
    <h5 *ngIf='title'>{{title}}</h5>
    <p *ngIf='desc'>{{desc}}</p>
</div>
<div class='modal-content'>
    <form
        action='#'
        autocomplete='off'
        (ngSubmit)='onSubmit()'
        [formGroup]='form'
    >
        <div class='form-modal px-4'>
            <h6>Na consulta, você realizou alguma destas opções?</h6>
            <br />
            <div class='w-100' *ngFor='let item of checks; let i = index'>
                <app-checkbox
                    [id]='"prescription-options-" + (i)'
                    [labelName]='item.description'
                    [form]='form'
                    [value]='item.value'
                    [changeModel]="onCheckChange"
                >
                </app-checkbox>
            </div>
            <div *ngIf='showError' class='w-100'>
                <p class='text-error'>Você precisa selecionar pelo menos uma opção para continuar</p>
            </div>
        </div>
        <div class='modal-footer'>
            <button type='button' class='btn outline' (click)='close()'>
                Cancelar
            </button>
            <button type='submit' class='btn btn-xs'>Confirmar</button>
        </div>
    </form>
</div>

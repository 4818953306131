import { Action } from '@ngrx/store'
import { User } from '../../models/user'

export enum ActionUserTypes {
	Add = '@USER/ADD',
	Clear = '@USER/CLEAR',
}

export const AddUser = (user: User) => {
	return <Action>{ type: ActionUserTypes.Add, payload: user }
}

export const ClearUser = () => {
	return <Action>{ type: ActionUserTypes.Clear, payload: null }
}

import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { AuthService } from '../../services/auth/auth.service'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { ActivatedRoute, Router, Routes } from '@angular/router'

@Component({
	selector: 'app-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
	form: FormGroup
	id = ''

	constructor(
		private store: Store<AppState>,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute
	) {
		this.form = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email]],
			password: [null, [Validators.required]],
			confirm_password: [null, [Validators.required]],
		})

		this.route.params.subscribe((params) => {
			this.id = params['id']
		})
	}

	ngOnInit(): void {}

	onSubmitNewPassword() {
		const self = this

		if (self.form.valid) {
			self.store.dispatch(ShowLoading())
			const value = self.form.value
			const data = {
				...value,
				token: this.id,
			}
			this.authService.newPassword(data, {
				fnSuccess() {
					self.form.reset()
					window.location.href = '/login'
				},
				fnError(responseError) {
					console.warn(responseError)
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			})
		}
	}
}

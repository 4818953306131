import { Action } from '@ngrx/store'

export enum ActionLoadingTypes {
    ShowLoading = '@LOADING/SHOW',
    HiddenLoading = '@LOADING/HIDE',
}

export const ShowLoading = () => {
    return <Action>{ type: ActionLoadingTypes.ShowLoading}
}

export const HiddenLoading = () => {
    return <Action>{ type: ActionLoadingTypes.HiddenLoading }
}

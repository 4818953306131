import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { Patient } from '../../models/patient'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'

@Component({
	selector: 'app-workbench-layer',
	templateUrl: './workbench-layer.component.html',
	styleUrls: ['./workbench-layer.component.scss'],
})
export class WorkbenchLayerComponent implements OnInit {
	@Input() open: boolean = false
    @Input() isHeaders: boolean = true
	public mouseOverItem: boolean = false
	@Output() emmitEvent: EventEmitter<any> = new EventEmitter()
	patient$?: Observable<Patient>
	public layers: number = 0

	constructor(private store: Store<AppState>) {
		this.patient$ = this.store.select('patient')
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.setQuantityLayers(this.getQuantityLayers() + 1)
		}, 150)
	}

	ngOnDestroy(): void {
		this.setQuantityLayers(this.layers - 1)
	}

	private getQuantityLayers(): number {
		return (<any>window).workBenchLayer || 0
	}

	private setQuantityLayers(value: number) {
		this.layers = value < 0 ? 0 : value
		;(<any>window).workBenchLayer = this.layers
	}

	private reset(): void {
		this.mouseOverItem = false
		this.open = false
	}

	public close() {
		this.mouseOverItem = false
		this.reset()
		this.emmitEvent.emit('close')
	}

	public addClassMouseOver(): void {
		this.mouseOverItem = true
	}

	public removeClassMouseOver(): void {
		this.mouseOverItem = false
	}
}
